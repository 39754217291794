import { IChatRes } from "../../helpers/interface";
import Button from "../ui/Buttons";
import classes from "./chatTag.module.css";

export type IButtonType = {
  options?: IChatRes[];
  handleSelect: (selectedTag: any) => void;
};

const ChatTag = ({ options, handleSelect }: IButtonType) => {
  if (options)
    return (
      <>
        {options.length > 0 && (
          <div className={classes.customListContainer}>
            <ul className={classes.chatUl}>
              {options.map((option, index) => (
                <li key={index} className={classes.listItem}>
                  <Button
                    className={"none"}
                    label={option.username}
                    handleClick={() => handleSelect(option)}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    );
  else return <></>;
};

export default ChatTag;
