import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getUserBetsPagination,
  getHighRollerBetsPagination,
} from "../../../helpers/ServerApi";
import diceImage from "../../../assets/gameone.png";
import moment from "moment";
import tableClass from "../../../components/SwitchTable/SwitchTable.module.css";
import IconLoader from "../../../components/IconLoader";
import classes from "./talbeInfinite.module.css";
import { formatToTwoDecimals } from "../../../helpers/functions";

interface IBets {
  createdAt: string;
  game: string;
  prize: number;
  input: number;
  inputCurrency: {
    symbol: string;
    image: string;
  };
}

interface IProps {
  activeTab: string;
  labels: string[];
}

const TableInfinite = (Props: IProps) => {
  const [page, setPage] = useState(1);
  const [highPage, setHighPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [list, setList] = useState<IBets[]>([]);
  const [highList, setHighList] = useState<IBets[]>([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPage(1);
    setHighPage(1);
    setList([]);
    setHighList([]);
    setHasMore(true);
    fetchMoreData();
  }, [Props.activeTab]);

  const fetchMoreData = async () => {
    try {
      setLoading(true);
      let res: IBets[] = [];

      if (Props.activeTab === "all") {
        const result = await getUserBetsPagination(10, page); // limit set to 10 for example
        if (result.data) res = result.data;
        if (result.success) setSuccess(result.success);
      } else {
        const result = await getHighRollerBetsPagination(10, highPage); // limit set to 10 for example
        if (result.data) res = result.data;
        if (result.success) setSuccess(result.success);
      }

      if (res.length === 0) {
        setHasMore(false);
      } else {
        if (Props.activeTab === "all") {
          setList((prevList) => [...prevList, ...res]);
          setPage((prevPage) => prevPage + 1);
          setLoading(false);
        } else {
          setHighList((prevList) => [...prevList, ...res]);
          setHighPage((prevPage) => prevPage + 1);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      setHasMore(false); // Stop fetching on error
    }
  };

  const renderList = (bets: IBets[]) => {
    return bets.map((bet, index) => (
      <div key={index} className={`${classes["div-1"]}`}>
        <p className={`${classes["p-0"]} col-3`}>
          {moment(bet.createdAt)
            .fromNow()
            .replace("in a few seconds", "a few seconds ago")
            .toUpperCase()}
        </p>
        <p className={`col-3 p-1 ${classes[`p-1`]} ${classes.tabIFp1}`}>
          <IconLoader
            iconName={bet.inputCurrency.symbol}
            iconType={"tokenSymbol"}
            image={bet.inputCurrency.image}
            width={20}
            height={20}
          />{" "}
          {String(bet.input).length > 6
            ? formatToTwoDecimals(bet.input)
            : bet.input}
        </p>

        <p className={`col-3 p-1 ${classes[`p-1`]} ${classes[`p-2`]}`}>
          <IconLoader
            iconName={bet.inputCurrency.symbol}
            iconType={"tokenSymbol"}
            image={bet.inputCurrency.image}
            width={20}
            height={20}
          />{" "}
          {bet.prize === 0
            ? "-" +
              (String(bet.input).length > 6
                ? formatToTwoDecimals(bet.input)
                : bet.input)
            : String(bet.prize + bet.input).length > 6
              ? formatToTwoDecimals(bet.prize + bet.input)
              : bet.prize + bet.input}
        </p>
        <p className={`col-3 ${classes[`p-1`]}`}>
          <img
            className={classes.tabIFImg1}
            src={diceImage}
            height={24}
            width={24}
            alt=""
          />
        </p>
      </div>
    ));
  };

  return (
    <>
      <div
        className={`d-flex align-items-center justify-content-between mx-3 ${tableClass.borderBottom}`}
      >
        {Props.labels?.map((label, index) => (
          <th
            className={classes.tabIFth1}
            key={index}
            style={{
              float: index === Props?.labels?.length - 1 ? "right" : "initial",
            }}
          >
            {label}
          </th>
        ))}
      </div>

      <InfiniteScroll
        className={`${classes["infinite-1"]}`}
        dataLength={Props.activeTab === "all" ? list.length : highList.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          ((list.length > 9 || highList.length > 9) && (
            <span className="d-flex justify-content-center">Loading...</span>
          )) ||
          (loading && (
            <span className="d-flex justify-content-center">Loading...</span>
          )) ||
          (list && (list.length < 9 || highList.length < 9) && (
            <span className="d-flex justify-content-center">
              {list.length > 0 || highList.length > 0
                ? "No more data"
                : "No data found"}
            </span>
          ))
        }
        endMessage={
          <span className="d-flex justify-content-center">
            {list.length > 0 ? "No more data" : "No data found"}
          </span>
        }
        height={"516px"}
      >
        <div className="d-flex flex-column px-3 gap-1">
          {Props.activeTab === "all" ? renderList(list) : renderList(highList)}
        </div>
      </InfiniteScroll>
    </>
  );
};

export default TableInfinite;
