import { useEffect, useState } from "react";
import Pagination from "../ui/pagination/Pagination";
import Table2 from "../ui/table2";
interface ReferralData {
  createdAt: string;
  username: string;
  referralUsedStatus: string;
  usdEarned: number;
  usdWagered: number;
}
const Table = (Props: {
  tableData: ReferralData[];
  totalData: number;
  handlePage: (page: number) => void;
  handleLimit: (limit: number) => void;
}) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    Props.handlePage(page);
    Props.handleLimit(limit);
  }, [page, limit]);

  return (
    <>
      <Table2
        thead={["Sr No", "UserName", "Date", "Status"]}
        tbody={Props.tableData}
      />
      <div className="d-flex justify-content-end"></div>
    </>
  );
};

export default Table;
