import Confetti from "react-confetti";
import { AppState } from "../../redux";
import { useConfettiToggle } from "../../redux/application/hooks";
import { useSelector } from "react-redux";

const ConfettiComponent = (props: any) => {
  const showConfetti = useSelector(
    (state: AppState) => state.application.confettiOpen,
  );
  const toggleConfetti = useConfettiToggle();
  return (
    <>
      {showConfetti && (
        <Confetti
          run={true}
          width={props.width}
          height={props.height}
          onConfettiComplete={() => toggleConfetti()}
          recycle={false}
          style={{ margin: "0 auto" }}
        />
      )}
    </>
  );
};

export default ConfettiComponent;
