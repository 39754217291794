import classes from "./Auth.module.css";
import { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Button from "../../components/ui/Buttons";
import eye from "../../static/images/auth/eye.svg";
import eyeOff from "../../static/images/auth/eye-off.svg";
import mail from "../../static/images/auth/mail.svg";
import chevronRight from "../../static/images/auth/chevron-right.svg";
import { toast } from "react-toastify";
import { userLogin } from "../../helpers/ServerApi";
import { useGetJWT, useSetJWT } from "../../redux/user/hooks";
import moment from "moment";
import Foot from "./foot";
import { discord, google, twitter } from "./socialButtons";
import { core } from "../../config";

const SignIn = () => {
  const userAgent = window.navigator.userAgent;
  const token = useGetJWT();
  const Navigate = useNavigate();
  if (token) {
    Navigate("/");
  }
  const setJWT = useSetJWT();
  const [showPassword, setShowPassword] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const conti = (
    <>
      <div className={`d-flex align-items-center justify-content-center gap-2`}>
        <p className={classes.discordText}>CONTINUE</p>
        {loading && (
          <i className="fa fa-spinner fa-spin" style={{ fontSize: "20px" }}></i>
        )}
        {!loading && <img src={chevronRight} alt="" />}
      </div>
    </>
  );

  const IsValidPassword = (word: string) => {
    const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,15}$/;
    const result = pattern.test(word) || word.length === 0;
    return result;
  };

  const IsValidEmail = (word: string) => {
    const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const result = pattern.test(word) || word.length === 0;
    return result;
  };

  const changeHandler = (e: any) => {
    if (e.target.value.length > 15) {
      return;
    } else setPassword(e.target.value);
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (email: string, password: string) => {
    // e.preventDefault();
    const payload = {
      email,
      password,
      loginMethod: "email signin",
      timezone: moment.tz.guess(),
      userAgent,
    };
    if (!email && !password) {
      toast("Please provide email and password", { theme: "dark" });
      return;
    } else if (!email) {
      toast("Please provide email", { theme: "dark" });
      return;
    } else if (!password) {
      toast("Please provide password", { theme: "dark" });
      return;
    } else if (!IsValidEmail(email)) {
      toast("Invalid email", { theme: "dark" });
      return;
    } else if (!IsValidPassword(password)) {
      toast("Invalid password", { theme: "dark" });
      return;
    } else {
      setLoading(true);
      await userLogin(payload)
        .then((res: any) => {
          if (res.userData) {
            setJWT(res.userData.token);
            Navigate("/");
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.status === 401) {
          } else {
            if (err.response?.data?.error === "Incorrect password") {
              setWrongPass((prev) => !prev);
            }
            toast(err.response?.data.error, { theme: "dark" });
          }
        });
    }
  };

  const handleContinue = () => {
    handleSubmit(email, password);
  };

  return (
    <>
      <div className={classes.box + " " + classes.center}>
        <div style={{ gap: "2px" }}>
          <p className={classes.title}>Sign in</p>
          <p className={classes.subtitle}>Welcome back, enter your details</p>
        </div>
        <div>
          <div style={{ maxHeight: "100px" }}>
            <Button
              label={discord}
              handleClick={async () => {
                window.location.href = `${core._config.relayerUrl}/discord/login`;
              }}
              className={"discord"}
            />
            <div className={classes.signDiv1}>
              <Button
                label={google}
                handleClick={async () => {
                  window.location.href = `${core._config.relayerUrl}/google/login`;
                }}
                className={"google"}
              />
              <Button
                label={twitter}
                handleClick={() => {}}
                className={"twitter"}
              />
            </div>
          </div>
        </div>
        <div className={classes.signDiv2}>
          <div className={classes.line}></div>
          <p className={classes.or}>or</p>
          <div className={classes.line}></div>
        </div>
        <div className={classes.signDiv3}>
          <div className={classes.inputContainer}>
            <input
              className={
                IsValidEmail(email) === true
                  ? `${classes.input} ${classes.inputEmail} ${classes.inputPadding}`
                  : `${classes.error} ${classes.inputEmail} ${classes.input} ${classes.inputPadding}`
              }
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => {
                handleEmail(e);
              }}
              pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
            />
            <img src={mail} alt="Email Icon" className={classes.inputIcon} />
          </div>
          <div className={` d-flex flex-column align-items-start`}>
            {IsValidEmail(email) === false ? (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;Invalid Email
                </span>
              </i>
            ) : null}
          </div>

          <div className={`${classes.inputContainer}`}>
            <input
              className={
                IsValidPassword(password) === true || wrongPass === true
                  ? `${classes.input} ${classes.inputPadding2}`
                  : `${classes.error} ${classes.inputPadding2}`
              }
              type={`${showPassword ? "text" : "password"}`}
              placeholder="Password"
              value={password}
              name="password"
              onChange={(e) => {
                changeHandler(e);
              }}
              pattern=".{6,15}"
              title="Please enter one char, one number with min 6 and max 15"
            />
            <span
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              // As per sonarqube report added below code
              onKeyDown={() => {
                setShowPassword(!showPassword);
              }}
              role="button"
              tabIndex={0}
            >
              <img
                src={showPassword ? eye : eyeOff}
                alt="Eye Icon"
                className={classes.eyeIcon}
              />
            </span>
          </div>
          <div className={` d-flex flex-column align-items-start`}>
            {IsValidPassword(password) === false ? (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;Password should include min 1 char and 1 number
                </span>
              </i>
            ) : null}
            {password && password.length < 6 && (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;6 - 15 length
                </span>
              </i>
            )}
          </div>

          <NavLink to="/forget-password">
            <p className={classes.subtitle + " " + classes.signDiv4}>
              Forgot password
            </p>
          </NavLink>
        </div>
        <Button
          label={conti}
          handleClick={handleContinue}
          className={"authContinue"}
        />
        <p className={classes.authSwitch}>
          Haven't created an account? &nbsp;
          <Link to="/register" className={classes.authSwitchLink}>
            Create account
          </Link>
        </p>
        <Foot />
      </div>
    </>
  );
};

export default SignIn;
