import classes from "../Profile.module.css";

const ProfileClose = (Props: { handleClose: () => void }) => {
  return (
    <>
      <svg
        className={`${classes.cursorPointer} ${classes.charm} ${classes.headerSpan1}`}
        onClick={Props.handleClose}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6L6 18"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ProfileClose;
