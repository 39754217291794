import classes from "./Auth.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../ui/Buttons";
import emailIcon from "../../static/images/auth/mail.svg";
import chevronRight from "../../static/images/auth/chevron-right.svg";
import { toast } from "react-toastify";
import { forgotPassword } from "../../helpers/ServerApi";
import Foot from "./foot";

const ForgetPassword = () => {
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const IsValidEmail = (word: string) => {
    const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const result = pattern.test(word) || word.length === 0;
    return result;
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (username: string) => {
    if (email.length === 0) {
      toast("Enter Email", { theme: "dark" });
      return;
    } else {
      const forgotPayload = {
        email,
      };
      fnForgot(forgotPayload);
    }
  };

  async function fnForgot(payload: object) {
    await forgotPassword(payload)
      .then((res: any) => {
        setLoading(false);
        if (res.user) {
          Navigate("/otp", {
            state: { email: res.user.email, _id: res.user._id },
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.status === 401) {
        } else {
          toast(err.response?.data.error, { theme: "dark" });
        }
      });
  }

  const conti = (
    <>
      <span className={classes.forgotContinueSpan}>
        <p className={classes.discordText}>NEXT</p>
        <img className={classes.chevron} src={chevronRight} alt="" />
      </span>
    </>
  );

  return (
    <>
      <div className={classes.box + " " + classes.center}>
        <div style={{ gap: "2px", display: "flex", flexDirection: "column" }}>
          <p className={classes.title}>Forgot your Password?</p>
          <p className={classes.subtitle}>
            No worries, enter the following details
          </p>
        </div>
        <div className="d-flex justify-content-center flex-column">
          <div className={classes.inputContainer}>
            <input
              className={
                IsValidEmail(email) === true
                  ? `${classes.input} ${classes.inputEmail} ${classes.inputPadding}`
                  : `${classes.error} ${classes.inputEmail} ${classes.input} ${classes.inputPadding}`
              }
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => {
                handleEmail(e);
              }}
              pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
            />
            <img
              className={classes.inputIcon}
              src={emailIcon}
              alt="User Icon"
            />
          </div>
          <div className={` d-flex flex-column align-items-start`}>
            {IsValidEmail(email) === false ? (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;Invalid Email
                </span>
              </i>
            ) : null}
          </div>
        </div>
        <Button
          label={conti}
          handleClick={() => {
            handleSubmit(email);
            setLoading(true);
          }}
          className={"authContinue"}
        />
        <Foot />
      </div>
    </>
  );
};

export default ForgetPassword;
