import { useLocation } from "react-router-dom";
import Home from "../modules/home";
import BootstrapModal from "../components/BootstrapModal";
import { useEffect } from "react";
import useHandleProfileTab from "../hooks/useHandleProfile";
import notifiModalTitle from "../components/Homepage/NotificationModal/title";
import notifiBody from "../components/Homepage/NotificationModal/body";

const HomePage = () => {
  useHandleProfileTab();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {location?.state?.newUser === true && (
        <BootstrapModal
          open={true}
          title={notifiModalTitle}
          message={notifiBody}
          maxWidth={"520px"}
          maxHeight={"244px"}
        />
      )}
      <Home />
    </>
  );
};

export default HomePage;
