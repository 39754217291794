import classes from "./Auth.module.css";
import { useEffect, useState } from "react";
import Button from "../../components/ui/Buttons";
import eye from "../../static/images/auth/eye.svg";
import mail from "../../static/images/auth/mail.svg";
import chevronRight from "../../static/images/auth/chevron-right.svg";
import eyeOff from "../../static/images/auth/eye-off.svg";
import { userRegister } from "../../helpers/ServerApi";
import { useGetJWT, useGetUser, useSetJWT } from "../../redux/user/hooks";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import Foot from "./foot";
import { toast } from "react-toastify";
import { discord, google, twitter } from "./socialButtons";
import { core } from "../../config";

const SignUp = () => {
  const location = useLocation();
  const token = useGetJWT();
  const user = useGetUser();
  const Navigate = useNavigate();
  if (token && user?.username) {
    Navigate("/");
  }
  const setJWT = useSetJWT();
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);

  const conti = (
    <>
      <div className={`d-flex align-items-center justify-content-center gap-2`}>
        <p className={classes.discordText}>NEXT</p>
        {loading && (
          <i className="fa fa-spinner fa-spin" style={{ fontSize: "20px" }}></i>
        )}
        {!loading && <img src={chevronRight} alt="" />}
      </div>
    </>
  );

  const IsValidPassword = (word: string) => {
    const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])/;
    const result = pattern.test(word) || word.length === 0;
    return result;
  };

  const matchPassword = (word: string) => {
    const result = word === password;
    return result;
  };

  const IsValidEmail = (word: string) => {
    const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const result = pattern.test(word) || word.length === 0;
    return result;
  };

  const changeHandler = (e: any) => {
    if (e.target.value.length > 15) {
      return;
    } else {
      if (e.target.name === "password") {
        setPassword(e.target.value);
      } else if (e.target.name === "confirmPassword") {
        setConfirmPassword(e.target.value);
      }
    }
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const userAgent = window.navigator.userAgent;
  const checkAtob = (word: string) => {
    try {
      const parts = window.atob(location.state?.referralCode).split(" ");
      // Store both parts in variables
      const firstPart = parts[0];
      const secondPart = parts[1];
      if (secondPart.includes("/") && secondPart.length > 2) {
        toast("Invalid referral code", { theme: "dark" });
      } else return firstPart;
    } catch (err) {
      toast("Invalid referral code", { theme: "dark" });
    }
  };

  useEffect(() => {
    if (location.state?.referralCode.length > 0)
      checkAtob(location.state?.referralCode);
  }, [location]);

  const handleSubmit = async (
    email: string,
    password: string,
    confirmPassword: string,
  ) => {
    const result = passwordValidation(password);
    if (result === false) {
      return;
    } else {
      const payload = {
        email,
        userAgent,
        password,
        confirmPassword,
        loginMethod: "email signup",
        timezone: moment.tz.guess(),
        avatar: "01",
        referralCode: location.state?.referralCode
          ? checkAtob(location.state?.referralCode)
          : "",
      };
      setLoading(true);
      await userRegister(payload)
        .then((res: any) => {
          if (res?.user) {
            setLoading(false);
            setJWT(res.user.token);
            Navigate("/set-username", { state: { newUser: true } });
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.status === 401) {
          } else {
            if (err.response?.data?.error === "Incorrect password") {
              setWrongPass((prev) => !prev);
            }
            toast(err.response?.data.error, { theme: "dark" });
          }
        });
    }
    // else {
    //   if (matchPassword(confirmPassword) === false) {
    //     toast("Password does not match", { theme: "dark" });
    //   }
    //   if (IsValidEmail(email) === false) {
    //     toast("Invalid Email", { theme: "dark" });
    //   }
    // }
  };

  function passwordValidation(word: string) {
    let pattern = /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,15}$/;
    let result = pattern.test(word);
    if (!email) {
      toast(`Please provide email`, { theme: "dark" });
      result = false;
    } else if (!password || !confirmPassword) {
      toast(
        `Please enter ${!password ? "password " : ""}${!password && !confirmPassword ? "and " : ""} ${!confirmPassword ? "confirm password" : ""}`,
        { theme: "dark" },
      );
      result = false;
    } else if (password.length < 6 || confirmPassword.length < 6) {
      toast("Password should be char length of min 6 and max 15", {
        theme: "dark",
      });
      result = false;
    } else if (password !== confirmPassword) {
      toast("Passwords do not match", { theme: "dark" });
      result = false;
    } else if (IsValidPassword(password) === false) {
      toast("Password should include min 1 char and 1 number", {
        theme: "dark",
      });
      result = false;
    } else if (IsValidPassword(confirmPassword) === false) {
      toast("Confirm password should include min 1 char and 1 number", {
        theme: "dark",
      });
      result = false;
    } else if (IsValidEmail(email) === false) {
      toast("Invalid Email", { theme: "dark" });
      result = false;
    }

    if (result === false) {
      return result;
    }
  }

  return (
    <>
      <div className={classes.box + " " + classes.center}>
        <div style={{ gap: "2px" }}>
          <p className={classes.title}>Sign up</p>
          <p className={classes.subtitle + " " + classes.signupP1}>
            Enter your details to continue with the game
          </p>
        </div>

        <div>
          <div style={{ maxHeight: "100px" }}>
            <Button
              label={discord}
              handleClick={async () => {
                window.location.href = `${core._config.relayerUrl}/discord/login`;
              }}
              className={"discord"}
            />
            <div className={classes.signDiv1}>
              <Button
                label={google}
                handleClick={async () => {
                  window.location.href = `${core._config.relayerUrl}/google/login`;
                }}
                className={"google"}
              />
              <Button
                label={twitter}
                handleClick={async () => {
                  window.location.href = `${core._config.relayerUrl}/twitter/login`;
                }}
                className={"twitter"}
              />
            </div>
          </div>
        </div>
        <div className={classes.signDiv2}>
          <div className={classes.line}></div>
          <p className={classes.or}>or</p>
          <div className={classes.line}></div>
        </div>
        <div className={classes.signDiv3}>
          <div className={classes.inputContainer}>
            <input
              className={
                IsValidEmail(email) === true
                  ? `${classes.input} ${classes.inputEmail} ${classes.inputPadding}`
                  : `${classes.error} ${classes.inputEmail} ${classes.input} ${classes.inputPadding}`
              }
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => {
                handleEmail(e);
              }}
              pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
            />
            <img src={mail} alt="Email Icon" className={classes.inputIcon} />
          </div>
          <div className={` d-flex flex-column align-items-start`}>
            {IsValidEmail(email) === false ? (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;Invalid Email
                </span>
              </i>
            ) : null}
          </div>
          <div className={`${classes.inputContainer}`}>
            <input
              className={
                IsValidPassword(password) === true || wrongPass === true
                  ? `${classes.input} ${classes.inputPadding2}`
                  : `${classes.error} ${classes.inputPadding2}`
              }
              type="password"
              placeholder="Password"
              pattern=".{6,15}"
              value={password}
              name="password"
              onChange={(e) => {
                changeHandler(e);
              }}
              title="Please enter one char, one number with min 6 and max 15"
            />
          </div>
          <div className={` d-flex flex-column align-items-start`}>
            {IsValidPassword(password) === false ? (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;Password should include min 1 char and 1 number
                </span>
              </i>
            ) : null}
            {password && password.length < 6 && (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;6 - 15 length
                </span>
              </i>
            )}
          </div>
          <div className={`${classes.inputContainer}`}>
            <input
              className={
                matchPassword(confirmPassword) === true || wrongPass === true
                  ? `${classes.input} ${classes.inputPadding2}`
                  : confirmPassword?.length
                    ? `${classes.error} ${classes.inputPadding2}`
                    : `${classes.input} ${classes.inputPadding2}`
              }
              type={`${showConfPassword ? "text" : "password"}`}
              placeholder="Confirm Password"
              value={confirmPassword}
              name="confirmPassword"
              onChange={(e) => {
                changeHandler(e);
              }}
              title="Please enter one char, one number with min 6 and max 15"
            />
            <span
              onClick={() => {
                setShowConfPassword(!showConfPassword);
              }}
              // As per sonarqube report added below code
              onKeyDown={() => {
                setShowConfPassword(!showConfPassword);
              }}
              role="button"
              tabIndex={0}
            >
              <img
                src={showConfPassword ? eye : eyeOff}
                alt="Eye Icon"
                className={classes.eyeIcon}
              />
            </span>
          </div>
          <div className={` d-flex flex-column align-items-start`}>
            {confirmPassword?.length && password !== confirmPassword ? (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;Password does not match
                </span>
              </i>
            ) : null}
          </div>
        </div>
        <Button
          label={conti}
          handleClick={() => {
            handleSubmit(email, password, confirmPassword);
          }}
          className={"authContinue"}
        />
        <p className={classes.authSwitch}>
          Already have an account? &nbsp;
          <Link to="/login" className={classes.authSwitchLink}>
            Sign in
          </Link>
        </p>
        <Foot />
      </div>
    </>
  );
};

export default SignUp;
