import { avatars } from "../../../static/images";
import classes from "./changeAvatar.module.css";

interface IProps {
  handleClose: () => void;
  handleChangeAvatar: (data: string) => void;
}

const ChangeAvatar = (Props: IProps) => {
  return (
    <>
      <div className={classes.avatarBox}>
        {avatars.map((avatar, index) => (
          <div
            key={index}
            className={classes.profileImgDiv}
            onClick={() => Props.handleChangeAvatar(avatar)}
            // as per sonarqube report changes below
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          >
            <img className={classes.profileImgDiv} src={avatar} alt="avatar" />
          </div>
        ))}
      </div>
    </>
  );
};

export default ChangeAvatar;
