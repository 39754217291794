import Button from "../../components/ui/Buttons";
import classes from "./betDetails.module.css";
import ellipse from "../../static/images/Ellipse.svg";
import insta from "../../static/images/betModal/inst.png";
import tel from "../../static/images/betModal/tel.png";
import x from "../../static/images/betModal/x.png";
import dis from "../../static/images/betModal/dis.png";
import copyIcon from "../../static/images/copy.svg";
import {
  openProfileAction,
  openRival,
  RivalData,
} from "../../redux/application/actions";
import { useGetLoggedIn, useGetUserName } from "../../redux/user/hooks";
import { useDispatch } from "react-redux";
import { checkAvatar } from "../../helpers/functions";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetCurrentGameId } from "../../redux/application/hooks";
import { toast } from "react-toastify";
import { TwitterShareButton, TelegramShareButton } from "react-share";
export interface IBetDetailProps {
  data: any;
  handleClose: () => void;
  handlePlay: () => void;
  type?: number; // from => 0 - other page, 1 - game page bets, 2 - ingame history
}

const BetBody = (Props: IBetDetailProps) => {

  const isLoggedIn = useGetLoggedIn();
  const getGameId = useGetCurrentGameId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const username = useGetUserName();

  const handleClickRival = (id: string) => {
    dispatch(RivalData({ username: id }));
    dispatch(openRival({ key: true }));
    Props.handleClose();
  };

  return (
    <>
      <div className="d-flex align-items-center w-100 justify-content-between">
        <div className="col-6 d-flex">
          <p className={classes.betId}>
            <span>ID: </span>
            <span>{(Props.data._id || Props.data.betId).substring(0, 10)}</span>
          </p>
          <Button
            className={"none"}
            label={
              <img
                className="m-1"
                src={copyIcon}
                width={24}
                height={24}
                alt="copy"
              />
            }
            handleClick={() => {
              toast("Copied to clipboard", { theme: "dark" });
              navigator.clipboard.writeText(Props.data._id || Props.data.betId);
            }}
          />
        </div>
        <div className={`col-6 float-end ${classes.social}`}>
          <img src={insta} height={24} width={24} alt="" />
          <TelegramShareButton
            title={`Hello, In mooncake ${Props.data.won === true ? "Won" : "Lost"} the game.
            Won Amount: ${Props.data.prize}`}
            url={`Won Amount: ${Props.data.prize}`}
          >
            <img src={tel} height={24} width={24} alt="" />
          </TelegramShareButton>
          <TwitterShareButton
            title={`Hello, In mooncake ${Props.data.won === true ? "Won" : "Lost"} the game.
            Won Amount: ${Props.data.prize}`}
            url={""}
          >
            <img src={x} height={24} width={24} alt="" />
          </TwitterShareButton>
          <img src={dis} height={24} width={24} alt="" />
        </div>
      </div>
      <div className="d-flex align-items-center w-100 float-start gap-2">
        <div className="d-flex flex-column">
          <img
            className={`${classes.profileIcon}`}
            src={
              checkAvatar(Props.data.user.avatar) === null ||
              !checkAvatar(Props.data.user.avatar)
                ? ellipse
                : checkAvatar(Props.data.user.avatar)
            }
            height={32}
            width={32}
            alt="game"
          />
        </div>
        <div className="d-flex flex-column gap-1">
          <div
            className={classes.username}
            onClick={() => {
              if (Props.data.user.username !== username) {
                handleClickRival(Props.data.user._id);
              } else {
                dispatch(openProfileAction({ key: true }));
                Props.handleClose();
              }
            }}
            // as per sonarqube report changes below
            onKeyDown={(e) => e.preventDefault()}
            role="button"
            tabIndex={0}
          >
            {Props.data.user.username}
          </div>
          <div className={classes.tag}>
            <p className={classes.tagtxt}>Adventurer</p>
          </div>
        </div>
      </div>
      <div className={classes.gameBg}>
        <img
          className={classes.bgImg}
          src={`${process.env.REACT_APP_URL}/assests/images/games/${Props.data.game.image || Props.data.gameImage}`}
          alt=""
        />
        <div
          className={`${Props.data.won ? classes.wintxt : classes.losstxt} position-relative`}
        >
          <p className={`${Props.data.won ? classes.winloss : classes.loss}`}>
            {Props.data.won === true ? "WIN" : "LOST"}
          </p>
        </div>
      </div>
      <div className={classes.playNow}>
        <div>
          <img
            className={classes.img1}
            src={`${process.env.REACT_APP_URL}/assests/images/games/${Props.data.game.image || Props.data.gameImage}`}
            height={32}
            width={32}
            alt=""
          />{" "}
        </div>
        <Button
          label="PLAY NOW"
          handleClick={() => {
            Props.handlePlay();
            if (!isLoggedIn) navigate("/login");
            else if (Props.type === 2) {
              Props.handleClose();
            } else if (!location.pathname.includes("/game"))
              navigate("/game/dice-2d", { state: { gameId: getGameId } });
          }}
          className={"betDetailsPlayNow"}
        />
      </div>
    </>
  );
};

export default BetBody;
