import { useNavigate } from "react-router-dom";
import classes from "./ChangePassword.module.css";

const ChangePassword = () => {
  const navigate = useNavigate();
  const handleChangePassword = () => {
    navigate("/change-password", { state: { type: "changePassword" } });
  };

  return (
    <div
      className={`${classes.changePassword} px-4 pb-4`}
      onClick={handleChangePassword}
      //   as per sonarqube report changes below
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      role="button"
      tabIndex={0}
    >
      <p className={classes.changePasswordTxt}>Change Password</p>
    </div>
  );
};

export default ChangePassword;
