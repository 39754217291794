import IconLoader from "../../components/IconLoader";
import { formatToTwoDecimals } from "../../helpers/functions";
import classes from "./betDetails.module.css";

const Details = (Props: { data: any }) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className={`col-6 ${classes.score} gap-1 d-flex flex-column`}>
        <p>Target</p>
        <p>Multiplier</p>
        <p>Bet</p>
        <p>{Props.data.won === true ? "Won" : "Lost"}</p>
      </div>
      <div className={`col-6 ${classes.result} gap-1 d-flex flex-column`}>
        <p className="">
          {Props.data.underOver === "under" ? "UNDER" : "OVER"}{" "}
          {Props.data.guess}
        </p>
        <p className="">x{Props.data.multiplier}</p>
        <p className="gap-1 d-flex justify-content-end">
          {" "}
          <IconLoader
            iconName={Props.data.inputCurrency.symbol}
            iconType={"tokenSymbol"}
            image={Props.data.inputCurrency.image}
            width={20}
            height={20}
          />
          {formatToTwoDecimals(Props.data.input)}
        </p>
        <p className="gap-1 d-flex justify-content-end">
          <IconLoader
            iconName={Props.data.inputCurrency.symbol}
            iconType={"tokenSymbol"}
            image={Props.data.inputCurrency.image}
            width={20}
            height={20}
          />
          {Props.data.won === true
            ? formatToTwoDecimals(Props.data.prize)
            : formatToTwoDecimals(Props.data.input)}
        </p>
      </div>
    </div>
  );
};

export default Details;
