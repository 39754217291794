import classes from "./referral.module.css";

const RefHeader = () => {
  return (
    <>
      <span>
        <h1 className={classes.refHeader}>Refer Friends & Earn</h1>
      </span>
    </>
  );
};

export default RefHeader;
