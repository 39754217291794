import "./styles.sass";
import Banners from "./Banners";
import Banner2 from "./Banner2";
import StaticBanner from "./StaticBanner";
import GameCards from "./components/GameCards";
import { useEffect, useState } from "react";
import { getGames } from "../../helpers/ServerApi";
import weeklyBanner from "../../static/images/weeklyBanner.svg";
import anonymousBanner from "../../static/images/anonymousBanner.svg";
import refferBanner from "../../static/images/refferBanner.svg";
import classes from "./Home.module.css";
import SwitchTabs from "../../components/SwitchTabs";
import HomeBets from "../../components/HomeBets/MyBetsComponent";
import HeadingClasses from "../heading.module.css";

const Home = () => {
  const [activeTab, setActiveTab] = useState("recent");

  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  const [games, setGames] = useState([]);
  useEffect(() => {
    getGames()
      .then((res: any) => {
        setGames(res.games);
      })
      .catch((err) => {
        console.log("Unable to get games");
      });
  }, []);

  return (
    <>
      <div className={classes.home}>
        <div className={`row ${classes.rowGap} ${classes.div1}`}>
          <div className="col-12 col-md-6 col-lg-8">
            <Banners />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <Banner2 />
          </div>
        </div>

        <div className={`row ${classes.rowGap}`}>
          <div className="col-md-4">
            <StaticBanner
              img={weeklyBanner}
              heading={"Weekly Tournament"}
              body={"A vitae senectus amet magna aliquam porttitor nunc purus."}
            />
          </div>

          <div className="col-md-4">
            <StaticBanner
              img={anonymousBanner}
              heading={"Full Anonymous"}
              body={"A vitae senectus amet magna aliquam porttitor nunc purus."}
            />
          </div>

          <div className="col-md-4">
            <StaticBanner
              type={"refer"}
              img={refferBanner}
              heading={"Refer Friends"}
              body={"Arcu ullamcorper tellus morbi sit justo."}
            />
          </div>
        </div>

        {/* Games */}
        <div className={`${classes.rowGap} d-flex flex-column ${classes.div2}`}>
          <p className={HeadingClasses.game}>Games</p>
          <div className={`row ${classes.rowGap}`}>
            {games.map((game: any) => (
              <div key={game.slug} className={`col-md-6 ${classes.gCard}`}>
                <GameCards
                  game={game.slug}
                  name={game.name}
                  gameId={game._id}
                  image={game.image}
                  about={game.description}
                  ></GameCards>
              </div>
            ))}
          </div>
        </div>

        <SwitchTabs
          className="leaderTabs"
          lables={["recent", "high rollers", "lucky winners"]}
          handleTab={handleTab}
          activeTab={activeTab}
        />

        <HomeBets handleFirstPlay={() => {}} activeTab={activeTab} />
      </div>
    </>
  );
};

export default Home;
