import { ICountRes } from "../modules/profile/UserCard";
import { ISendWithPermitParams as IPlayParams } from "../protocol/BetsQueue";
import api from "./apiCaller";
import {
  ILeaderBoard,
  IReferralBoard,
  IRelayResponseData,
  ILiveFeedBets,
  IAssetPrices,
  IUser,
  IBetResult,
  IReferralCodes,
  IMyReferral,
  IBalance,
  IReferral,
  IRefRes,
  IRefdRes,
  ICurrency,
  IChat,
} from "./interface";

//Ranking Board APIs
export const getLeaderBoard = async () => {
  return await api<ILeaderBoard[]>("leaderBoard/bets");
};

export const getReferralLeaderBoard = async () =>
  await api<IReferralBoard[]>("leaderBoard/referral");

// Auth APIs

export const forgotPassword = async (payload: object) => {
  let result = await api("auth/forgot-password", "POST", payload);
  return result;
};

export const updatePassword = async (payload: object) => {
  let result = await api<{ user: IUser }>("auth/update-password", "POST", payload);
  return result;
}

export const changePassword = async (payload: object) => {
  let result = await api<{ user: IUser }>("users/update-password", "POST", payload);
  return result;
}

//Login APIs

export const userLogin = async (payload: object) => {
  let result = await api("auth/login", "POST", payload);
  return result;
};

export const userRegister = async (payload: object) => {
  let result = await api<{ user: IUser }>("auth/signup", "POST", payload);
  return result;
}

//User Data APIs


export const verifyOtp = async (payload: object) => {
  let result = await api("auth/check-otp", "POST", payload);
  return result;
};

export const updateUser = async (payload: object) => {
  let result = await api<{ user: IUser }>("users/update-user", "POST", payload);
  return result;
}

export const updateAvatar = async (payload: object) => {
  let result = await api<{ user: IUser }>("users/update-avatar", "POST", payload);
  return result;
}

export const userUpdate = async (body: any) =>
  await api("users/update", "POST", body);

export const getUserDetails = async () => await api("users/me");

export const getUserBalance = async () =>
  await api<IBalance[]>("users/balances");

export const depositTxns = async () => await api("users/deposit/transactions");

export const withdrawTxns = async () =>
  await api("users/withdraw/transactions");

export const getRival = async (id: string) => {
  let data = await api(`users/rival?id=${id}`)
  return data;
}

// Referral APIs
export const createReferralAPI = async (body: any) =>
  await api("referrals/add", "POST", body);

export const getReferralAPI = async () => await api<IReferral>("referrals/referrals");
export const getReferredAPI = async (payload: { page: number, limit: number }) => {
  let query = `?page=${payload.page}&limit=${payload.limit}`;
  let result = await api<IRefdRes>(`referrals/referred${query}`);
  return result;
}
export const getReferralData = async () => await api<IRefRes>("referrals/data");

export const getLoginHistory = async (page: number) => await api(`login-history/?page=${page}`, 'GET');

//Chat APIs

export const sendMessage = async (message: string) => await api("chats", "POST", { message });
export const getAllChat = async () => await api<IChat[]>("chats");
export const getChatTag = async (string: string) => await api(`chats/tag-user/${string}`)
export const chatted = async () => await api(`chats/chatted/`);

// Address APIs

export const getDepositAddress = async (username: any) =>
  await api<{ depositAddress: string }>(`users/address?username=${username}`);

export const withdrawal = async (body: any) =>
  await api("users/withdrawal", "POST", body);

// Game APIs

export const getGames = async () => await api('games/', 'GET');
export const getGame = async (name: string) => await api(`games/${name}`, 'GET');

export const getCountBet = async (gameId: string) => await api(`bets/count/${gameId}`, 'GET');
export const getCountBetAll = async () => await api(`bets/count`, 'GET');

export const play = async (game: string, params: IPlayParams) =>
  await api<IBetResult>(`plays/${game}`, "POST", params);

export const getUserBets = async () => await api<IBetResult[]>("bets/me");
export const getUserBetsPagination = async (limit?: number, page?: number) => {
  let query = `?limit=${limit}&page=${page}`;
  let result = await api<{ data: IBetResult[]; success: boolean }>(`bets/me${query}`);
  return result;
}
export const getHighRollerBetsPagination = async (limit?: number, page?: number) => {
  let query = `?limit=${limit}&page=${page}`;
  let result = await api<{ data: IBetResult[]; success: boolean }>(`bets/me/highRollers${query}`);
  return result;
}

export const getGameBets = async (id: string, page: number, limit: number, type?: string) => {
  let queryType = type ? `&type=${type}` : "";
  let result = await api<IBetResult[]>(`bets/game/${id}?page=${page}&limit=${limit}${queryType}`);
  return result;
}

export const getHomeBets = async (id: string, page: number, limit: number, type?: string) => {
  let queryType = type ? `&type=${type}` : "";
  let result = await api<IBetResult[]>(`bets/home-bets?page=${page}&limit=${limit}${queryType}`);
  return result;
}

export const relayPlayWithPermit = async (data: IPlayParams) =>
  await api<IRelayResponseData>(`relay/playWithPermit`, "post", data);

export const generateSha256Hash = async () =>
  await api<{ shaHash: string }>(`seeds`, "post");

export const getDollarValues = async () =>
  await api<IAssetPrices>(`prices/assets`, "get");

export const getCurrentUser = async () =>
  await api<{ user: IUser }>(`users/me`, "get");

export const addReferralCode = async (body: any) =>
  await api("referrals/add", "POST", body);

export const getUserReferralCodes = async () =>
  await api<{ codes: IReferralCodes[] }>("referrals/codes");

export const getMyReferrals = async () =>
  await api<{ referrals: IMyReferral[] }>("referrals/me");

export const getLiveFeedData = async () => await api<ILiveFeedBets[]>("bets");

// Currency

export const getCurrency = async () => await api<ICurrency>("currencies/");

// logout
export const clearSession = async () => {
  await api('login-history/clear-session', 'GET');
}