import { useLocation, useNavigate, useParams } from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./SingUp";
import Username from "./Username";
import ForgetPassword from "./ForgetPassword";
import Otp from "./Otp";
import ChangePassword from "./ChangePassword";
import { useEffect } from "react";
import { useGetUser } from "../../redux/user/hooks";

const Auth = () => {
  const param = useParams();

  const location = useLocation();
  const Navigate = useNavigate();
  const path = location.pathname;
  const otp = location?.state?.otp;
  const user = useGetUser();

  const newUser = location?.state?.newUser || false;

  useEffect(() => {
    if (path === "/set-username" && !newUser && user?.username) {
      Navigate("/register");
    }
    if (param.code) {
      Navigate("/register", { state: { referralCode: param.code } });
    }
  }, [path, newUser, Navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {path === "/login" && <SignIn />}
      {path === "/register" && <SignUp />}
      {path === "/set-username" && user?.username === undefined && <Username />}
      {path === "/forget-password" && <ForgetPassword />}
      {path === "/otp" && <Otp email={location.state?.email} />}
      {path === "/change-password" && <ChangePassword otp={otp} />}
    </>
  );
};

export default Auth;
