import copyIcon from "../../../static/images/copy.svg";
import classes from "../wallet.module.css";
import eth from "../../../static/images/wallet/eth.svg";
import weth from "../../../static/images/wallet/wETH.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import QRCode from "react-qr-code";
import Drop from "../dropDown";
import Button from "../../../components/ui/Buttons";
import { useGetUser } from "../../../redux/user/hooks";
import { getDepositAddress } from "../../../helpers/ServerApi";

const Deposit = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const user = useGetUser();
  const userName = user?.username
  const [selectedCurrency, setSelectedCurrency] = useState<string>("");
  const [selectedNetword, setSelectedNetwork] = useState<string>("");
  const [selectedDepoAddress, setSelectedDepoAddress] = useState("");

  // dummy data for currency
  const currency = [
    {
      _id: {
        $oid: "65dda31b3f25f03b4dd9b8d2",
      },
      address: "2435465ytrgfvdfr5t4etgrfvdrwe4tdssdvseawss",
      isBlockchain: true,
      name: "Ethereum",
      frozen: false,
      minimumWithdrawalAmount: 0.003,
      enableWithdrawals: true,
      symbol: "ETH",
      createdAt: {
        $date: "2024-02-27T08:53:47.654Z",
      },
      updatedAt: {
        $date: "2024-02-27T08:53:47.654Z",
      },
      __v: 0,
    },
    {
      _id: {
        $oid: "65dda31b3f25f03b4dd9b8d9",
      },
      address: "h4es5465ytrgfvdfr5t4etgrfvdrwe4tdssdvseawss",
      isBlockchain: false,
      name: "Mooncake",
      frozen: false,
      minimumWithdrawalAmount: 0,
      enableWithdrawals: false,
      symbol: "MCAKE",
      createdAt: {
        $date: "2024-02-27T08:53:47.676Z",
      },
      updatedAt: {
        $date: "2024-02-27T08:53:47.676Z",
      },
      __v: 0,
    },
    {
      _id: {
        $oid: "65dda31b3f25f03b4dd9b8d2",
      },
      address: "2435465ytrgfvdfr5t4etgrfvdrwe4t5erfsdawe3q2",
      isBlockchain: true,
      name: "Wrapped Ethereum",
      frozen: false,
      minimumWithdrawalAmount: 0.003,
      enableWithdrawals: true,
      symbol: "wETH",
      createdAt: {
        $date: "2024-02-27T08:53:47.654Z",
      },
      updatedAt: {
        $date: "2024-02-27T08:53:47.654Z",
      },
      __v: 0,
    },
  ];
  // dummy data for network
  // Use useMemo to memoize the network array
  const network = useMemo(
    () => [
      {
        name: "Ethereum",
        address: "rtgyrhuthtreffgbnhtuj4r456rtserdgnt4etgrefrw5ery",
        symbol: "ETH",
      },
      {
        name: "Sample",
        address: "retgthe5t4567687675u65yt4w3rwrgdfhgjuki7t6rets",
        symbol: "SAMPLE",
      },
    ],
    [],
  ); // No dependencies for useMemo, it will only initialize once

  // Set default values for selected network and deposit address when the component mounts
  useEffect(() => {
    async function getUserDepositAddress() {
      const getAddress = await getDepositAddress(userName)
      if(getAddress?.depositAddress) {
        setSelectedDepoAddress(getAddress?.depositAddress);
      }
    }
    getUserDepositAddress()
    if (network.length > 0) {
      // Get the first network item from the array
      const defaultNetwork = network[0];

      // Set the initial state values based on the first network item
      setSelectedNetwork(defaultNetwork.symbol);
    }
  }, []); // Depend on the `network` array to run the effect when it changes

  const handleNetwork = (value: string) => {
    const selectedNetworkSymbol = value;

    // Find the selected network object based on the symbol
    const selectedNetwork = network.find(
      (item) => item.symbol === selectedNetworkSymbol,
    );

    // If the selected network is found, set the state accordingly
    if (selectedNetwork) {
      setSelectedNetwork(selectedNetworkSymbol);
      //setSelectedDepoAddress(selectedNetwork.address);
    }
  };

  return (
    <>
      <div className={`${classes.depositDiv1} pt-3 ${classes.wrapBody}`}>
        <div className={`${classes.depositDi2} ${classes.wrapBody}`}>
          <div>
            <p className={classes.inputLabel}>Currency</p>
            <Drop activeTab="deposit" data={currency} type="currency" />
          </div>
          <div>
            <p className={classes.inputLabel}>Network</p>
            <Drop
              activeTab="deposit"
              data={network}
              type="currency"
              onChange={(value: string) => {
                handleNetwork(value);
              }}
            />
          </div>
        </div>
        <div>
          <p className={classes.inputLabel}>Your deposit address</p>
          <div className="d-flex align-items-center">
            <input
              ref={inputRef}
              className={`${classes.input} ${classes.depoRela}`}
              type="text"
              name=""
              id=""
              value={selectedDepoAddress}
              disabled
            />
            <Button
              className={"none"}
              label={
                <img className={classes.copyIcon} src={copyIcon} alt="copy" />
              }
              handleClick={() =>
                navigator.clipboard.writeText(selectedDepoAddress)
              }
            />
          </div>
        </div>
        <div className={classes.depoDiv2}>
          <p className={classes.lineBreak}></p>
          <p className={classes.inputLabel}>or</p>
          <p className={classes.lineBreak}></p>
        </div>
        <div className="d-flex flex-column align-items-center gap-2">
          <p className={classes.endText}>Scan the QR Code</p>
          <p className={classes.endText}>for the deposit</p>
          <div className={classes.qrCode}>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={selectedDepoAddress}
              viewBox={`0 0 256 256`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Deposit;
