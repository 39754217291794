import { useState } from "react";
import { setting, pencil } from "../../../static/images";
import classes from "../Profile.module.css";
import BootstrapModal from "../../../components/BootstrapModal";
import EditProfileTitle from "../EditProfile/title";
import EditProfileContent from "../EditProfile/content";
import Button from "../../../components/ui/Buttons";

interface IProps {
  handleClick: () => void;
  handleClose: () => void;
}

const EditSetting = (Props: IProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
    // Props.handleClose();
  };
  return (
    <>
      {modalOpen === true && (
        <BootstrapModal
          open={true}
          handleClose={() => setModalOpen(false)}
          title={<EditProfileTitle />}
          message={<EditProfileContent />}
          buttonTitle={"none"}
          className={"modalEditProfile"}
        />
      )}
      <div
        className={`col-6 d-flex align-items-center justify-content-end gap-2 ${classes.editProfileDiv} ${classes.editProfileDiv2}`}
      >
        <div
          className={`float-end ${classes.cursorPointer}`}
          onClick={Props.handleClick}
          // as per sonarqube report changes below
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          role="button"
          tabIndex={0}
        >
          {setting}
        </div>
        <div
          className={`d-flex align-items-center ${classes.editProfile}`}
          onClick={() => {
            handleOpenModal();
          }}
          // as per sonarqube report changes below
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          role="button"
          tabIndex={0}
        >
          <span>{pencil(16)}</span>
          <p className={classes.editProfileTxt}>Edit Profile</p>
        </div>
      </div>
    </>
  );
};

export default EditSetting;
