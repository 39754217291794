import { AbiCoder } from "ethers/lib/utils.js";
import { addBet, addCurrentBet } from "../redux/bets/actions";
import { generateSha256Hash, play } from "../helpers/ServerApi";
import { Protocol } from "./Protocol";
import { setGamePlayError, toggleConfetti } from "../redux/application/actions";
import EventEmitter from "events";
import { toast } from "react-toastify";

import song from "../static/sound/dice-win.wav";
import lost from "../static/sound/Loss.mp3";

export type IWinLoss = {
  seedHash: string;
  guess: string;
  random?: string;
  input: string;
  prize?: string;
  won?: boolean;
};

export interface ISendWithPermitParams {
  seedHash: string;
  clientHash: string;
  input: number;
  guess: number;
  inputCurrency: string;
  under: boolean;
  over: boolean;
}

/**
 * A helper class that manages the browser nonce and sends tx's to the relayer in one request
 */
export class BetsQueue extends EventEmitter {
  dispatch: any = {};
  core: Protocol;
  seedHash?: string;

  constructor(core: Protocol, dispatch: any) {
    super();
    this.core = core;
    this.dispatch = dispatch;
  }

  public initHash = () => {
    generateSha256Hash().then((res) => {
      this.seedHash = res.shaHash;
    });
  };

  public getHash = async () => {
    if (this.seedHash) return this.seedHash;
    return generateSha256Hash().then((res) => {
      this.seedHash = res.shaHash;
      return this.seedHash;
    });
  };

  playSound = (url: string) => {
    const audio = new Audio(url);
    return audio.play();
  };

  public async processTransaction(params: ISendWithPermitParams) {
    // const random = Math.floor(Math.random() * 100000000000);
    const random = this.generateSecureRandomNumber();
    const encode = new AbiCoder();
    const seed = encode.encode(["uint256"], [random]);
    const hash = await this.getHash();
    const data: ISendWithPermitParams = {
      ...params,
      seedHash: hash,
      clientHash: seed.toString(),
    };

    play("dice-2d", data)
      .then(async (response) => {

        const win = response.won;

        if (win) {
          this.dispatch(addBet(response));
          this.dispatch(addCurrentBet(response));
          this.playSound(song);
          this.dispatch(toggleConfetti());
        } else {
          this.playSound(lost);
          this.dispatch(addBet(response));
          this.dispatch(addCurrentBet(response));
        }
      })
      .catch((err) => {

        if (err.response.data.success === false) {
          this.dispatch(setGamePlayError({ key: true }))
          toast(err.response.data.error === 'invalid-currency' ? 'Currency frozen, change your currency' : err.response.data.error, { theme: "dark", type: "error" });
        }
      })
      .finally(() => this.initHash());

  }

  private generateSecureRandomNumber(): number {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] % 100000000000; // Ensure the number is within the desired range
  }
}
