import { useState } from "react";
import { useGetSideMenuOpen } from "../../../redux/application/hooks";
import { MenuItems } from "../AppHeader/AppHeader";
import classes from "./SideMenu.module.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useGetLoggedIn } from "../../../redux/user/hooks";

interface MenuItem {
  id: string;
  icon: string;
  name: string;
  redirect: string;
  show: string[];
}

const Menu = () => {
  const location = useLocation();
  const isLoggedIn = useGetLoggedIn();
  const navigate = useNavigate();
  const sideMenuOpen = useGetSideMenuOpen();
  const [selectedItem, setSelectedItem] = useState<MenuItem | null>(null);

  const handleItemClick = (item: MenuItem) => {
    setSelectedItem(item);
    if (item.id === "referral" && !isLoggedIn) {
      navigate("/login");
    } else {
      navigate(item.redirect);
    }
  };

  const list = (
    <>
      <ul className={classes["menu-list"]}>
        {MenuItems.filter((m) => m.show.includes("desktop")).map(
          (item, index) => (
            <div
              className={`
              ${item.id === "support" && `${classes["support"]} ms-3 mb-1 pe-3 ps-3 me-3`} d-flex  
              ${item.id === "support" && !sideMenuOpen ? `${classes["support-open"]}` : ""}
              ${
                sideMenuOpen
                  ? `${classes["float-container-open"]}`
                  : `${classes["float-container"]}=
                  `
              }
              `}
              style={{
                cursor: "pointer",
                backgroundColor:
                  location.pathname === item.redirect && sideMenuOpen
                    ? "rgba(255, 255, 255, 0.2)"
                    : "transparent",
                gap: "5%",
              }}
              key={index}
              onClick={() => handleItemClick(item)}
              // as per sonarqube report changes below
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              role="button"
              tabIndex={0}
            >
              <div
                className={`${classes["navIcon"]} ${classes["side-menu-item"]} ${classes["float-child"]} tooltip`}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    width: "2rem",
                    height: "2rem",
                    backgroundColor:
                      location.pathname === item.redirect
                        ? "rgba(255, 255, 255, 0.2)"
                        : "transparent",
                    borderRadius: "5px",
                  }}
                />
                <span className="tooltiptext">{item.name}</span>
              </div>
              {sideMenuOpen && (
                <>
                  <NavLink to={`${item.redirect}`} className={classes.navTo}>
                    <div
                      className={`${classes["navIcon"]} ${classes["side-menu-text"]} ${classes["float-child"]}`}
                    >
                      {item.name}
                    </div>
                  </NavLink>
                </>
              )}
            </div>
          ),
        )}
      </ul>
    </>
  );

  return (
    <>
      <div
        className={`offcanvasC offcanvas-startC ${classes.sideNav}`}
        data-bs-scroll="true"
        data-bs-backdrop="false"
        tabIndex={-1}
        id="offcanvasScrolling"
        aria-labelledby="offcanvasScrollingLabel"
      >
        <div className="offcanvas-header pe-3 ps-3">{list}</div>
      </div>
    </>
  );
};

export default Menu;
