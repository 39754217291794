import { Routes, Route, useLocation } from "react-router-dom";
import { ProtectedRoute } from "../Navigation";
import GamePage from "../pages/game";
import NotFoundPage from "../pages/notfound.page";

const GameRoutes = () => {
  const location = useLocation();

  return (
    <>
      <Routes>
        <Route
          path="dice-2d"
          element={
            <ProtectedRoute element={<GamePage state={location.state} />} />
          }
        ></Route>
        <Route path=":id" element={<NotFoundPage />}></Route>
      </Routes>
    </>
  );
};

export default GameRoutes;
