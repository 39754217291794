import dth from "../../../static/images/banners/welcomeCoin.svg";
import Button from "../../../components/ui/Buttons";
import arrow from "../../../assets/arrow1.png";
import { Link } from "react-router-dom";
import classes from "./Banners.module.css";
import { useMediaQuery } from "react-responsive";
import { useGetLoggedIn } from "../../../redux/user/hooks";

const Banners = () => {
  const isLoggedIn = useGetLoggedIn();
  const isTablet = useMediaQuery({ maxWidth: "991px" });
  const dynamicClassName = isTablet ? "col-12" : "col-8";
  return (
    <>
      <div className={`row ${classes.raysBanner}`}>
        <div
          className={`d-flex flex-column align-items-center justify-content-center ${dynamicClassName}`}
        >
          <div className={classes.div2}>
            <div className={classes.div1}>
              <h1 className={classes.heading}>
                Stand a Chance to <br />
                win 1 eth! & $2000 <br />
                giveaway!
              </h1>
              <p className={classes.Checkout}>
                Checkout our referral Program now before it <br />
                expire
              </p>
            </div>
            <div>
              <Link to={isLoggedIn ? "/referral" : "/login"}>
                <Button
                  label={
                    <div className={classes.div3}>
                      CHECK NOW{" "}
                      <img className={classes.img1} src={arrow} alt="arrow" />
                    </div>
                  }
                  handleClick={() => {}}
                  className="checkNow"
                />
              </Link>
            </div>
          </div>
        </div>
        <div
          className={`col-3 pe-5 d-flex align-items-center justify-content-center ${classes.bannerImage}`}
        >
          <img className={classes.dthImg} src={dth} alt="dth" />
        </div>
      </div>
    </>
  );
};

export default Banners;
