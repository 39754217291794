import Download from "../Download";
import classes from "./Auth.module.css";

const Foot = () => {
  return (
    <p className={classes.authFoot}>
      By accessing website you confirm that you are at least{" "}
      <span className={classes.authFootIn}>18 years old</span> and agree to the{" "}
      <span className={classes.authFootIn}>
        <Download string={" Terms of Use"} type={"tc"} />
      </span>
    </p>
  );
};

export default Foot;
