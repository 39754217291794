import "./styles/components.css";
import "./styles/index.css";
import "./styles/style.css";

import { fas } from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { ProtocolProvider } from "./context/ProtocolProvider";
import { Provider } from "react-redux";
import Navigation from "./Navigation";
import ServerProvider from "./context/ServerProvider";
import store from "./redux";
import useCore from "./hooks/useCore";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
library.add(fas);

const Providers = ({ children }: React.HTMLProps<HTMLBaseElement>) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ServerProvider>
          <ProtocolProvider store={store}>
            <AppContent>{children}</AppContent>
          </ProtocolProvider>
        </ServerProvider>
      </BrowserRouter>
    </Provider>
  );
};

const AppContent = ({ children }: React.HTMLProps<HTMLBaseElement>) => {
  const core = useCore();

  // environment check
  if (!core) return <div />;
  return (
    <>
      <ToastContainer />
      <Navigation />
      {children}
    </>
  );
};

function App() {
  return <Providers></Providers>;
}

export default App;
