import { createReducer } from "@reduxjs/toolkit";
import { INITIAL_APP_STATE as init } from "../../helpers/constants";

import {
  addPopup,
  removePopup,
  setReferredBy,
  toggleConfetti,
  toggleSettingsMenu,
  toggleWalletModal,
  updateBlockNumber,
  updatePopup,
  updateUserInput,
  setGameIDs,
  setDepositAddress,
  setAssetPrices,
  toggleChatBox,
  openRival,
  openProfileAction,
  toggleSideMenu,
  openBetModal,
  currentGame,
  games, // get games,
  RivalData,
  RivalMetaData,
  prevRoute,
  setProfileTab,
  setCurrencies,
  setGamePlayError,
  setChatted,
  clearApp,
} from "./actions";
import { Popup } from "../../helpers/interface";
import { getPopUpKey } from "../../helpers/functions";

export default createReducer(init, (builder) => {
  builder
    .addCase(updateBlockNumber, (state, action) => {
      const { chainId, blockNumber } = action.payload;
      if (typeof state.blockNumber[chainId] !== "number") {
        state.blockNumber[chainId] = blockNumber;
      } else {
        state.blockNumber[chainId] = Math.max(
          blockNumber,
          state.blockNumber[chainId],
        );
      }
    })
    .addCase(toggleWalletModal, (state) => {
      state.walletModalOpen = !state.walletModalOpen;
    })
    .addCase(toggleSettingsMenu, (state) => {
      state.settingsMenuOpen = !state.settingsMenuOpen;
    })
    .addCase(setReferredBy, (state, { payload: { refBy } }) => {
      state.referredBy = refBy;
    })

    .addCase(setDepositAddress, (state, { payload: { address } }) => {
      state.depositAddress = address;
    })
    .addCase(setAssetPrices, (state, { payload }) => {
      state.assetPrices = payload["collateral-prices"];
    })
    .addCase(setGameIDs, (state, { payload: { id } }) => {
      state.gameIDs.push(id);
    })
    .addCase(
      addPopup,
      (state, { payload: { content, key, removeAfterMs = 15000 } }) => {
        state.popupList = (
          key
            ? state.popupList.filter((popup) => popup.key !== key)
            : state.popupList
        ).concat([
          {
            key: key || getPopUpKey(),
            show: true,
            content,
            removeAfterMs,
          },
        ]);
      },
    )
    .addCase(updatePopup, (state, { payload: { content, key } }) => {
      const popupList = state.popupList;
      // state.popupList = popupList.filter(_p => _p.key !== key);
      const popupFiltered = popupList.filter((_p) => _p.key === key);
      if (popupFiltered.length === 1) {
        let popup: Popup = popupFiltered[0];
        popup = {
          key: popup.key,
          show: true,
          removeAfterMs: popup.removeAfterMs,
          content: {
            ...content,
          },
        };
        state.popupList = popupList.map((_p) => {
          if (_p.key === key) {
            return popup;
          } else return _p;
        });
      }
    })
    .addCase(removePopup, (state, { payload: { key } }) => {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false;
        }
      });
    })
    .addCase(toggleConfetti, (state) => {
      state.confettiOpen = !state.confettiOpen;
    })
    .addCase(toggleChatBox, (state, action) => {
      state.openChatBox = !state.openChatBox;
    })
    .addCase(openRival, (state, action) => {
      state.openRival = action.payload.key;
    })
    .addCase(openProfileAction, (state, action) => {
      state.openProfile = action.payload.key;
    })
    .addCase(RivalData, (state, action) => {
      state.rivalData = action.payload.username;
    })
    .addCase(toggleSideMenu, (state, action) => {
      state.openSideMenu = !action.payload.key;
    })
    .addCase(openBetModal, (state, action) => {
      state.openBetModal = action.payload.key;
    })
    .addCase(updateUserInput, (state, action) => {

      const { guess, input, token, under, over, image } = action.payload;
      const data = {
        guess: guess || state.userInput.guess || init.userInput.guess,
        input: input || state.userInput.input || init.userInput.input,
        token: token || state.userInput.token || init.userInput.token,
        under: under || state.userInput.under || init.userInput.under,
        over: over || state.userInput.over || init.userInput.over,
        image: image || state.userInput.image || init.userInput.image,
      }
      state.userInput = data;
    })
    // .addCase(games, (state, action) => {
    //   state.games = action.payload.games;
    // })
    .addCase(currentGame, (state, action) => {
      state.currentGameId = action.payload.gameId;
    })
    .addCase(RivalMetaData, (state, action) => {
      state.RivalMetaData = {
        username: action.payload.username,
        avatar: action.payload.avatar,
        usdWagered: action.payload.usdWagered,
        createdAt: action.payload.createdAt,
        _id: action.payload._id,
        won: action.payload.won,
        loss: action.payload.loss,
      }
    })
    .addCase(prevRoute, (state, action) => {
      state.prevRoute = action.payload.route
    })
    .addCase(setProfileTab, (state, action) => {
      state.profileTab = action.payload.tab
    })
    .addCase(setCurrencies, (state, action) => {
      state.currencies = action.payload.currencies
    })
    .addCase(setGamePlayError, (state, action) => {
      state.gamePlayError = action.payload.key
    })
    .addCase(setChatted, (state, action) => {
      state.chatted = action.payload.key
    })
    .addCase(clearApp, (state) => {
      state.currencies = [];
      state.RivalMetaData = {
        username: "",
        avatar: "",
        usdWagered: 0,
        createdAt: "",
        _id: "",
        won: 0,
        loss: 0,
      }
      localStorage.clear();
    })
});
