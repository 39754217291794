import { IPagination } from "../../../helpers/interface";
import classes from "./Pagination.module.css";

const Pagination = (Props: IPagination) => {
  // Pagination
  const totalPages = Math.ceil(Props.totalRecords / Props.limit);
  function changePage(type: string) {
    if (type === "prev") {
      if (Props.page - 1 > 0) {
        Props.setPage(Props.page - 1);
        Props.setSelectedPage(Props.page - 1);
      }
    } else if (type === "next") {
      if (Props.page + 1 <= totalPages) {
        Props.setPage(Props.page + 1);
        Props.setSelectedPage(Props.page + 1);
      }
    }
  }
  const renderPageButton = (page: number, isSelected: boolean) => (
    <div
      key={page}
      className={`${classes.pageNumber} ${isSelected ? classes.selectedPage : ""}`}
      onClick={() => {
        Props.setPage(page);
        Props.setSelectedPage(page);
      }}
      onKeyDown={(e) => e.preventDefault()}
      role="button"
      tabIndex={0}
    >
      {page}
    </div>
  );

  const renderEllipsis = (key: string) => <div key={key}>...</div>;

  const renderPageButtons = (startPage: number, endPage: number) => {
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(renderPageButton(i, Props.selectedPage === i));
    }
    return pages;
  };

  const renderPages = () => {
    const pages = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      pages.push(...renderPageButtons(1, totalPages));
    } else {
      const startPage = Math.max(
        1,
        Math.min(Props.selectedPage - 2, totalPages - maxVisiblePages + 1),
      );
      const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

      if (startPage > 1) {
        pages.push(renderPageButton(1, Props.selectedPage === 1));
        if (startPage > 2) {
          pages.push(renderEllipsis("ellipsis1"));
        }
      }

      pages.push(...renderPageButtons(startPage, endPage));

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pages.push(renderEllipsis("ellipsis2"));
        }
        pages.push(
          renderPageButton(totalPages, Props.selectedPage === totalPages),
        );
      }
    }
    return pages;
  };

  return (
    <div>
      {Props.totalRecords > 0 ? (
        <div className={`${classes.paginationContainer} mb-3`}>
          <div
            className={`d-flex justify-content-center ${classes.pageNumber}`}
            onClick={() => {
              changePage("prev");
            }}
            // as per sonarqube report changes below
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            role="button"
            tabIndex={0}
          >
            {`< Pervious`}
          </div>
          <div className={`d-flex justify-content-center`}>{renderPages()}</div>
          <div
            className={`d-flex justify-content-center ${classes.pageNumber}`}
            onClick={() => {
              changePage("next");
            }}
            // as per sonarqube report changes below
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            role="button"
            tabIndex={0}
          >
            {` Next >`}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Pagination;
