// !! Merge with ui/Buttons component when redeveloping game module
export type tracking_ids =
  | "place_bet"
  | "wallet"
  | "fund_account"
  | "login"
  | "register"
  | "deposit"
  | "switch_network"
  | "withdraw"
  | "welcome_join_discord"
  | "get_started";

interface IProps {
  tracking_id?: tracking_ids;
  tracking_params?: any;
  class?: string;
}

const Button = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & IProps,
) => {
  return (
    <button
      className={props?.class !== undefined ? props?.class : "primary-btn"}
      {...props}
    ></button>
  );
};

export default Button;
