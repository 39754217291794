import { useEffect, useState } from "react";
import classes from "./LoginHistory.module.css";
import { getLoginHistory } from "../../../../helpers/ServerApi";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

interface ILoginHistoryItem {
  loginMethod: string;
  createdAt: string;
  country: string;
  ip: string;
  userAgent: string;
}

const LoginHistoryTable = () => {
  const [loginHistory, setLoginHistory] = useState<ILoginHistoryItem[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = async () => {
    if (!hasMore) return;

    try {
      const res: any = await getLoginHistory(page);
      const data = res.data as ILoginHistoryItem[];
      // console.log("data :: ", data, data.length);

      if (data.length === 0) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setLoginHistory((prevList) => [...prevList, ...data]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    // const fetchData = async () => {
    fetchMoreData();
    // };

    // fetchData();
  });

  const headers = ["DATE", "LOGIN METHOD", "COUNTRY", "IP address"];

  return (
    <>
      <InfiniteScroll
        dataLength={loginHistory.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <span className="d-flex justify-content-center">Loading...</span>
        }
        height={window.innerHeight / 2}
        style={{ overflowY: "auto", width: "100%" }}
      >
        <table className={classes.tableH}>
          <thead>
            <tr className={classes.tr}>
              {headers.map((item, index) => (
                <th key={index} className={classes.th}>
                  <p className={classes.p}>{item}</p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={classes.tbodyH}>
            {loginHistory.map((login, index) => (
              <tr key={index} className={classes.tr}>
                <td className={classes.td}>
                  {/* {new Date(login.createdAt).toLocaleDateString()} */}
                  {moment(login.createdAt).format("DD/MM/YYYY")}
                </td>
                <td className={classes.td}>{login.loginMethod}</td>
                <td className={classes.td}>{login.country}</td>
                <td className={classes.td}>{login.ip}</td>
              </tr>
            ))}
            {!hasMore && (
              <tr>
                <td colSpan={headers.length} className={classes.noMoreData}>
                  No more data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </InfiniteScroll>
    </>
  );
};

export default LoginHistoryTable;
