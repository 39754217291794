const NotificationIcon = () => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2680_4333)">
          <path
            d="M5.85259 15.4044L5.8529 15.4038L6.03244 14.6399V14.6397C6.03244 14.6396 6.03244 14.6396 6.03244 14.6395L6.03244 10.3715C6.03244 8.25793 6.87204 6.23095 8.36654 4.73645C9.86105 3.24195 11.888 2.40234 14.0016 2.40234C16.1151 2.40234 18.1421 3.24195 19.6366 4.73645C21.1311 6.23095 21.9707 8.25793 21.9707 10.3715V14.6399V14.6405C21.971 14.9058 22.0331 15.1675 22.152 15.4048L24.2279 19.5578L24.2279 19.5579C24.2913 19.6846 24.3212 19.8253 24.3148 19.9668C24.3084 20.1083 24.266 20.2459 24.1915 20.3663C24.1171 20.4868 24.013 20.5863 23.8893 20.6552C23.7656 20.7242 23.6263 20.7604 23.4847 20.7604H23.4846H4.51856H4.51849C4.37685 20.7604 4.23755 20.7242 4.11383 20.6552C3.99011 20.5863 3.88608 20.4868 3.81161 20.3663C3.73714 20.2459 3.6947 20.1083 3.68834 19.9668C3.68198 19.8254 3.71189 19.6846 3.77523 19.5579C3.77523 19.5579 3.77524 19.5579 3.77524 19.5579L5.85259 15.4044ZM12.1936 23.816C11.8692 23.5867 11.5933 23.2992 11.3786 22.9702H16.6245C16.4098 23.2992 16.1339 23.5867 15.8095 23.816C15.2808 24.1896 14.6492 24.3902 14.0017 24.39H14.0014C13.354 24.3902 12.7224 24.1896 12.1936 23.816Z"
            fill="url(#paint0_linear_2680_4333)"
            stroke="#B1BFDB"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2680_4333"
            x1="14.0016"
            y1="1.90234"
            x2="14.0016"
            y2="24.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9E6FF" />
            <stop offset="1" stopColor="#D4E2FF" stopOpacity="0.31" />
          </linearGradient>
          <clipPath id="clip0_2680_4333">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default NotificationIcon;
