// src/hooks/useHandleProfileTab.js

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetPrevRoute } from '../redux/application/hooks';
import { setProfileTab } from '../redux/application/actions';

const useHandleProfileTab = () => {
    const dispatch = useDispatch();
    const prevRoute = useGetPrevRoute();

    useEffect(() => {
        if (prevRoute === '/change-password') {
            dispatch(setProfileTab({ tab: 'settings' }));
        }
    }, [prevRoute, dispatch]);
};

export default useHandleProfileTab;
