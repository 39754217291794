import walletIcon from "../../../static/images/wallet.svg";
import classes from "../wallet.module.css";
const WalletTitle = () => {
  return (
    <>
      <div className={classes.WalletTitleDiv1}>
        <img src={walletIcon} width={24} height={24} alt="" />
        <span>wallet</span>
      </div>
    </>
  );
};

export default WalletTitle;
