import BootstrapModal from "../../components/BootstrapModal";
import Wallet from "./index";
import WalletTitle from "./title";

const OpenWallet = (Props: { openWallet: boolean; closeModal: () => void }) => {
  return (
    <>
      {Props.openWallet && (
        <BootstrapModal
          open={true}
          title={<WalletTitle />}
          message={<Wallet />}
          buttonTitle={"none"}
          height={"436px"}
          handleClose={() => {
            Props.closeModal();
          }}
          width="436px"
          className="modalWallet"
        />
      )}
    </>
  );
};

export default OpenWallet;
