import { useMemo } from "react";
import classes from "./Comment.module.css";
import {
  avatar,
  checkAvatar,
  truncateMiddle,
} from "../../../helpers/functions";
import ellipse from "../../../static/images/Ellipse.svg";
import "./style.sass";
import { useDispatch } from "react-redux";
import {
  openRival,
  RivalData,
  toggleChatBox,
} from "../../../redux/application/actions";
import { IChat, IMetaData } from "../../../helpers/interface";
import {
  useGetAvatar,
  useGetUserId,
  useGetUserName,
} from "../../../redux/user/hooks";

interface IProps {
  chatData: IChat;
}

const Comment = (props: IProps) => {
  const { chatData } = props;
  const dispatch = useDispatch();
  const userId = useGetUserId();
  const userAvatar = useGetAvatar();
  const userName = useGetUserName();
  // Assuming userAvatar is the name of the user's avatar
  // Function to highlight mentions with a background
  const highlightMentions = (message: string, metaData: IMetaData[]) => {
    const regex = /(@\w+)/g;
    let currentIndex = 0; // Initialize a pointer to iterate through metaData

    // Replace mentions with spans wrapped in the red background color and onClick function
    const highlightedMessage = message.replace(regex, (match) => {
      // Check if the current match is "@everyone"
      if (match === "@everyone") {
        const style =
          "background-color: #2F3647; border-radius: 5px; padding: 0 0.3rem 0.1% 0.3rem; margin-right: 0.3rem; color: white;";
        // Return the "@everyone" string wrapped in a span with the background color
        return `<span style="${style}">${match}</span>`;
      }
      // if (match === metaData?.[currentIndex]?.username) {
      // }

      // Get the current metadata item if there are any left
      const metaItem = metaData?.[currentIndex] ?? null;

      if (metaItem?.username === match.split("@")[1]) {
        // Increment the index for the next iteration
        currentIndex++;

        // Define the style
        const style =
          "background-color: #2F3647; border-radius: 5px; padding: 0 0.3rem 0.1% 0.3rem; margin-right: 0.3rem;";

        // Return the mention wrapped in a span with the appropriate style
        return `<span style="${style}">${match}</span>`;
      }

      // If no metadata item is available, return the mention as is
      return match;
    });

    return highlightedMessage;
  };

  // Memoize the highlighted message to avoid unnecessary re-renders
  const highlightedMessage = useMemo(
    () => highlightMentions(chatData.message, chatData.metaData),
    [chatData],
  );

  const handleRivalProfile = (username: string) => {
    dispatch(RivalData({ username }));
    dispatch(openRival({ key: true }));
    dispatch(toggleChatBox({ key: false }));
  };

  return (
    <>
      <div className={`comment-main ${classes.div0}`}>
        <div className={`${classes.div1} col-1`}>
          <img
            className={classes.img1}
            src={
              // userId === chatData.from._id
              //   ? checkAvatar(userAvatar)
              //   : checkAvatar(chatData.from.avatar) === null ||
              //       !checkAvatar(chatData.from.avatar)
              //     ? ellipse
              //     : checkAvatar(chatData.from.avatar)
              avatar(
                chatData.from._id,
                userId,
                chatData.from.avatar,
                userAvatar,
              )
            }
            alt=""
          />
        </div>
        <div className={`${classes.div2} col-11`}>
          <span
            className={`comment-name ${classes.span1}`}
            onClick={() => {
              handleRivalProfile(chatData.from._id);
            }}
            // as per sonarqube report changes below
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            role="button"
            tabIndex={0}
          >
            {/* {truncateMiddle(chatData.from.username, 14)} */}
            {userId === chatData.from._id ? userName : chatData.from.username}
          </span>{" "}
          {/* Set the inner HTML of the messageColor span to the highlighted message */}
          <span
            className={`messageColor ${classes.span1}`}
            dangerouslySetInnerHTML={{ __html: highlightedMessage }}
          />
        </div>
      </div>
    </>
  );
};

export default Comment;
