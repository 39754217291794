import classes from "./referral.module.css";

const RefNoData = () => {
  return (
    <>
      <div className={classes.refNoDataBox}>
        <div className={classes.refNoDataCenter}>
          <h1 className={classes.refNoDatah1}>
            Oops! Looks you haven't
          </h1>
          <h1 className={classes.refNoDatah1}>referred anyone yet</h1>
          <p className={classes.refNoDatapara}>
            Velit morbi sit sed scelerisque lorem bibendum.
          </p>
          <p className={classes.refNoDatapara}>
            Venenatis elit venenatis vitae sagittis
          </p>
        </div>
      </div>
    </>
  );
};

export default RefNoData;
