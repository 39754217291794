import { profileIcon } from "../../../static/images";
const EditProfileTitle = () => {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ gap: "6px" }}
      >
        {profileIcon(24)}
        <span>Profile</span>
      </div>
    </>
  );
};

export default EditProfileTitle;
