import userClass from "../../../modules/bet-details/betDetails.module.css";
import classes from "../Profile.module.css";
import {
  useGetUserName,
  useGetUserEmail,
  useGetUserAvatar,
} from "../../../redux/user/hooks";
import { eye, eyeOff, pencil, setting } from "../../../static/images";
import { useState } from "react";
import { checkAvatar } from "../../../helpers/functions";
import {
  useGetRivalCreatedAt,
  useGetRivalUsername,
} from "../../../redux/application/hooks";
import moment from "moment";
import ellipse from "../../../static/images/Ellipse.svg";
import BootstrapModal from "../../../components/BootstrapModal";
import EditProfileTitle from "../EditProfile/title";
import EditProfileContent from "../EditProfile/content";
import Button from "../../../components/ui/Buttons";

const InfoCard = (Props: {
  type: string;
  handleClick: () => void;
  handleClose: () => void;
}) => {
  const [showEmail, setShowEmail] = useState(false);
  const rivalUsrname = useGetRivalUsername();
  const rivalJoined = useGetRivalCreatedAt();
  const userName = useGetUserName();
  const userEmail = useGetUserEmail();
  const userAvatar = useGetUserAvatar();
  const [modalOpen, setModalOpen] = useState(false);

  const formatEmail = (email: string) => {
    if (showEmail)
      return (
        <>
          <div className={`tooltip ${classes.useremail}`}>
            {email.length > 15 ? email.slice(0, 15) + "..." : email}
            <span className="tooltiptext tooltipProfileEmail">{email}</span>
          </div>
        </>
      );
    else {
      const name = email.split("@")[0];
      const obscuredName = Array(8).fill(
        <span className={classes.dots}></span>,
      );
      return (
        <span className={classes.emailContainer}>
          {obscuredName}
          {email.slice(name.length).slice(0, 7) + "..."}
        </span>
      );
    }
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      {modalOpen === true && (
        <BootstrapModal
          open={true}
          handleClose={() => setModalOpen(false)}
          title={<EditProfileTitle />}
          message={<EditProfileContent />}
          buttonTitle={"none"}
        />
      )}
      <div
        className={`col-6 float-start ${classes.profileInfoCard} ${classes.InfoCardiv1}`}
      >
        <div className="d-flex flex-column">
          <img
            className={classes.profileIcon}
            src={
              checkAvatar(userAvatar) === null || !checkAvatar(userAvatar)
                ? ellipse
                : checkAvatar(userAvatar)
            }
            height={56}
            width={56}
            alt="game"
          />
        </div>
        <div className={`d-flex flex-column ${classes.infoDiv}`}>
          <p className={classes.username}>
            {Props.type === "profile" ? userName : rivalUsrname}
          </p>
          {Props.type === "profile" ? (
            <div className={classes.email}>
              <p className={`${classes.useremail} ${classes.emailText}`}>
                {formatEmail(userEmail)}
              </p>
              <Button
                className={"none"}
                handleClick={() => setShowEmail(!showEmail)}
                label={!showEmail ? eyeOff : eye}
              />
            </div>
          ) : (
            <p className={classes.useremail}>
              {`Joined on ${moment(rivalJoined).format("Do MMM YYYY")}`}
            </p>
          )}

          <div className={`${classes.tag} ${classes.InfoCardDiv2}`}>
            <p className={`${userClass.tagtxt} ${classes.infoCardTrans}`}>
              Adventurer
            </p>
          </div>
          {userName !== rivalUsrname && (
            <div
              className={`d-flex align-items-center gap-2 ${classes.editProfileDiv} ${classes.editProfileDiv3}`}
            >
              <Button
                className={"none"}
                label={<span className={`float-end`}>{setting}</span>}
                handleClick={() => {
                  Props.handleClick();
                }}
              />

              <div
                className={`d-flex align-items-center ${classes.editProfile}`}
                onClick={() => {
                  handleOpenModal();
                }}
                // as per sonarqube report changes below
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                role="button"
                tabIndex={0}
              >
                <span>{pencil(16)}</span>
                <p className={classes.editProfileTxt}>Edit Profile</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InfoCard;
