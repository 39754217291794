import { useEffect, useState } from "react";
import { useCurrentBet } from "../../redux/bets/hooks";
import { getGameBets } from "../../helpers/ServerApi";
import { toast } from "react-toastify";
import SwitchRows from "../SwitchTable/SwitchRows";
import { IData } from "../SwitchTable/SwitchRows";
import useSocketIO from "../../hooks/useSocketIo";
interface IProps {
  gameId?: string;
  activeTab?: string;
  handleFirstPlay: (firstPlay: boolean, openModal: boolean) => void;
}

const MyBets = (props: IProps) => {
  const socket = useSocketIO();
  const gameId = props.gameId;
  const [betsData, setBetsData] = useState<IData[]>([]);
  const [betsDataCurrent, setBetsDataCurrent] = useState<IData[]>([]);
  const [betsDataHighroller, setBetsDataHighroller] = useState<IData[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const currentBet = useCurrentBet();

  useEffect(() => {
    if (props.activeTab === "current") {
      socket?.on("won:bet", (data: IData) => {
        setBetsDataCurrent((prevData: IData[]) => {
          const newData = [data, ...prevData];

          return newData;
        });
      });
    }
  }, [props.activeTab, socket]);

  useEffect(() => {
    if (currentBet !== undefined) {
      setBetsData((prevData) => {
        const newData = [...prevData];

        // If the length of newData is 10, remove the last element
        if (newData.length >= 10) {
          newData.pop();
        }

        // Add currentBet to the beginning of the array
        newData.unshift(currentBet);

        return newData;
      });
    }
  }, [currentBet, limit]);

  useEffect(() => {
    if (limit > 0) {
      getGameBets(gameId ? gameId : "", page, limit)
        .then((res: any) => {
          if (betsData.length > 0) {
            props.handleFirstPlay(false, false);
            setBetsData(res.data);
          } else {
            if (res.data.length === 0) props.handleFirstPlay(true, true);
            setBetsData((prevData) => {
              // Combine the existing data with the newly fetched data
              return [...prevData, ...res.data];
            });
          }
        })
        .catch((err) => {
          toast("Unable to get bets history");
        });
    }
    if (props.activeTab === "high rollers") {
      getGameBets(gameId ? gameId : "all", page, limit, "high")
        .then((res: any) => {
          if (res.data.length > 0) {
            setBetsDataHighroller(res.data);
          } else
            setBetsDataHighroller((prevData) => {
              // Combine the existing data with the newly fetched data
              return [...prevData, ...res.data];
            });
        })
        .catch((err) => {
          toast("Unable to get bets history");
        });
    }
    if (props.activeTab === "current") {
      getGameBets(gameId ? gameId : "", page, limit, "current")
        .then((res: any) => {
          if (res.data.length > 0) {
            setBetsDataCurrent(res.data);
          } else
            setBetsDataCurrent((prevData) => {
              // Combine the existing data with the newly fetched data
              return [...prevData, ...res.data];
            });
        })
        .catch((err) => {
          toast("Unable to get bets history");
        });
    }
  }, [betsData.length, gameId, limit, page, props.activeTab]);

  useEffect(() => {
    if (props.activeTab === "high rollers") {
      socket?.on("highroller", (data: IData) => {
        setBetsDataHighroller((prevData: IData[]) => {
          const newData = [data, ...prevData];
          return newData;
        });
      });
    }
  }, [props.activeTab, socket]);

  return (
    <>
      <SwitchRows
        labels={["players", "time", "bet", "payout", "games"]}
        data={
          props.activeTab === "my bets"
            ? betsData
            : props.activeTab === "current"
              ? betsDataCurrent
              : betsDataHighroller
        }
        type={props.activeTab}
      />
    </>
  );
};

export default MyBets;
