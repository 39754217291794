import Button from "../ui/Buttons";
import { useCallback, useEffect, useState } from "react";
import Profile from "../../modules/profile";
import {
  useGetRivalData,
  useGetRivalOpen,
  useGetRivalUsername,
} from "../../redux/application/hooks";
import {
  openProfileAction,
  openRival,
  RivalMetaData,
} from "../../redux/application/actions";
import { useDispatch } from "react-redux";
import { getRival } from "../../helpers/ServerApi";
import { useGetProfileTab } from "../../redux/application/hooks";
import { useGetLoggedIn, useGetUserName } from "../../redux/user/hooks";
import { useNavigate } from "react-router-dom";
import { profileIconFilled, profileIconPng } from "../../static/images";
import { useGetProfileOpen } from "../../redux/application/hooks";

const ProfileDropDown = () => {
  const isLoggedIn = useGetLoggedIn();
  const rivalOpen = useGetRivalOpen();
  const rivalData = useGetRivalData();
  const rivalUsername = useGetRivalUsername();
  const profileOpen = useGetProfileOpen();
  const Navigate = useNavigate();

  useEffect(() => {
    if (rivalOpen) {
      getRival(rivalData).then((res: any) => {
        dispatch(RivalMetaData(res.output));
      });
    }
  }, [rivalOpen]);

  const dispatch = useDispatch();

  const [openProfile, setOpenProfile] = useState(false);

  useEffect(() => {
    setOpenProfile(profileOpen);
  }, [profileOpen]);

  const handleClose = useCallback(() => {
    setOpenProfile(false);
    dispatch(openProfileAction({ key: false }));
    dispatch(openRival({ key: false }));
  }, [dispatch]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    if (openProfile) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openProfile, handleClose]);

  // const profileTabRedux = useGetProfileTab();
  // useEffect(() => {

  //   if (profileTabRedux.length > 0) {
  //     setOpenProfile(true);
  //     // setActiveTab(profileTabRedux);
  //     // dispatch(setProfileTab({ tab: "" }));
  //   }
  // }, [profileTabRedux]);

  const handleOpenProfile = () => {
    if (isLoggedIn) {
      setOpenProfile(!openProfile);
    } else Navigate("/login");
  };

  return (
    <>
      <div className="dropdown profile-dropdown">
        <Profile
          // openProfile={openProfile ? openProfile : rivalOpen}
          openProfile={openProfile}
          rivalProfile={useGetUserName() !== rivalUsername && rivalOpen}
          handleClose={handleClose}
        />
        <Button
          className={"profileButton"}
          label={profileIconPng(28)}
          handleClick={() => handleOpenProfile()}
        />
      </div>
    </>
  );
};

export default ProfileDropDown;
