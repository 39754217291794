import "./bethistory.sass";
import { IBetResult } from "../../../helpers/interface";
import { useEffect, useMemo, useState } from "react";
import { useGetAllBetsFor } from "../../../redux/bets/hooks";
import { useGetUserId } from "../../../redux/user/hooks";
import useUpdateGameBets from "../../../hooks/games/callbacks/useUpdateGameBets";
import classes from "../dice.module.css";

interface BetsHistoryProps {
  gameId: string;
  handleClick: React.Dispatch<React.SetStateAction<boolean>>;
  setModalDataHandler: React.Dispatch<React.SetStateAction<IBetResult | null>>; // Add setModalData property
}

const BetsHistory = (props: BetsHistoryProps) => {
  const userId = useGetUserId();
  const bets = useGetAllBetsFor(userId);
  const gameId = props.gameId;
  const updateBetsHistory = useUpdateGameBets({ gameId: gameId });
  const [openModal, setOpenModal] = useState(false);
  const [index, setIndex] = useState(0);
  const getBetColor = (bet: IBetResult) => {
    let bgColor = "";
    let color = "#FFFFFF";
    let border = "1px solid #FFFFFF";

    if (bet.won) {
      bgColor = "";
      color = "#2DCA72";
      border = "1px solid #2DCA72";
    } else {
      bgColor = "";
      color = "#F53D6B";
      border = "1px solid #F76489";
    }

    return { color, bgColor, border };
  };

  const sortedArray = useMemo(
    () =>
      Object.values(bets).sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ),
    [bets],
  );

  useEffect(() => {
    updateBetsHistory();
  }, [updateBetsHistory]);

  return (
    <>
      {sortedArray.length > 0 ? (
        <>
          <div className={`${classes["bets-history"]} col-12`}>
            {sortedArray.map((data, index) => {
              const betData = data;
              const _c = getBetColor(betData);

              return (
                <div
                  key={data._id}
                  className={classes["individual-bet"]}
                  style={{
                    color: _c.color,
                    border: _c.border,
                  }}
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenModal(true);
                    setIndex(index);
                    props.handleClick(true); // Call handleClick with the desired boolean value
                    props.setModalDataHandler(data);
                  }}
                  // as per sonarqube report changes below
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {Math.trunc(data.random)}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className={"bets-history"} style={{ opacity: "0.3" }}></div>
        </>
      )}
    </>
  );
};

export default BetsHistory;
