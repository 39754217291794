import moment from "moment";
import classes from "../../../modules/profile/settings/LoginHistory/LoginHistory.module.css";
import RefPill from "../../../modules/referral/pill";
import { infoIcon } from "../../../static/images";
interface ReferralData {
  createdAt: string;
  username: string;
  referralUsedStatus: string;
  usdEarned: number;
  usdWagered: number;
}
const Table2 = (Props: { thead: string[]; tbody: ReferralData[] }) => {
  return (
    <>
      <table className={`${classes.tableH} w-100`}>
        <thead>
          <tr className={classes.tr}>
            {Props.thead.map((item, index) => (
              <th key={index} className={classes.th}>
                <p className={classes.p}>{item}</p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={classes.tbodyH}>
          {Props.tbody.map((data, index) => (
            <tr key={index} className={classes.tr}>
              <td className={classes.td}>{index + 1}</td>
              <td className={classes.td}>
                {data.username ? "@" + data.username : "---"}
              </td>
              <td className={classes.td}>
                {moment(data.createdAt).format("DD/MM/YYYY")}
              </td>
              <td className={classes.td}>
                <RefPill text={data.referralUsedStatus} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Table2;
