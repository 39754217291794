import { core } from "../config";
import store from "../redux";
import axios, { AxiosError } from "axios";
import { logout } from "../redux/user/actions";
import { toast } from "react-toastify";

interface IHttpError {
  success: false;
  errorName: "Error";
  error: string;
  response: { data?: object }
}

export class HttpError extends Error {
  status: number = 500;
  response: object = {};
  constructor(message?: string, status?: number, response?: { data?: object }) {
    super(message);
    if (status) this.status = status;
    if (response && response.data) this.response = response.data;
  }
}

async function apiCaller<T>(
  endpoint: string,
  method: string = "get",
  body?: any,
): Promise<T> {
  const url = `${core._config.relayerUrl}/${endpoint}`;

  const state = store.getState();

  const headers: any = {
    "content-type": "application/json",
  };

  if (state.user.jwt) headers["x-jwt"] = state.user.jwt;

  try {
    const ret = await axios<T | IHttpError>({
      url,
      headers,
      method,
      data: body,
      // credentials: "include",
      // redirect: "follow",
      // mode: "no-cors",
    });

    const err = ret.data as IHttpError;
    if (ret.status !== 200) throw new HttpError(err.error, ret.status);

    const res = ret.data as T;
    return res;
  } catch (e: any) {
    const err = e as AxiosError;
    if (err.response?.status === 401) {
      toast("Session expired, please login again", { theme: "dark" });
      store.dispatch(logout());  // Dispatch the logout action
    }
    throw new HttpError(err.message, err.response?.status || 500, { data: err.response });
  }
}

export default apiCaller;
