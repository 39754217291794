import IconLoader from "../IconLoader/index";
import classes from "./GlobalTokenDrop.module.css";
import {
  useGetUserInput,
  useUpdateUserInputs,
} from "../../redux/application/hooks";
import TokenRow from "../TokenSelectorModal/TokenRow";
import { useTokenBalance } from "../../redux/token/hooks";
import { useMediaQuery } from "react-responsive";
import { useGetLoggedIn } from "../../redux/user/hooks";
import { useGetCurrencies } from "../../redux/application/hooks";

const DropDown = () => {
  const currencies = useGetCurrencies();
  const isLoggedIn = useGetLoggedIn();
  const isTablet = useMediaQuery({ maxWidth: "992px" });
  const updateUserInput = useUpdateUserInputs();
  const userInput = useGetUserInput();
  const tokenBalance = useTokenBalance(userInput.token);

  return (
    <>
      <div
        className={`dropdown-center customd w-100 ${classes.tokenSelectorDiv} ${classes.div1}`}
      >
        <button
          className={`btn btn-secondary container ${classes.button1} ${classes.dropdownToggle} ${classes.tokenSelector} ${isTablet ? `${classes.tokenSelectorTablet}` : ""}`}
          type="button"
          data-bs-toggle={isLoggedIn ? "dropdown" : ""}
          aria-expanded="false"
        >
          <div className={classes.tokenInfo}>
            <IconLoader
              iconName={userInput.token}
              iconType={"tokenSymbol"}
              image={userInput.image}
              width={20}
              height={20}
            />
            <span> {String(tokenBalance)}</span>
          </div>
          <div className={`${classes.tokenInfo} ${classes.div2}`}>
            <span className={classes.tokenName}>{userInput.token} </span>
            <IconLoader
              iconName={"ArrowDownWhite"}
              iconType={"arrow"}
              width={12}
            />
          </div>
        </button>
        <ul
          className={`dropdown-menu ${classes.dropdownMenu} ${classes.dropdownMenuTablet}`}
        >
          <div className={`option-items ${classes.optionItems}`}>
            {
              // @ts-ignore
              currencies.map((data: IData, index) => {
                return (
                  <TokenRow
                    key={index}
                    index={index}
                    selectedToken={userInput.token}
                    passingToken={data.symbol}
                    image={data.image}
                    setSelectedToken={(el) => {
                      updateUserInput(
                        undefined,
                        undefined,
                        el,
                        undefined,
                        undefined,
                        data.image,
                      );
                    }}
                  />
                );
              })
            }
          </div>
        </ul>
      </div>
    </>
  );
};

export default DropDown;
