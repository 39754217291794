import Card from "../../modules/profile/Card";
import classes from "./referral.module.css";
import { useGetUser } from "../../redux/user/hooks";
const RefCards = (Props: { numberUsers: number; earnings: number }) => {
  const user = useGetUser();
  const coins = user ? user?.referPoints : 0;
  return (
    <>
      <div className={classes.refCards}>
        <Card
          title={"Number of user"}
          value={String(Props.numberUsers)}
          className={"referral"}
        />
        <Card title={"Earnings"} value={String(coins)} className={"referral"} />
      </div>
    </>
  );
};

export default RefCards;
