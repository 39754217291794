import React from "react";
import classes from "./GameCards.module.css";
import { useGetLoggedIn } from "../../../redux/user/hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, Link } from "react-router-dom";
import CardBorderAnimation from "../../../components/animations/CardBorder";
interface IProps {
  game?: string;
  name?: string;
  children?: React.ReactNode;
  gameId: string;
  image: string;
  about: string;
}

const GameCards = (props: IProps) => {
  const location = useLocation();
  const { pathname } = location;

  // const navigate = useNavigate();
  const isLoggedIn = useGetLoggedIn();
  const checkLogin = () => {
    if (!isLoggedIn) {
      return toast("Please login to play", { theme: "dark" });
    }
  };

  return (
    <>
      <CardBorderAnimation
        body={
          <>
            <div
              className={` ${classes["background-wrapper-all"]} ${classes["background-wrapper-" + props.game]}`}
            ></div>
            <div
              className={`${classes["radial"]} gameImg ${classes.gameCard} ${props.game && classes[props.game]}`}
            >
              <Link
                className={classes.rays}
                to={isLoggedIn ? `/game/${props.game}` : pathname}
                state={{
                  gameId: props.gameId,
                  gameName: props.name,
                  gameAbout: props.about,
                  gameImage: props.image,
                }}
                onClick={isLoggedIn ? undefined : checkLogin}
              >
                <img
                  className={classes.img}
                  src={`${process.env.REACT_APP_URL}/assests/images/games/${props.image}`}
                  alt="gameone"
                />
              </Link>
              <p
                className={`${classes["txtAll"]} ${classes["game-name"]} ${props.game ? classes["txt" + props.game] : classes["txtgame"]}`}
              >
                {props.name}
              </p>
            </div>
          </>
        }
      />
    </>
  );
};

export default GameCards;
