import { TwitterShareButton } from "react-share";
import { profileTwitter } from "../../static/images";
import classes from "./referral.module.css";
const RefShare = (Props: { shareUri: string }) => {
  return (
    <>
      <TwitterShareButton
        title="Hello, my mooncake referral link is here"
        url={Props.shareUri}
      >
        <div className={classes.refContentBox2}>
          {profileTwitter}
          <p className={classes.refShare}>SHARE</p>
        </div>
      </TwitterShareButton>
    </>
  );
};

export default RefShare;
