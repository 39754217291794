import classes from "../styles/App.module.css";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ProtectedRoute } from "../Navigation";
import { checkRoutes } from "../helpers/functions";

import HomePage from "../pages/home.page";
import RedirectPage from "../pages/redirect.page";
import Auth from "../components/Auth";
import NotFoundPage from "../pages/notfound.page";
import GamePageList from "../pages/gamelist.page";
import ReferralPage from "../pages/referral.page";
import { useGetSideMenuOpen } from "../redux/application/hooks";

const App = () => {
  const [path, setPath] = useState("");
  const location = useLocation();
  const sideMenuOpen = useGetSideMenuOpen();

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <>
      <div
        className={`container ${sideMenuOpen ? classes['sidebar-open'] : ''} ${checkRoutes(path) ? `${classes.contentBody}` : `${classes.authBody}`}`}
      >
        <Routes>
          <Route path="/set-username" element={<Auth />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/register" element={<Auth />} />
          <Route path="/forget-password" element={<Auth />} />
          <Route path="/otp" element={<Auth />} />
          <Route path="/change-password" element={<Auth />} />
          <Route path="/games" element={<GamePageList />} />

          <Route
            path="/referral"
            element={<ProtectedRoute element={<ReferralPage />} />}
          />
          <Route path="/referred/:code" element={<Auth />} />

          <Route path="/auth/redirect/callback" element={<RedirectPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
