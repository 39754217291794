import classes from "./referral.module.css";

const RefDescription = () => {
  return (
    <>
      <span>
        <h1 className={classes.refDescription}>
          Velit morbi sit sed scelerisque lorem bibendum. Venenatis elit
          venenatis vitae sagittis. Vitae nibh bibendum tincidunt nullam. Purus
          turpis dignissim egestas tortor nec. Feugiat quisque platea ridiculus
          sit a id natoque sit suspendisse.
        </h1>
      </span>
    </>
  );
};

export default RefDescription;
