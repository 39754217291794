import { copyIcon } from "../../static/images";
import classes from "./referral.module.css";
const RefShare = () => {
  return (
    <>
      <div className={classes.refContentBox2}>
        {copyIcon()}
        <p className={classes.refShare}>copy</p>
      </div>
    </>
  );
};

export default RefShare;
