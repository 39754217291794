import classes from "../Profile.module.css";
import { eth } from "../../../static/images";
interface IProps {
  title: string;
  value: string;
  className?: string;
}

const Card = (Props: IProps) => {
  return (
    <>
      <div
        className={`${classes.card} ${Props.className ? `${classes[Props.className]}` : ""}`}
      >
        <p className={classes.cardtitle}>{Props.title}</p>
        <p className={classes.cardvalue}>
          {Props.title === "wagered" && eth(18)}
          {Props.value}
        </p>
      </div>
    </>
  );
};

export default Card;
