import classes from "../wallet.module.css";
import eth from "../../../static/images/wallet/eth.svg";
import { useState } from "react";
import {
  useGetUserInput,
  useUpdateUserInputs,
} from "../../../redux/application/hooks";
import { useTokenBalance } from "../../../redux/token/hooks";
import Drop from "../dropDown";
import { EthIcon } from "../../../static/images";
import Button from "../../../components/ui/Buttons";

const Withdraw = () => {
  const [amount, setAmount] = useState<number>(0.0);
  const [address, setAddress] = useState<string>("");
  const [amountHas, setAmountHas] = useState<number>(0.0);
  const userInput = useGetUserInput();
  const tokenBalance = useTokenBalance(userInput.token);
  return (
    <>
      <div className={`${classes.withDiv1} pt-3 ${classes.wrapBody}`}>
        <div className={classes.HistoryDiv2}>
          <div>
            <p className={classes.inputLabel}>Your Wallet</p>
            <Drop activeTab={"withdraw"} />
          </div>
        </div>
        <div>
          <p className={classes.inputLabel}>
            ETH Address<span className={classes.withSpan1}>*</span>
          </p>
          <div className="d-flex align-items-center">
            <input
              className={`${classes.input} ${classes.inputWithdraw} ${classes.inputWithdrawAdd} ${classes.withInput1}`}
              type="text"
              name=""
              id=""
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-between">
            <p className={classes.inputLabel}>
              Amount<span className={classes.withSpan1}>*</span>
            </p>
            <p className={classes.dollarValue}>$6.55</p>
          </div>

          <div className={classes.withDiv01}>
            <input
              className={`${classes.input} ${classes.inputWithdraw} ${classes.inputWithdrawAmount} ${classes.withInput1}`}
              type="number"
              name=""
              id=""
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
            />
            <div className={classes.withDiv2}>
              {EthIcon(18)}
              <Button
                className={"none"}
                label={<p className={classes.max}>MAX</p>}
                handleClick={() => setAmount(tokenBalance)}
              />
            </div>
          </div>
        </div>
        <div
          className={`d-flex flex-column align-items-center gap-2 ${classes.wrapBody}`}
        >
          <p className={classes.endTextWithdraw}>
            Minimum withdrawal is 0.00003928. Your withdrawal will have
            0.00001571 subtracted from your remaining balance to cover the fee
            required to process the transaction.
          </p>
        </div>
      </div>
    </>
  );
};

export default Withdraw;
