import classes from "./filledinputbox.module.css";

const FilledInputBox = (Props: { value: string }) => {
  return (
    <>
      <div className="d-flex align-items-center w-100">
        <input
          className={`${classes.input} ${classes.relative}`}
          type="text"
          name=""
          id=""
          value={Props.value}
          disabled
        />
      </div>
    </>
  );
};

export default FilledInputBox;
