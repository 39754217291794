import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useGetJWT, useGetLoggedIn, useGetUser } from "./redux/user/hooks";
import GameRoutes from "./routes/index";
import AppRoutes from "./routes/app";
import AppHeader from "./layouts/components/AppHeader/AppHeader";
import SideMenu from "./layouts/components/SideMenu/SideMenu";
import ChatComponent from "./layouts/components/Chat/Chat";
import AppFooter from "./layouts/components/AppFooter/AppFooter";
import FooterNav from "./components/FooterNav";
import { useEffect, useState } from "react";
import AuthHeader from "./components/Auth/AuthHeader";
import { checkRoutes } from "./helpers/functions";
import { ProtectedRouteProps } from "./helpers/interface";

export function ProtectedRoute({ element }: ProtectedRouteProps) {
  const isAuthenticated = useGetLoggedIn();
  return isAuthenticated ? element : <Navigate to="/" />;
}

export default function Navigation() {
  const location = useLocation();
  const [path, setPath] = useState("");
  const user = useGetUser();
  const jwt = useGetJWT();
  const Navigate = useNavigate();
  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  useEffect(() => {
    if (jwt && user && user._id && !user.username) {
      Navigate("/set-username");
    }
  }, [jwt, user, Navigate]);

  return (
    <>
      {checkRoutes(path) && <AppHeader />}
      {!checkRoutes(path) && <AuthHeader />}

      <div
        style={{
          display: location.pathname.includes("/game") ? "block" : "flex",
        }}
      >
        {checkRoutes(path) && <SideMenu />}

        <Routes>
          <Route path="/*" element={<AppRoutes />} />
          <Route path="/game/*" element={<GameRoutes />} />
        </Routes>
      </div>

      {<ChatComponent />}

      <AppFooter />
      {checkRoutes(path) && <FooterNav />}
    </>
  );
}
