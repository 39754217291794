// import dice from "../assets/dice.png";
import classes from "./styles/gamebg.module.css";

const GameBackground = () => {
  return (
    <>
      <div className={classes.bgImage}></div>
    </>
  );
};

export default GameBackground;
