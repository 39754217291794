import classes from "./LoginHistory.module.css";
import LoginHistoryTable from "./LoginHistoryTable";

const LoginHistory = () => {
  return (
    <div className={`${classes.loginHistory} px-4 pb-4`}>
      <p className={classes.loginHistoryTxt}>Login History</p>
      <LoginHistoryTable />
    </div>
  );
};

export default LoginHistory;
