import classes from "../Profile.module.css";
import { useCallback, useEffect, useRef } from "react";
import { logout } from "../../../redux/user/actions";
import { useDispatch } from "react-redux";
import { clearStorage } from "../../../redux/bets/actions";
import Download from "../../../components/Download";
import { useNavigate } from "react-router-dom";
import { clearApp } from "../../../redux/application/actions";
import Button from "../../../components/ui/Buttons";
import { useGetRivalOpen } from "../../../redux/application/hooks";
const ExpandMenu = (Props: {
  open: boolean;
  handleClose: () => void;
  handleClick: () => void;
  handleCloseProfile: () => void;
  title: string;
}) => {
  const rivalOpen = useGetRivalOpen();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const charmRef = useRef<HTMLDivElement>(null);
  const handleLogout = () => {
    Props.handleClose();
    Props.handleCloseProfile();
    Navigate("/");
  };
  const doLogout = useCallback(() => {
    dispatch(clearStorage());
    dispatch(clearApp());
    dispatch(logout());
  }, [dispatch]);

  const handleClickOutside = (event: MouseEvent) => {
    if (charmRef.current && !charmRef.current.contains(event.target as Node)) {
      Props.handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePrivacy = () => {
    // toast("Coming soon", { theme: "dark" });
  };

  return (
    <>
      {Props.open && (
        <div className={classes.expanMenu} ref={charmRef}>
          <ul>
            <li>
              <Button
                className={"none"}
                label={"Log out"}
                handleClick={() => {
                  doLogout();
                  handleLogout();
                }}
              />
            </li>
            {Props.title !== "settings" && !rivalOpen && (
              <li>
                <Button
                  className={"none"}
                  label={"Setting"}
                  handleClick={Props.handleClick}
                />
              </li>
            )}
            <li>
              <Download string={"Privacy"} type={"privacy"} />
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default ExpandMenu;
