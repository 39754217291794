import { useState } from "react";
import Button from "../ui/Buttons";
import classes from "./SwitchTabs.module.css";

interface IProps {
  className: string;
  lables: string[];
  handleTab?: (tab: string) => void;
  activeTab?: string;
}

const SwitchTabs = ({ className, lables, handleTab, activeTab }: IProps) => {
  return (
    <>
      <div className={`${classes[className]} col-12`}>
        {lables?.map((label, index) => (
          <Button
            key={index}
            label={label}
            className={`${activeTab === label ? "activeTab" : "inactiveTab"}`}
            handleClick={() => handleTab?.(label)}
          />
        ))}
      </div>
    </>
  );
};

export default SwitchTabs;
