import classes from "../wallet.module.css";
import eth from "../../../static/images/wallet/eth.svg";
import weth from "../../../static/images/wallet/wETH.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useState } from "react";
import Drop from "../dropDown";

const History = () => {
  const [limitRef, setLimitRef] = useState(20);
  const [hasmore, setHasmore] = useState(true);
  const fetchMoreData = () => {
    if (!hasmore) {
      setHasmore(false);
      return;
    } else {
      // call api for data
    }
  };

  // dummy data for currency
  const currency = [
    {
      _id: {
        $oid: "65dda31b3f25f03b4dd9b8d2",
      },
      address: "2435465ytrgfvdfr5t4etgrfvdrwe4tdssdvseawss",
      isBlockchain: true,
      name: "Ethereum",
      frozen: false,
      minimumWithdrawalAmount: 0.003,
      enableWithdrawals: true,
      symbol: "ETH",
      createdAt: {
        $date: "2024-02-27T08:53:47.654Z",
      },
      updatedAt: {
        $date: "2024-02-27T08:53:47.654Z",
      },
      __v: 0,
    },
    {
      _id: {
        $oid: "65dda31b3f25f03b4dd9b8d9",
      },
      address: "h4es5465ytrgfvdfr5t4etgrfvdrwe4tdssdvseawss",
      isBlockchain: false,
      name: "Mooncake",
      frozen: false,
      minimumWithdrawalAmount: 0,
      enableWithdrawals: false,
      symbol: "MCAKE",
      createdAt: {
        $date: "2024-02-27T08:53:47.676Z",
      },
      updatedAt: {
        $date: "2024-02-27T08:53:47.676Z",
      },
      __v: 0,
    },
    {
      _id: {
        $oid: "65dda31b3f25f03b4dd9b8d2",
      },
      address: "2435465ytrgfvdfr5t4etgrfvdrwe4t5erfsdawe3q2",
      isBlockchain: true,
      name: "Wrapped Ethereum",
      frozen: false,
      minimumWithdrawalAmount: 0.003,
      enableWithdrawals: true,
      symbol: "wETH",
      createdAt: {
        $date: "2024-02-27T08:53:47.654Z",
      },
      updatedAt: {
        $date: "2024-02-27T08:53:47.654Z",
      },
      __v: 0,
    },
  ];

  const dummyList = Array.from({ length: 10 }).map((_, index) => {
    return (
      <div className={classes.dummyListDiv1}>
        {/* data goes here */}
        <p className={`col-4 p-0 ${classes.dummyListP1}`}>B32c6vgher…443dc</p>
        <p className={`col-4 ${classes.dummyListP2}`}>
          <img src={eth} width={18} height={18} alt="" /> ETH
        </p>
        <p className={`col-4 ${classes.dummyListP3}`}>
          <img src={weth} width={20} height={20} alt="" /> 3.21
        </p>
      </div>
    );
  });

  return (
    <>
      <div className={`${classes.HistoryDiv1} pt-3 ${classes.wrapBody}`}>
        <div className={classes.HistoryDiv2}>
          <div>
            <p className={classes.inputLabel}>Select account</p>
            <Drop activeTab="deposit" data={currency} type="currency" />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className={classes.historyth}>ADDRESS</p>
          <p className={classes.historyth}>NETWORK</p>
          <p className={classes.historyth}>AMOUNT</p>
        </div>
        <p className={classes.divider}></p>
      </div>
      {/* Infinte scroll */}
      <div className="w-100">
        <InfiniteScroll
          dataLength={limitRef}
          next={fetchMoreData}
          hasMore={hasmore}
          loader={
            <span className="d-flex justify-content-center">No more data</span>
          }
          height={300}
          style={{ overflowY: "auto", overflowX: "clip", width: "100%" }}
        >
          {/* data goes here */}
          <div className={classes.HistoryIFSDiv1}>{dummyList}</div>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default History;
