// import { AppState } from "../../redux";
// import { useConfettiToggle } from "../../redux/application/hooks";
// import { useSelector } from "react-redux";
// import BetsHistory from "./components/BetsHistory";
// import Confetti from "react-confetti";
import PlayingArea from "./PlayingArea";
// import BetsHistory from "./components/BetsHistory";
// import Explain from "./Explain";

// import "./style.sass";

const Dice = () => {
  return (
    <>
      <PlayingArea />
    </>
  );
};

export default Dice;
