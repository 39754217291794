import Button from "../ui/Buttons";
import { useNavigate } from "react-router-dom";

const SignUpButton = (Props: { className?: string }) => {
  const navigateTo = useNavigate();
  return (
    <>
      <Button
        className={`singUp ${Props.className}`}
        handleClick={() => {
          navigateTo("register");
        }}
        label={"register"}
      ></Button>
    </>
  );
};

export default SignUpButton;
