import classes from "./Auth.module.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../ui/Buttons";
import chevronRight from "../../static/images/auth/chevron-right.svg";
import { toast } from "react-toastify";
import {
  updatePassword,
  changePassword,
  clearSession,
} from "../../helpers/ServerApi";
import { useSetJWT } from "../../redux/user/hooks";
import eye from "../../static/images/auth/eye.svg";
import eyeOff from "../../static/images/auth/eye-off.svg";
import { prevRoute } from "../../redux/application/actions";
import { useDispatch } from "react-redux";
import Foot from "./foot";
import { logout } from "../../redux/user/actions";

interface ITypeOtp {
  otp?: string;
}

const ForgetPassword = (props: { otp: ITypeOtp }) => {
  const dispatch = useDispatch();
  const { otp } = props;
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/change-password") {
      const path = location.pathname;
      dispatch(prevRoute({ route: path }));
    }
  }, []);

  const Navigate = useNavigate();
  if (
    // !location.state?.otpVerified ||
    location.state?.changePassword === true
  ) {
    Navigate("/");
  }
  const setJWT = useSetJWT();
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
    oldPassword: "",
  });

  const conti = (
    <>
      <div className={`d-flex align-items-center justify-content-center gap-2`}>
        <p className={classes.discordText}>continue</p>
        {loading && (
          <i className="fa fa-spinner fa-spin" style={{ fontSize: "20px" }}></i>
        )}
        {!loading && <img src={chevronRight} alt="" />}
      </div>
    </>
  );

  const IsValidPassword = (word: string) => {
    const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])/;
    const result = pattern.test(word) || word.length === 0;
    return result;
  };

  const matchPassword = (word: string) => {
    const result = word === password;
    return result;
  };

  const changeHandler = (e: any) => {
    if (e.target.value.length > 15) {
      return;
    } else {
      setError({
        oldPassword: "",
        password: "",
        confirmPassword: "",
      });
      if (e.target.name === "password") {
        setPassword(e.target.value);
      } else if (e.target.name === "confirmPassword") {
        setConfirmPassword(e.target.value);
      } else if (e.target.name === "oldPassword") {
        setOldPassword(e.target.value);
      }
    }
  };

  const handleSubmitReset = async (
    password: string,
    confirmPassword: string,
  ) => {
    const result = passwordValidation(password, confirmPassword);
    if (result === false) {
      return;
    } else {
      setLoading(true);
      const payload = {
        userId: location.state?._id,
        otp: otp,
        newPassword: password,
        cnfPassword: confirmPassword,
      };
      updatePassword(payload)
        .then((res: any) => {
          setLoading(false);
          if (res.success === true || res.status === 200) {
            if (res?.user?.token) {
              toast("Password Changed Successfully", {
                theme: "dark",
              });
              setJWT(res?.user?.token);
              Navigate("/", { replace: true });
            }
          } else {
            toast("Unable to change password", {
              theme: "dark",
            });
          }
        })
        .catch((err: any) => {
          setLoading(false);
          toast(err.response?.data.error, {
            theme: "dark",
          });
        });
    }
  };

  const showToast = (message: string) => {
    toast(message, { theme: "dark" });
  };

  const checkEmptyPasswords = (
    password: string,
    confirmPassword: string,
  ): boolean => {
    if (!password || !confirmPassword) {
      showToast(
        `Please enter ${!password ? "password " : ""}${!password && !confirmPassword ? "and " : ""}${!confirmPassword ? "confirm password" : ""}`,
      );
      return false;
    }
    return true;
  };

  const checkPasswordLength = (
    password: string,
    confirmPassword: string,
  ): boolean => {
    if (password.length < 6 || confirmPassword.length < 6) {
      showToast("Password should be char length of min 6 and max 15");
      return false;
    }
    return true;
  };

  const checkPasswordValidity = (
    password: string,
    confirmPassword: string,
  ): boolean => {
    if (!IsValidPassword(password)) {
      showToast("Password should include min 1 char and 1 number");
      return false;
    }
    if (!IsValidPassword(confirmPassword)) {
      showToast("Confirm password should include min 1 char and 1 number");
      return false;
    }
    return true;
  };

  const checkPasswordsMatch = (
    password: string,
    confirmPassword: string,
  ): boolean => {
    if (password !== confirmPassword) {
      showToast("Passwords do not match");
      return false;
    }
    return true;
  };

  const passwordValidation = (
    password: string,
    confirmPassword: string,
  ): boolean => {
    if (
      !checkEmptyPasswords(password, confirmPassword) ||
      !checkPasswordLength(password, confirmPassword) ||
      !checkPasswordValidity(password, confirmPassword) ||
      !checkPasswordsMatch(password, confirmPassword)
    ) {
      return false;
    }
    return true;
  };

  const handleChangePassword = (
    oldPassword: string,
    password: string,
    confirmPassword: string,
  ) => {
    if (!oldPassword || !password || !confirmPassword) {
      toast(`Please fill required fields`, { theme: "dark" });
      setError({
        oldPassword: oldPassword === "" ? "required" : "",
        confirmPassword: confirmPassword === "" ? "required" : "",
        password: password === "" ? "required" : "",
      });
      return;
    }
    if (oldPassword && password && confirmPassword) {
      console.log("oldPassword :: ", oldPassword);
    }
    if (oldPassword === password) {
      toast("Old password and new password cannot be same", { theme: "dark" });
      return;
    } else {
      const payload = {
        oldPassword: oldPassword,
        newPassword: password,
        cnfPassword: confirmPassword,
      };
      changePassword(payload)
        .then(async (res: any) => {
          setLoading(false);
          if (res.success === true || res.status === 200) {
            if (res?.user?.token) {
              toast("Password reset Successfully", {
                theme: "dark",
              });
              setJWT("res?.user?.token");
              Navigate("/", { replace: true });
            } else if (!res?.user?.token) {
              toast("Password Changed Successfully", {
                theme: "dark",
              });
              await clearSession();
              dispatch(logout());
              Navigate("/", { replace: true });
            }
          } else {
            toast("Unable to change password, try again", {
              theme: "dark",
            });
          }
        })
        .catch((err: any) => {
          setLoading(false);
          toast(err.response?.data.error, {
            theme: "dark",
          });
        });
    }
  };

  return (
    <>
      <div className={classes.box + " " + classes.center}>
        <div
          style={{ gap: "2px" }}
          className="d-flex align-items-center justify-content-center flex-column"
        >
          <span className="col d-flex align-items-center justify-content-center w-100">
            {location.state.type === "changePassword" && (
              <i
                style={{ fontSize: "24px" }}
                className={`fa ${classes.backIcon} col-1`}
                title="Back"
                onClick={() => Navigate(-1)}
              >
                &#xf104;
              </i>
            )}
            <p className={`${classes.title} col-11 w-100`}>
              Change your password
            </p>
          </span>

          {location.state.type !== "changePassword" && (
            <p className={classes.subtitle}>
              No worries, enter the following details
            </p>
          )}
        </div>
        <div className={classes.signDiv3}>
          {location.state.type === "changePassword" && (
            <>
              <div className={`${classes.inputContainer}`}>
                <input
                  className={
                    IsValidPassword(password) === true || wrongPass === true
                      ? `${classes.input} ${classes.inputPadding2}`
                      : `${classes.error} ${classes.inputPadding2}`
                  }
                  type="password"
                  placeholder="Old password"
                  pattern=".{6,15}"
                  title="Please enter one char, one number with min 6 and max 15"
                  value={oldPassword}
                  name="oldPassword"
                  onChange={(e) => {
                    changeHandler(e);
                  }}
                />
              </div>
              <div className={`d-flex flex-column align-items-start`}>
                {IsValidPassword(oldPassword) === false ? (
                  <i
                    className="fa fa-times-circle"
                    style={{ color: "#ff9494 " }}
                  >
                    {" "}
                    <span
                      className={classes.authSwitch}
                      style={{ color: "#ff9494 " }}
                    >
                      &nbsp;Password should include min 1 char and 1 number
                    </span>
                  </i>
                ) : null}
                {oldPassword && oldPassword.length < 6 && (
                  <i
                    className="fa fa-times-circle"
                    style={{ color: "#ff9494 " }}
                  >
                    {" "}
                    <span
                      className={classes.authSwitch}
                      style={{ color: "#ff9494 " }}
                    >
                      &nbsp;6 - 15 length
                    </span>
                  </i>
                )}
                {error?.oldPassword && (
                  <i
                    className="fa fa-times-circle"
                    style={{ color: "#ff9494 " }}
                  >
                    {" "}
                    <span
                      className={classes.authSwitch}
                      style={{ color: "#ff9494 " }}
                    >
                      &nbsp;{error.oldPassword}
                    </span>
                  </i>
                )}
              </div>
            </>
          )}
          <div className={`${classes.inputContainer}`}>
            <input
              className={
                IsValidPassword(password) === true || wrongPass === true
                  ? `${classes.input} ${classes.inputPadding2}`
                  : `${classes.error} ${classes.inputPadding2}`
              }
              type="password"
              placeholder="Password"
              pattern=".{6,15}"
              title="Please enter one char, one number with min 6 and max 15"
              value={password}
              name="password"
              onChange={(e) => {
                changeHandler(e);
              }}
            />
          </div>
          <div className={` d-flex flex-column align-items-start`}>
            {IsValidPassword(password) === false ? (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;Password should include min 1 char and 1 number
                </span>
              </i>
            ) : null}
            {password && password.length < 6 && (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;6 - 15 length
                </span>
              </i>
            )}
            {error?.password && (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;{error.password}
                </span>
              </i>
            )}
          </div>

          <div className={`${classes.inputContainer}`}>
            <input
              className={
                matchPassword(confirmPassword) === true || wrongPass === true
                  ? `${classes.input} ${classes.inputPadding2}`
                  : confirmPassword?.length
                    ? `${classes.error} ${classes.inputPadding2}`
                    : `${classes.input} ${classes.inputPadding2}`
              }
              type={`${showConfPassword ? "text" : "password"}`}
              placeholder="Confirm Password"
              title="Please enter one char, one number with min 6 and max 15"
              value={confirmPassword}
              name="confirmPassword"
              onChange={(e) => {
                changeHandler(e);
              }}
            />
            <span
              onClick={() => {
                setShowConfPassword(!showConfPassword);
              }}
              // As per sonarqube report added below code
              onKeyDown={() => {
                setShowConfPassword(!showConfPassword);
              }}
              role="button"
              tabIndex={0}
            >
              <img
                src={showConfPassword ? eye : eyeOff}
                alt="Eye Icon"
                className={classes.eyeIcon}
              />
            </span>
          </div>
          <div className={` d-flex flex-column align-items-start`}>
            {confirmPassword?.length && password !== confirmPassword ? (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;Password does not match
                </span>
              </i>
            ) : null}
            {error?.confirmPassword && (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;{error.confirmPassword}
                </span>
              </i>
            )}
          </div>
        </div>

        <Button
          label={conti}
          handleClick={() => {
            location.state.type !== "changePassword"
              ? handleSubmitReset(password, confirmPassword)
              : handleChangePassword(oldPassword, password, confirmPassword);
          }}
          className={"authContinue"}
        />
        <Foot />
      </div>
    </>
  );
};

export default ForgetPassword;
