import RefHeader from "../../components/referral/header";
import RefDescription from "../../components/referral/description";
import RefLink from "../../components/referral/referralLink";
import RefCards from "../../components/referral/countCard";
import classes from "./referral.module.css";
import { useEffect, useState } from "react";
import RefNoData from "../../components/referral/refNoData";
import useGetReferral from "../../hooks/useGetReferral";
import useGEtReferred from "../../hooks/useGetReferred";
import Table from "../../components/referral/table";
import Pagination from "../../components/ui/pagination/Pagination";
import { useGetUserId } from "../../redux/user/hooks";
interface ReferralData {
  createdAt: string;
  username: string;
  referralUsedStatus: string;
  usdEarned: number;
  usdWagered: number;
}

const ReferralIndex = () => {
  const userId = useGetUserId();
  const referralData = useGetReferral();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const referredData = useGEtReferred(page, limit);
  const [tableData, setTableData] = useState<ReferralData[]>([]);
  const [numberUsers, setNumberUsers] = useState(0);
  const [earnings, setEarnings] = useState(0);
  // const [selfCode, setSelfCode] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (referralData) {
      setNumberUsers(referralData.numberOfUsers);
      setEarnings(referralData.usdEarned + referralData.usdWagered);
      // setSelfCode(referralData.referralCode);
    }
    if (referredData) {
      setTableData(referredData);
    }
  }, [referralData, referredData]);

  const handlePage = (page: number) => {
    setPage(page);
  };

  const handleLimit = (limit: number) => {
    setLimit(limit);
  };
  return (
    <>
      <div className="d-flex flex-column w-100 gap-4 mb-4">
        <div className={classes.referral}>
          <RefHeader />
          <RefDescription />
          <RefLink code={window.btoa(userId + " /")} />
          <RefCards numberUsers={numberUsers} earnings={earnings} />
        </div>
        {tableData.length ? (
          <>
            <div className={`${classes.refTableDiv} gap-3 d-flex flex-column`}>
              <Table
                tableData={tableData}
                totalData={numberUsers}
                handlePage={handlePage}
                handleLimit={handleLimit}
              />
            </div>
            <Pagination
              totalRecords={numberUsers}
              limit={limit}
              page={page}
              setPage={setPage}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          </>
        ) : (
          <>
            <RefNoData />
          </>
        )}
      </div>
    </>
  );
};

export default ReferralIndex;
