import classes from "./Auth.module.css";
import { googleIcon, twitterIcon, discordIcon } from "../../static/images";

export const discord = (
  <>
    <div className={`d-flex align-items-center justify-content-center gap-2`}>
      {discordIcon(24)}
      <p className={classes.discordText}>Continue with Discord</p>
    </div>
  </>
);

export const google = (
  <>
    <div className={`d-flex align-items-center justify-content-center gap-2`}>
      {googleIcon(24)}
      <p className={classes.discordText}>Google</p>
    </div>
  </>
);

export const twitter = (
  <>
    <div className={`d-flex align-items-center justify-content-center gap-2`}>
      {twitterIcon(24)}
      <p className={classes.discordText}>X(Twitter)</p>
    </div>
  </>
);
