import React, { useState, useEffect } from "react";
// import Button from "../ui/Buttons";
import classes from "./SwitchTable.module.css";
import { useLocation } from "react-router-dom";

// import { useMediaQuery } from 'react-responsive';
// import SwitchTabs from '../SwitchTabs';

interface IProps {
  lables: string[];
  tableData?: React.ReactNode[];
}
// TODO:: Accept data as props for the table later
const FadeInTabs = (props: IProps) => {
  const location = useLocation();
  const [tableRows, setTableRows] = useState<
    React.FunctionComponentElement<any>[]
  >([]);

  useEffect(() => {
    const interval = setInterval(() => {}, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {props.tableData?.length ? (
        <>
          <div style={{ overflowX: "auto" }}>
            <table className={classes.table}>
              <thead>
                <tr className={classes.tableHeading}>
                  {props.lables?.map((label, index) => (
                    <th
                      key={index}
                      className={
                        index === props?.lables?.length - 1
                          ? `${classes.thright}`
                          : `${classes.thiniti}`
                      }
                      // style={{
                      //   float:
                      //     index === props?.lables?.length - 1
                      //       ? "right"
                      //       : "initial",
                      // }}
                    >
                      {label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{props.tableData ? props.tableData : tableRows}</tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <span className="d-flex justify-content-center">No data found</span>
        </>
      )}
    </>
  );
};

export default FadeInTabs;
