import React from "react";
import about from "../../static/pdfs/about.pdf";
import contactSupport from "../../static/pdfs/contactSupport.pdf";
import helpCenter from "../../static/pdfs/helpCenter.pdf";
import privacy from "../../static/pdfs/privacy.pdf";
import tc from "../../static/pdfs/t&c.pdf";
import blog from "../../static/pdfs/blog.pdf";
import classes from "./Download.module.css";
import termsOfService from "../../static/pdfs/termsOfService.pdf";
import welcome from "../../static/pdfs/welcome.pdf";
import { NavLink } from "react-router-dom";
import { useGetLoggedIn } from "../../redux/user/hooks";

interface DownloadProps {
  type:
    | "about"
    | "contactSupport"
    | "helpCenter"
    | "privacy"
    | "tc"
    | "termsOfService"
    | "welcome"
    | "blog";
  string: string;
}

const Download: React.FC<DownloadProps> = (props) => {
  const isLoggedIn = useGetLoggedIn();
  const refMap = {
    about: about,
    contactSupport: contactSupport,
    helpCenter: helpCenter,
    privacy: privacy,
    tc: tc,
    termsOfService: termsOfService,
    welcome: welcome,
    blog: blog
  };

  if (props.type === "welcome" && !isLoggedIn) {
    return (
      <>
        <NavLink to={"/login"} className={classes.support}>
          {props.string}
        </NavLink>
      </>
    );
  } else {
    return (
      <>
        <a
          className={classes.support}
          href={refMap[props.type]}
          target="_blank"
          rel="noreferrer"
        >
          {props.string}
        </a>
      </>
    );
  }
};

export default Download;
