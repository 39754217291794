import ProfileClose from "./close";
import Charm from "./charm";
import classes from "../Profile.module.css";
import { profileIcon, setting } from "../../../static/images";
import ProfileBack from "./back";
import { useGetUserName } from "../../../redux/user/hooks";
import { useGetRivalUsername } from "../../../redux/application/hooks";

const ProfileHeader = (Props: {
  handleClose: () => void;
  title: string;
  handleClick: () => void;
  handleClickProfile: () => void;
}) => {
  const username = useGetUserName();
  const rivalUsername = useGetRivalUsername();
  return (
    <>
      <div className={classes.header}>
        {/* {Props.title === "profile" ? (
          <ProfileClose handleClose={Props.handleClose} />
        ) : (
          <ProfileBack handleClick={Props.handleClickProfile} />
        )} */}
        {Props.title === "profile" && (
          <ProfileClose handleClose={Props.handleClose} />
        )}
        {Props.title !== "settings" && username !== rivalUsername && (
          <ProfileClose handleClose={Props.handleClose} />
        )}
        {Props.title === "settings" && (
          <ProfileBack handleClick={Props.handleClickProfile} />
        )}

        <div
          className={`d-flex col-12 justify-content-center align-items-center ${classes.headerTxtGroup}`}
        >
          <span>
            {/* {Props.title === "profile" ? (
              <>{profileIcon(24)}</>
            ) : (
              <>{setting}</>
            )} */}
            {(Props.title === "profile" ||
              (Props.title !== "settings" && username !== rivalUsername)) && (
              <>{profileIcon(24)}</>
            )}

            {Props.title === "settings" && <>{setting}</>}
          </span>
          <h1 className={`${classes.headerTxt} m-0 p-0`}>{Props.title}</h1>
        </div>
        <Charm
          handleClick={Props.handleClick}
          handleCloseProfile={Props.handleClose}
          title={Props.title}
        />
      </div>
    </>
  );
};

export default ProfileHeader;
