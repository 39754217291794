import classes from "../Profile.module.css";
import { backIcon } from "../../../static/images";
import Button from "../../../components/ui/Buttons";

const ProfileBack = (Props: { handleClick: () => void }) => {
  return (
    <>
      <Button
        className={"none"}
        label={
          <span
            className={`${classes.cursorPointer} ${classes.charm} ${classes.headerSpan1}`}
          >
            {backIcon}
          </span>
        }
        handleClick={Props.handleClick}
      />
    </>
  );
};

export default ProfileBack;
