import classes from "./firstTimePlay.module.css";

interface IProps {
  title: string;
}

const FirstPlayTitle = ({ title }: IProps) => {
  return (
    <>
      <p className={classes.title}>{title}</p>
    </>
  );
};

export default FirstPlayTitle;
