import { connections } from "../../../../helpers/constants";
import {
  profileGoogle,
  profileDiscord,
  profileTwitter,
} from "../../../../static/images";
import classes from "./Connections.module.css";

// Define type for iconsConn object
interface IconsConn {
  [key: string]: JSX.Element;
}

const iconsConn: IconsConn = {
  google: profileGoogle,
  discord: profileDiscord,
  twitter: profileTwitter,
};

const Connections = () => {
  return (
    <div className={`d-flex flex-column px-4 pb-4 gap-3 ${classes.connections}`}>
      <p className={classes.connectionString}>Connections</p>
      <div className="d-flex col-12 flex-column gap-3">
        {connections.map((connection: string) => (
          <>
            <div
              key={connection}
              className="d-flex col-12 justify-content-between"
            >
              <div key={connection} className="d-flex col-10 gap-2">
                {iconsConn[connection]}
                <span className={classes.connectionTxt}>
                  {connection === "twitter" ? "X(Twitter)" : connection}
                </span>
              </div>
              <div className={classes.switch}>
                <label>
                  <input type="checkbox" disabled/>
                  <span className={`${classes.slider} ${classes.round}`}>{" "}</span>
                </label>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default Connections;
