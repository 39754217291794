import classes from "./Buttons.module.css";

export type IButtonType = {
  ref?:
    | React.Ref<HTMLButtonElement>
    | React.RefObject<HTMLButtonElement>
    | null;
  label: string | React.ReactNode;
  handleClick: () => void;
  className: string;
  color?: string;
  handleFocus?: () => void;
  handleBlur?: () => void;
};

const Button = ({
  ref,
  label,
  handleClick,
  handleFocus,
  handleBlur,
  className,
}: IButtonType) => {
  // Split the className string into an array of class names
  let classNames = "";
  if (className !== undefined && className !== null && className !== "") {
    classNames = className
      .split(" ")
      .map((cn: string) => classes[cn])
      .join(" ");
  }

  return (
    <button
      ref={ref}
      className={`${classes.buttonPrime} ${classNames}`}
      onClick={handleClick} // Add the data-bs-toggle attribute
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {label}
    </button>
  );
};

export default Button;
