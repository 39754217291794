// import classes from "./GlobalTokenSelector.module.css";
import classes from "./GTS.module.css";
import { useMediaQuery } from "react-responsive";
import DropDown from "../GlobalTokenDropDown";
import Button from "../ui/Buttons";
import BootstrapModal from "../BootstrapModal";
import { useState } from "react";
import Wallet from "../../modules/wallet/index";
import WalletTitle from "../../modules/wallet/title";
import { useGetLoggedIn } from "../../redux/user/hooks";
import { useNavigate } from "react-router-dom";

const GlobalTokenSelector = () => {
  const isLoggedIn = useGetLoggedIn();
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ maxWidth: "769px" });
  const [openWallet, setOpenWallet] = useState(false);

  const closeModal = () => {
    setOpenWallet(false);
  };

  return (
    <>
      {openWallet && (
        <BootstrapModal
          open={true}
          title={<WalletTitle />}
          message={<Wallet />}
          buttonTitle={"none"}
          height={"436px"}
          handleClose={() => {
            closeModal();
          }}
          width="436px"
          className="modalWallet"
        />
      )}

      <div className={classes.gddiv + " " + classes.div1}>
        <DropDown />
        <Button
          className="wallet"
          label="wallet"
          handleClick={() => {
            isLoggedIn ? setOpenWallet(true) : navigate("/login");
          }}
        ></Button>
      </div>
    </>
  );
};

export default GlobalTokenSelector;
