import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../index";

import { useCallback } from "react";
import {
  addBet, addLivefeedBets,
  //  addCurrentBet 
} from "./actions";
import { IBetsState } from "./reducer";
import { IBetResult, ILiveFeedBets } from "../../helpers/interface";

export function safeGetBetOf(state: IBetsState, who: string, key: string) {
  const betsOfWho = state.bets[who];
  const bet = betsOfWho[key];
  return betsOfWho && bet ? bet : {};
}

export function useGetBetDetailsOf(key: string, who: string) {
  return useSelector((state: AppState) => {
    return safeGetBetOf(state.bets, who, key);
  });
}

export function useGetAllBetsFor(who: string) {
  const bets = useSelector((state: AppState) => state.bets.bets);
  const betsOfWho = bets[who];
  return betsOfWho ? betsOfWho : {};
}

// export function useGetLivefeedBets() {
//   const bets = useSelector((state: AppState) => state.bets.liveFeed);
//   return bets || [];
// }

export function useAddBets(): (bet: IBetResult) => void {
  const dispatch = useDispatch();

  return useCallback(
    (props) => {
      dispatch(addBet(props));
    },
    [dispatch],
  );
}

export function useAddLivefeedBets(): (bet: ILiveFeedBets[]) => void {
  const dispatch = useDispatch();

  return useCallback(
    (props) => {
      dispatch(addLivefeedBets(props));
    },
    [dispatch],
  );
}

export function useCurrentBet(): IBetResult | undefined {
  const bet = useSelector((state: AppState) => state.bets.currentBet);
  return bet;
}

// export function useHighrollerBet(): IBetResult | undefined {
//   const bet = useSelector((state: AppState) => state.bets.highrollerBet);
//   return bet;
// }

// export function useCurrentAllBet() {
//   const bet = useSelector((state: AppState) => state.bets.currentBetAll);
//   return bet;
// }

