import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { toggleChatBox } from "../../redux/application/actions";
import { chatIcon, chatIconPng } from "../../static/images";

const ChatIconComponent = (props: { width?: string }) => {
  const [openChat, setOpenChat] = useState(false);
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (!isInitialMount.current) {
      dispatch(toggleChatBox({ key: openChat }));
    } else {
      isInitialMount.current = false;
    }
  }, [openChat, dispatch]);

  return (
    <>
      <span
        onClick={() => setOpenChat(!openChat)}
        // as per sonarqube report changes below
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
          }
        }}
        role="button"
        tabIndex={0}
      >
        {chatIconPng(props.width ? props.width : "24")}
      </span>
    </>
  );
};

export default ChatIconComponent;
