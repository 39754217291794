import { toast } from "react-toastify";
import { arrowUpRight } from "../../../static/images";
import classes from "../Profile.module.css";
import Button from "../../../components/ui/Buttons";

const ViewAllBets = () => {
  const handleClickViewAll = () => {
    toast("Coming soon", { theme: "dark" });
  };
  return (
    <>
      <div className="col-12 d-flex px-4 justify-content-end">
        <Button
          className={"none"}
          label={
            <p className={classes.viewAllBets}>View All Bets {arrowUpRight}</p>
          }
          handleClick={handleClickViewAll}
        />
      </div>
    </>
  );
};
export default ViewAllBets;
