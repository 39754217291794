import "../styles/index.css";
import classes from "./styles/game.module.css";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getGame } from "../helpers/ServerApi";
import { useGetBetModalOpen } from "../redux/application/hooks";
import { useDispatch } from "react-redux";
import { currentGame } from "../redux/application/actions";
import useHandleProfileTab from "../hooks/useHandleProfile";

import Dice from "../modules/dice";
import GameBackground from "./gamebg.page";
import MyBets from "../components/MyBets/MyBetsComponent";
import AboutTheGame from "../components/aboutTheGame";
import SwitchTabs from "../components/SwitchTabs";
import BootstrapModal from "../components/BootstrapModal";
import FirstPlayTitle from "../components/FirstTimePlay/title";
import FirstPlayContent from "../components/FirstTimePlay/content";
import Loading from "../components/Loading/index";
import NotFoundPage from "./notfound.page";
interface IProps {
  game?: string;
  name?: string;
  children?: React.ReactNode;
  state: {
    gameId: string;
    gameName: string;
    gameAbout: string;
    gameImage: string;
  };
}
const Game = (props: IProps) => {
  const location = useLocation();
  useHandleProfileTab();
  const [gameId, setGameId] = useState<string>("");
  const [gameName, setGameName] = useState<string>("");
  const params = useParams();
  const [firsTimeplay, setFirstTimePlay] = useState(false);
  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState<string>("");
  const [activeTab, setActiveTab] = useState("current");
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();
  dispatch(currentGame({ gameId: gameId }));
  const betHistoryModal = useGetBetModalOpen();

  useEffect(() => {
    if (props.state?.gameId) {
      setGameId(props.state.gameId);
    }
    if (props?.state?.gameId === undefined) {
      setLoading(true);
      const name = location.pathname.split("/");
      getGame(name[name.length - 1]).then((res: any) => {
        setGameId(res.games._id);
      });
    }
  }, [props.state?.gameId]);

  useEffect(() => {
    const route = Object.values(params)[0];
    if (route) setGame(route);
    else setGame("");
  }, [params]);

  useEffect(() => {
    if (betHistoryModal === false)
      window.scroll({ top: 100, left: 0, behavior: "smooth" });
  }, [betHistoryModal]);

  useEffect(() => {
    if (firsTimeplay === false) setLoading(false);
  }, [firsTimeplay, loading]);

  function renderSwitch(params: any) {
    switch (params) {
      case "dice-2d":
        return <Dice />;
      default:
        return <NotFoundPage />;
    }
  }

  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className={classes.div1}>
        <GameBackground />
        {loading ? (
          <Loading />
        ) : (
          <>
            {firsTimeplay === true && openModal === true && (
              <BootstrapModal
                open={true}
                title={<FirstPlayTitle title={"DIGITAL DICE"} />}
                message={
                  <FirstPlayContent
                    handleClose={() => {
                      closeModal();
                    }}
                  />
                }
                buttonTitle={"none"}
                height={"436px"}
                handleClose={() => {
                  closeModal();
                }}
                width="436px"
                className="modalBetDetail"
              />
            )}
            <div className={classes.gameBets}>
              <SwitchTabs
                className="leaderTabs"
                lables={["current", "high rollers", "my bets"]}
                activeTab={activeTab}
                handleTab={handleTab}
              />
              {gameId && (
                <MyBets
                  handleFirstPlay={(firstPlay, openModal) => {
                    setFirstTimePlay(firstPlay);
                    setOpenModal(openModal);
                  }}
                  gameId={gameId}
                  activeTab={activeTab}
                />
              )}
            </div>

            <AboutTheGame
              heading="About The Game"
              // game={"dice"}
              about={props.state.gameAbout}
              image={props.state.gameImage}
            />
          </>
        )}
      </div>

      {renderSwitch(game)}
    </>
  );
};

export default Game;
