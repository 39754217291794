import { useEffect, useState } from "react";
import { getGames } from "../helpers/ServerApi";
import HeadingClasses from "../modules/heading.module.css";
import useHandleProfileTab from "../hooks/useHandleProfile";
import GameCards from "../modules/home/components/GameCards";

const HomePage = () => {
  useHandleProfileTab();
  const [games, setGames] = useState([]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    getGames()
      .then((res: any) => {        
        setGames(res.games);
      })
      .catch((err) => {
        console.log("Unable to get games");
      });
  }, []);

  return (
    <>
      <div className="container" style={{ minHeight: "100vh" }}>
        <div className={"games-section"}>
          <p className={HeadingClasses.game}>Games</p>

          <div className="row">
            {games.map((game: any) => (
              <div key={game.slug} className="col-md-6 gameSm mt-3">
                <GameCards
                  game={game.slug}
                  name={game.name}
                  gameId={game._id}
                  image={game.image}
                  about={game.description}
                ></GameCards>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
