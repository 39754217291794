import { createReducer } from "@reduxjs/toolkit";
import { addBet, addBets, addCurrentBet, addLivefeedBets, clearStorage } from "./actions";
import { IBetResult, ILiveFeedBets } from "../../helpers/interface";

export interface IBetsState {
  bets: {
    [who: string]: {
      [_id: string]: IBetResult;
    };
  };
  currentBet?: IBetResult;

  // liveFeed: ILiveFeedBets[];
}

export const initialState: IBetsState = {
  bets: {},
  // liveFeed: [] 
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addBet, (_b, { payload }) => {

      // console.log("payload:: ", payload);


      _b.bets[payload.user._id] = _b.bets[payload.user._id] || {};
      _b.bets[payload.user._id][payload.betId] =
        _b.bets[payload.user._id][payload.betId] || {};
      _b.bets[payload.user._id][payload.betId] = {
        ..._b.bets[payload.user._id][payload.betId],
        ...payload,
      };

    })
    .addCase(addBets, (_b, { payload }) => {
      for (let index = 0; index < payload.length; index++) {
        const bet = payload[index];

        _b.bets[bet.user._id] = _b.bets[bet.user._id] || {};
        _b.bets[bet.user._id][bet.betId ? bet.betId : bet._id] = _b.bets[bet.user._id][bet.betId ? bet.betId : bet._id] || {};
        _b.bets[bet.user._id][bet.betId ? bet.betId : bet._id] = {
          ..._b.bets[bet.user._id][bet.betId ? bet.betId : bet._id],
          ...bet,
        };
      }
    })
    // .addCase(addLivefeedBets, (_b, { payload }) => {
    //   const feed = _b.liveFeed || [];
    //   feed.push(...payload);
    //   _b.liveFeed = feed;
    // })
    .addCase(addCurrentBet, (_b, { payload }) => {
      _b.currentBet = payload
    })
    .addCase(clearStorage, (_b) => {
      _b.bets = {};
      _b.currentBet = undefined;
      // _b.liveFeed = [];
    })
);
