import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NotFoundPage() {
  const navigation = useNavigate();
  const [time, setTime] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (time === 0) {
      navigation("/");
    }
  }, [time, navigation]);

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center flex-column"
        style={{
          minHeight: "100vh",
        }}
      >
        <h1>404 - Not Found</h1>
        <p>The page you are looking for does not exist.</p>
        <p>Redirecting to the home page in {time} seconds</p>
      </div>
    </>
  );
}

export default NotFoundPage;
