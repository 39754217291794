import ReactModal from "react-modal";
import { IChildrenProp, IModalProp } from "../helpers/interface";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./modal.sass";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: "none",
    borderRadius: "12px",
    background: "transparent",
    zIndex: 3,
    overfloY: "auto",
  },
  overlay: {
    backgroundColor: "#FFFFFF60",
    zIndex: 3,
  },
};

interface IProps {
  title?: React.ReactNode;
  noCloseButton?: boolean;
  noHeader?: boolean;
  className?: string;
}

const Modal = (props: IChildrenProp & IModalProp & IProps) => {
  return (
    <ReactModal
      isOpen={props.open}
      closeTimeoutMS={300}
      onRequestClose={props.handleClose}
      style={customStyles}
    >
      <div className={`custom-modal ${props.className ? props.className : ""}`}>
        {!props.noHeader && (
          <div>
            {props.title && (
              <div className={"custom-modal-title-content"}>{props.title}</div>
            )}
            {!props.noCloseButton && (
              <span
                className={"close-icon"}
                onClick={props.handleClose}
                onKeyDown={(e) => e.preventDefault()}
                role="button"
                tabIndex={0}
              >
                <FontAwesomeIcon icon={["fas", "close"]} />
              </span>
            )}
          </div>
        )}
        <div className={"custom-modal-content"}>{props.children}</div>
      </div>
    </ReactModal>
  );
};

export default Modal;
