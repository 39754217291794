import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import classes from "./SideMenu.module.css";
import { useDispatch } from "react-redux";
import { toggleSideMenu } from "../../../redux/application/actions";
import {
  useGetChatOpen,
  useGetSideMenuOpen,
} from "../../../redux/application/hooks";

import DropDown from "../../../components/GlobalTokenDropDown";
import { useGetLoggedIn } from "../../../redux/user/hooks";
import BootstrapModal from "../../../components/BootstrapModal";
import WalletTitle from "../../../modules/wallet/title";
import Wallet from "../../../modules/wallet";
import ChatIconComponent from "../../../components/ChatButton/ui";
import ProfileDropDown from "../../../components/ProfileDropdown";
import SignInButton from "../../../components/AccountButton/SignInButton";
import SignUpButton from "../../../components/AccountButton/SignUpBotton";
import Menu from "./SMenu";
const SideMenu = () => {
  const isLoggedIn = useGetLoggedIn();
  const isTablet = useMediaQuery({ maxWidth: "992px" });
  const dispatch = useDispatch();
  const sideMenuOpen = useGetSideMenuOpen();
  const [selectedItem, setSelectedItem] = useState(null);
  const [openWallet, setOpenWallet] = useState(false);
  const openSideMenu = useGetSideMenuOpen();
  useEffect(() => {
    if (sideMenuOpen) dispatch(toggleSideMenu({ key: sideMenuOpen }));
  }, []);

  const closeModal = () => {
    setOpenWallet(false);
  };

  return (
    <div>
      <nav
        className={`${classes.navbar} ${openSideMenu ? `${classes.active} ${classes.openSidebar}` : `${classes.closeSidebar}`}`}
      >
        {isTablet === true ? (
          <>
            {openWallet && (
              <BootstrapModal
                open={true}
                title={<WalletTitle />}
                message={<Wallet />}
                buttonTitle={"none"}
                height={"436px"}
                handleClose={() => {
                  closeModal();
                }}
                width="436px"
                className="modalWallet"
              />
            )}
            <div className="d-flex flex-column p-2 w-100">
              <div className="px-3 pb-3 w-100">
                <div className="pb-3">
                  <DropDown />
                </div>
                {isTablet && (
                  <>
                    {!isLoggedIn && (
                      <div className="w-100 pb-3">
                        <SignUpButton className={"signUpSideNav"} />
                      </div>
                    )}
                    <div className="d-flex gap-3 align-items-center">
                      <ChatIconComponent width="24" />
                      {isLoggedIn ? <ProfileDropDown /> : <SignInButton />}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <Menu />
        )}
      </nav>
    </div>
  );
};

export default SideMenu;
