import React, { useState, useEffect } from "react";
import classes from "./BootstrapModal.module.css";
import Button from "../ui/Buttons";
import crossIcon from "../../static/images/cross.svg";
import useGetUserChat from "../../hooks/user/useGetUserChat";

interface ModalProps {
  open: boolean;
  title?: string | JSX.Element;
  message?: string | JSX.Element;
  buttonTitle?: string;
  height?: string;
  handleClose?: () => void;
  width?: string;
  className?: string;
  maxWidth?: string;
  maxHeight?: string;
  buttonClass?: string;
  footerMessage?: string | JSX.Element;
}

const BootstrapModal: React.FC<ModalProps> = ({
  open,
  title,
  message,
  buttonTitle,
  height,
  handleClose,
  width,
  className,
  maxWidth,
  maxHeight,
  buttonClass,
  footerMessage,
}) => {
  const [showModal, setShowModal] = useState(open);
  useGetUserChat();

  // Effect to update showModal state when the open prop changes
  useEffect(() => {
    setShowModal(open);
  }, [open]);

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
    handleClose?.();
  };

  // Add keydown event listener to close modal on escape key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component is unmounted or when `open` prop changes
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  // Event handler for clicking on the backdrop
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return (
    <>
      {showModal && (
        <>
          <div className={`modal-backdrop fade show`}></div>
          <div
            className={`modal fade show ${classes.modalBackdrop}`} // Add 'show' class to display the modal
            id="staticBackdrop"
            data-bs-backdrop="true" // Allow closing modal on backdrop click
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{ display: "block" }}
            onClick={handleBackdropClick} // Add click event handler for the backdrop
          >
            <div
              className={`modal-dialog ${classes.modalDialog}`}
              style={{
                maxWidth: maxWidth ? maxWidth : "auto",
                maxHeight: maxHeight ? maxHeight : "auto",
              }}
            >
              <div
                className={`modal-content ${classes.modalContent} ${className ? classes[className] : ""}`}
                style={{
                  height: height ? height : "",
                  width: width ? width : "auto",
                }}
              >
                <div
                  className="modal-header d-flex justify-content-between align-items-center"
                  style={{
                    borderBottom: "1px solid #47515D",
                    borderWidth: "thin",
                    height: "3rem",
                  }}
                >
                  <h1
                    className="modal-title text-center flex-grow-1"
                    id="staticBackdropLabel"
                    style={{
                      right: "-10px",
                      position: "relative",
                      fontFamily: "Nunito",
                      fontWeight: "700",
                      fontSize: "1rem",
                      textTransform: "uppercase",
                    }}
                  >
                    {title}
                  </h1>
                  <Button
                    className="crossButton"
                    handleClick={() => {
                      closeModal();
                      handleClose?.();
                    }} // Close modal on button click
                    aria-label="Close"
                    label={
                      <>
                        <img src={crossIcon} alt="" width={24} height={24} />
                      </>
                    }
                  />
                </div>

                <div
                  className="modal-body d-flex flex-column align-items-center"
                  style={{ gap: "12px" , maxHeight: "500px", overflow: "auto"}}
                >
                  {/* {title === "Notification" && <img src={dots3} alt="" />} */}
                  {/* <img src={dots3} alt="" /> */}
                  <>{message}</>
                  {buttonTitle !== "none" && (
                    <>
                      <Button
                        label={buttonTitle ? buttonTitle : "ACTIVATE"}
                        className={`${buttonClass ? buttonClass : "activate"}`}
                        handleClick={closeModal} // Invoke closeModal function
                      />
                    </>
                  )}
                </div>

                {/* Optional footer section */}
                {footerMessage && (
                  <div
                    className="modal-footer"
                    style={{
                      borderTop: "1px solid #47515D",
                      borderWidth: "thin",
                    }}
                  >
                    {footerMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BootstrapModal;
