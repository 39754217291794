import IconLoader from "../../../components/IconLoader";
import classes from "../wallet.module.css";

interface IPropsOptionsDeposit {
  key: number;
  value: string;
  setSelectedToken: (data: string) => void;
}

const Options = (Props: IPropsOptionsDeposit) => {
  return (
    <>
      <li className={classes.listItem}>
        <div
          className={classes.optionsDiv}
          onClick={() => Props.setSelectedToken(Props.value)}
          // as per sonarqube report changes below
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          role="button"
          tabIndex={0}
        >
          <IconLoader
            iconName={Props.value}
            iconType={"tokenSymbol"}
            width={20}
            height={20}
          />{" "}
          {Props.value}
        </div>
      </li>
    </>
  );
};

export default Options;
