import classes from "./FooterNav.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { toggleChatBox } from "../../redux/application/actions";
import { useDispatch } from "react-redux";
import homeIcon from "../../static/images/Sidebar/home.svg";
import gamesIcon from "../../static/images/Sidebar/game.svg";
import chatIcon from "../../static/images/Navbar/chatIconMobile.png";
import ProfileDropDown from "../ProfileDropdown";

const FooterNav = () => {
  const Navigate = useNavigate();

  const navigateTo = (path: string) => {
    Navigate(path);
  };

  const dispatch = useDispatch();
  return (
    <>
      <div className={classes.fNav}>
        <div className="d-flex justify-content-around align-items-center">
          <NavLink
            to="/"
            style={({ isActive }) => ({ color: isActive ? "#fff" : "gray" })}
            className={classes.title}
          >
            <div className="d-flex flex-column align-items-center">
              <img
                className={classes.img}
                width={28}
                height={28}
                src={homeIcon}
                alt=""
              />
              <span>Home</span>
            </div>
          </NavLink>
          <NavLink
            to="/games"
            style={({ isActive }) => ({ color: isActive ? "#fff" : "gray" })}
            className={classes.title}
          >
            <div className="d-flex flex-column align-items-center">
              <img
                className={classes.img}
                width={28}
                height={28}
                src={gamesIcon}
                alt=""
              />
              <span>Games</span>
            </div>
          </NavLink>
          <div
            className="d-flex flex-column align-items-center"
            onClick={() => dispatch(toggleChatBox({ key: true }))}
            // as per sonarqube report changes below
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            role="button"
            tabIndex={0}
          >
            <img
              className={classes.img}
              src={chatIcon}
              width={28}
              height={28}
              alt=""
            />
            <span className={classes.title}>Chat</span>
          </div>
          <div className="d-flex flex-column align-items-center">
            <ProfileDropDown />
            <span className={classes.title}>Profile</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterNav;
