import bellIcon from "../../../static/images/bell.svg";
import classes from "./notifi.module.css";
const notifiModalTitle = (
  <>
    <div className={classes.divtitle}>
      <img src={bellIcon} width={24} height={24} alt="" />
      <span>Notifications</span>
    </div>
  </>
);

export default notifiModalTitle;
