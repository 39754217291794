// import ellipse from "../../static/images/Ellipse.svg";
import classes from "./SwitchTable.module.css";
import moment from "moment";
import SwitchTable from "./SwitchTable";
import { useState } from "react";
import BootstrapModal from "../BootstrapModal";
import BetTitle from "../../modules/bet-details/title";
import BetBody from "../../modules/bet-details/Content";
import diceImage from "../../assets/gameone.png";
import {
  avatar,
  // checkAvatar,
  formatToTwoDecimals,
} from "../../helpers/functions";
import { useGetAvatar, useGetUserName } from "../../redux/user/hooks";
import { useGetUserId } from "../../redux/user/hooks";
import { useLocation } from "react-router-dom";
import IconLoader from "../IconLoader";
import Details from "../../modules/bet-details/Details";

export interface IData {
  createdAt: Date | string;
  guess: number;
  input: number;
  inputCurrency: {
    symbol: string;
    image: string;
  };
  multiplier: number;
  prize: number;
  random: number;
  underOver: number;
  won: boolean;
  gameId?: string;
  user: {
    username: string;
    _id: string;
    avatar: string;
  };
}

interface IProps {
  data: IData[];
  labels: string[];
  type?: string;
}

const SwitchRows = (props: IProps) => {
  const location = useLocation();
  const username = useGetUserName();
  const userId = useGetUserId();
  const userAvatar = useGetAvatar();
  let rows: React.ReactNode[] = [];
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [play, setPlay] = useState(false);

  const handlePlay = () => {
    setPlay((prev) => {
      const newPlayState = !prev;

      if (location.pathname.includes("/game") && newPlayState) {
        window.scroll({ top: 100, left: 0, behavior: "smooth" });
        setOpenModal((prevModal) => !prevModal);
      }

      return newPlayState;
    });
  };

  // const avatar = (id: string, avatar: string) => {
  //   if (userId === id) {
  //     if (userAvatar) return checkAvatar(userAvatar);
  //     else return ellipse;
  //   } else {
  //     if (avatar) return checkAvatar(avatar);
  //     else return ellipse;
  //   }
  // };

  const closeModal = () => {
    setOpenModal(false);
  };
  if (props && props.data) {
    rows = props.data.map((row, index) => (
      <tr
        key={index}
        className={`${classes.tableBody}`}
        onClick={() => {
          setModalData(row);
          setOpenModal(true);
        }}
      >
        <td className={classes.td1}>
          <img
            className={classes.profileImage + " " + classes.img1}
            src={
              // userId === row.user._id
              //   ? checkAvatar(userAvatar)
              //   : checkAvatar(row.user.avatar) === null ||
              //       !checkAvatar(row.user.avatar)
              //     ? ellipse
              //     : checkAvatar(row.user.avatar)
              avatar(row.user._id, userId, row.user.avatar, userAvatar)
            }
            alt="avatar"
          />
          {userId === row.user._id ? username : row.user.username}
        </td>
        {props.labels.includes("time") && (
          <td className={classes.td2}>
            {moment(row.createdAt)
              .fromNow()
              .replace("in a few seconds", "a few seconds ago")}
          </td>
        )}
        <td className={classes.td2}>
          <IconLoader
            iconName={row.inputCurrency.symbol}
            iconType={"tokenSymbol"}
            image={row.inputCurrency.image}
            width={20}
            height={20}
          />{" "}
          {String(row.input).length > 6
            ? formatToTwoDecimals(row.input)
            : row.input}
        </td>
        <td className={classes.td2}>
          <IconLoader
            iconName={row.inputCurrency.symbol}
            iconType={"tokenSymbol"}
            image={row.inputCurrency.image}
            width={20}
            height={20}
          />{" "}
          <span>
            {row.prize === 0
              ? "-" +
                (String(row.input).length > 6
                  ? formatToTwoDecimals(row.input)
                  : row.input)
              : String(row.prize + row.input).length > 6
                ? formatToTwoDecimals(row.prize + row.input)
                : row.prize + row.input}
          </span>
        </td>

        {props.labels.includes("multiplier") && (
          <td className={classes.td2}>
            <span style={{ textTransform: "lowercase" }}>x</span>
            {row.multiplier}
          </td>
        )}
        <td className={classes.td3}>
          <img
            className={classes.img2}
            src={diceImage}
            width={24}
            height={24}
            alt=""
          />
        </td>
      </tr>
    ));
  } else {
    rows = [];
  }

  return (
    <>
      {openModal && (
        <BootstrapModal
          open={true}
          title={<BetTitle />}
          message={
            <BetBody
              data={modalData}
              handleClose={() => {
                closeModal();
              }}
              handlePlay={handlePlay}
            />
          }
          buttonTitle={"none"}
          height={"436px"}
          handleClose={() => {
            closeModal();
          }}
          width="436px"
          className="modalBetDetail"
          footerMessage={<Details data={modalData} />}
        />
      )}
      <SwitchTable lables={props.labels} tableData={rows} />
    </>
  );
};

export default SwitchRows;
