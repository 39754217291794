import Deposit from "./deposit";
import Withdraw from "./withdraw";
import History from "./history";
import SwitchTabs from "../../components/SwitchTabs";
import { useState } from "react";
import classes from "./wallet.module.css";

const Wallet = () => {
  const [activeTab, setActiveTab] = useState("deposit");

  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };
  return (
    <>
      <SwitchTabs
        className="walletTabs"
        lables={["deposit", "withdraw", "history"]}
        handleTab={handleTab}
        activeTab={activeTab}
      />
      <div
        className={`d-flex justify-content-center align-items-center flex-column ${classes.wrapBody} ${classes.wrapBody2}`}
      >
        {activeTab === "deposit" && <Deposit />}
        {activeTab === "withdraw" && <Withdraw />}
        {activeTab === "history" && <History />}
      </div>
    </>
  );
};

export default Wallet;
