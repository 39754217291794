import classes from "./AppHeader.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { toggleSideMenu } from "../../../redux/application/actions";
import { useGetLoggedIn } from "../../../redux/user/hooks";

import AccountButton from "../../../components/AccountButton/AccountButton";
import TransferTypeModal from "../../../modules/dice/components/TransferTypeModal";
import GlobalTokenSelector from "../../../components/GlobalTokenSelector";
import ChatIconComponent from "../../../components/ChatButton";
import NotificationIcon from "../../../components/NotificationIcon";
import Button from "../../../components/ui/Buttons";
import OpenWallet from "../../../modules/wallet/openWallet";
import {
  homeIcon,
  gameIcon,
  helpIcon,
  referralIcon,
  supportIcon,
  logo,
  menuIcon,
  xNav,
} from "../../../static/images";

interface MenuItemProps {
  id: string;
  name: string;
  redirect: string;
  show: ("desktop" | "mobile")[];
  icon: string;
}

export const MenuItems: MenuItemProps[] = [
  {
    id: "home",
    name: "Home",
    redirect: "/",
    icon: homeIcon,
    show: ["mobile", "desktop"],
  },
  {
    id: "game",
    name: "Games",
    redirect: "/games",
    icon: gameIcon,
    show: ["desktop", "mobile"],
  },
  {
    id: "help",
    name: "Help",
    redirect: "/help",
    icon: helpIcon,
    show: ["desktop", "mobile"],
  },
  {
    id: "referral",
    name: "Referral",
    redirect: "/referral",
    icon: referralIcon,
    show: ["desktop", "mobile"],
  },
  {
    id: "support",
    name: "Support",
    redirect: "/support",
    icon: supportIcon,
    show: ["desktop"],
  },
];

const AppHeader = () => {
  const isLoggedIn = useGetLoggedIn();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);

  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ maxWidth: "992px" });
  const navigate = useNavigate();
  const [openDeposit, setOpenDeposit] = useState<boolean>(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    dispatch(toggleSideMenu({ key: isSidebarOpen }));
  };

  const closeModal = () => {
    setOpenWallet(false);
  };

  return (
    <>
      <OpenWallet openWallet={openWallet} closeModal={closeModal} />
      <div className={classes.div1}>
        <div className={classes.appHeader}>
          {openDeposit && (
            <TransferTypeModal
              open={openDeposit}
              handleClose={() => setOpenDeposit(false)}
            />
          )}
          <div className="row">
            <div className={`${isTablet ? "col-6" : "col-3"} ${classes.div2}`}>
              <Button
                className={"none"}
                label={
                  <img
                    className={classes.img1}
                    src={isTablet && isSidebarOpen ? xNav : menuIcon}
                    alt=""
                    {...(!isTablet && {
                      "data-bs-toggle": "offcanvas",
                      "data-bs-target": "#offcanvasScrolling",
                      "aria-controls": "offcanvasScrolling",
                    })}
                  />
                }
                handleClick={toggleSidebar}
              />

              <NavLink to={"/"}>
                <img
                  src={logo}
                  alt={"brand-logo"}
                  className={classes.navLogo}
                />
              </NavLink>
            </div>
            {!isTablet && (
              <div className={`col-md-6 ${classes.tokenDiv}`}>
                <GlobalTokenSelector />
              </div>
            )}

            <div
              className={`${isTablet ? "col-6" : "cursor col-3"} ${classes.div3}`}
            >
              {!isTablet && <ChatIconComponent />}
              {!isTablet && isLoggedIn && <NotificationIcon />}
              {!isTablet && <AccountButton />}
              {isTablet && (
                <Button
                  className="wallet"
                  label="wallet"
                  handleClick={() => {
                    isLoggedIn ? setOpenWallet(true) : navigate("/login");
                  }}
                ></Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppHeader;
