import { useEffect, useState } from "react";
import { getReferredAPI } from "../helpers/ServerApi";
interface ReferralData {
    createdAt: string;
    username: string;
    referralUsedStatus: string;
    usdEarned: number;
    usdWagered: number;
}

const useGetReferral = (page: number, limit: number) => {
    const [data, setData] = useState<ReferralData[]>([]);
    useEffect(() => {
        const payload = {
            page: page,
            limit: limit
        }
        getReferredAPI(payload).then((res) => {
            setData(res.data);
        }).catch(err => {
            console.log("err", err);
        })
    }, [page, limit]);
    return data;
}

export default useGetReferral;