// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetLoggedIn } from "../../redux/user/hooks";
import ChatIcon from "./ui";

const ChatIconComponent = () => {
  const isLoggedIn = useGetLoggedIn();

  if (isLoggedIn === true)
    return (
      <>
        <ChatIcon />
      </>
    );
  else return <></>;
};

export default ChatIconComponent;
