import { useState, useEffect, useRef } from "react";
import Button from "../ui/Buttons";
import { useGetLoggedIn } from "../../redux/user/hooks";
import { useDispatch } from "react-redux";
import { toggleChatBox } from "../../redux/application/actions";
import Vector from "../../static/images/Vector.png";
import ProfileDropDown from "../ProfileDropdown";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import NotificationIcon from "../NotificationIcon";
import SignInButton from "./SignInButton";
import SignUpButton from "./SignUpBotton";
import ChatIconComponent from "../ChatButton/ui";
export type IType = "login" | "register";

const AccountButton = () => {
  const Navigate = useNavigate();
  // const [open, setOpen] = useState<boolean>(false);
  const [openChat, setOpenChat] = useState<boolean>(false);
  // const [type, setType] = useState<IType>("login");
  const isLoggedIn = useGetLoggedIn();
  const isMobile = useMediaQuery({ maxWidth: "496px" });
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!isInitialMount.current) {
      dispatch(toggleChatBox({ key: openChat }));
    } else {
      isInitialMount.current = false;
    }
  }, [openChat, dispatch]);

  const navigateTo = (type: IType) => {
    Navigate(`/${type}`, { state: { type } });
  };

  if (!isLoggedIn)
    return (
      <div className={"account-button"}>
        <div className={"not-logged-in d-flex gap-2 align-items-center"}>
          {/* chat */}
          {!isMobile && (
            <>
              <ChatIconComponent width={"32"} />
            </>
          )}
          {!isMobile && <SignInButton />}
          <SignUpButton />
        </div>
      </div>
    );
  else
    return (
      <>
        <div className={"account-button"}>
          <div className={"logged-in"}>
            <ProfileDropDown />
          </div>
        </div>
      </>
    );
};

export default AccountButton;
