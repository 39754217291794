import classes from "./Auth.module.css";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from "../ui/Buttons";
import mailIcon from "../../static/images/auth/mail.svg";
import chevronRight from "../../static/images/auth/chevron-right.svg";
import { toast } from "react-toastify";
import { forgotPassword, verifyOtp } from "../../helpers/ServerApi";
import { useDispatch } from "react-redux";
import Foot from "./foot";

const ForgetPassword = (props: { email: string }) => {
  const location = useLocation();

  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const IsValidEmail = (word: string) => {
    const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const result = pattern.test(word) || word.length === 0;
    return result;
  };

  const handleSubmit = async (username: string) => {
    if (otp.includes("") === true) {
      setLoading(false);
      toast("Enter 6 digit OTP", { theme: "dark" });
      return;
    } else {
      const payload = {
        otp: otp.join(""),
        _id: location.state?._id,
      };

      verifyOtp(payload)
        .then((res: any) => {
          if (res.success === true)
            Navigate("/change-password", {
              state: {
                otpVerified: true,
                otp: otp.join(""),
                _id: location.state?._id,
              },
            });
        })
        .catch((err) => {
          toast(err.response?.data.error, { theme: "dark" });
          setLoading(false);
        });
    }
  };

  const conti = (
    <>
      <div className={`d-flex align-items-center justify-content-center gap-2`}>
        <p className={classes.discordText}>CONTINUE</p>
        {loading && (
          <i className="fa fa-spinner fa-spin" style={{ fontSize: "20px" }}></i>
        )}
        {!loading && <img src={chevronRight} alt="" />}
      </div>
    </>
  );

  const otpRefs = useRef<Array<HTMLInputElement | null>>([]);

  // Initialize the refs
  useEffect(() => {
    otpRefs.current = otpRefs.current
      .slice(0, otp.length)
      .map((_, index) => otpRefs.current[index] || null);
  }, [otp.length]);

  // Function to change focus to the next input field
  const focusNextInput = (currentIndex: number) => {
    if (currentIndex < otpRefs.current.length - 1) {
      const nextIndex = currentIndex + 1;
      otpRefs.current[nextIndex]?.focus();
    }
  };

  // Function to handle changes in input fields
  const handleChange = (value: string, index: number) => {
    saveOtp(value, index);
    if (value.length === 1) {
      focusNextInput(index);
    }
  };

  function saveOtp(e: string, i: number) {
    if (e.length === 1 || e.length === 0) {
      const newOtp = [...otp];
      newOtp[i] = String(e);
      setOtp(newOtp);
    } else return;
  }

  const otpInputs = otp.map((value, index) => (
    <input
      key={index}
      ref={(el) => (otpRefs.current[index] = el)}
      className={classes.otpInput}
      type="number"
      value={value}
      onChange={(e) => handleChange(e.target.value, index)}
    />
  ));

  const resendOtp = (email: string) => {
    fnForgot({ email: email });
  };

  async function fnForgot(payload: object) {
    setLoading(true);
    await forgotPassword(payload)
      .then((res: any) => {
        if (res.user) {
          setLoading(false);
          toast("OTP sent successfully", { theme: "dark" });
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.status >= 400) {
        } else {
          toast(err.response?.data.error, { theme: "dark" });
        }
      });
  }

  const handleContinue = () => {
    handleSubmit(email);
  };

  return (
    <>
      <div className={classes.box + " " + classes.center}>
        <div className={classes.otpDiv1}>
          <p className={classes.title}>Enter the 6 digit OTP</p>
          <p className={classes.subtitle}>The OTP is sent via email</p>
        </div>
        <div className="d-flex justify-content-center flex-column gap-2">
          <div className={classes.inputContainer}>
            <input
              className={
                IsValidEmail(email) === true
                  ? `${classes.input} ${classes.inputEmail} ${classes.inputPadding}`
                  : `${classes.error} ${classes.inputEmail} ${classes.input} ${classes.inputPadding}`
              }
              type="email"
              placeholder={location.state?.email}
              disabled={true}
            />
            <img src={mailIcon} alt="User Icon" className={classes.inputIcon} />
          </div>
          <NavLink to="/forget-password">
            <p className={classes.subtitle + " " + classes.signDiv4}>
              Change mail
            </p>
          </NavLink>
        </div>
        <div className="d-flex justify-content-center flex-column gap-2">
          <div className={classes.inputContainerOtp}>
            <div className={`${classes.otpInputsGap}`}>{otpInputs}</div>
          </div>
          <span
            className={classes.subtitle + " " + classes.signDiv4}
            onClick={() => {
              resendOtp(location.state?.email);
            }}
            // As per sonarqube report added below code
            onKeyDown={() => {
              resendOtp(location.state?.email);
            }}
            role="button"
            tabIndex={0}
          >
            Resend OTP
          </span>
        </div>
        <Button
          label={conti}
          handleClick={handleContinue}
          className={"authContinue"}
        />
        <Foot />
      </div>
    </>
  );
};

export default ForgetPassword;
