import classes from "./Loading.module.css";
const Loading = () => {
  return (
    <>
      <div className={classes.loadiv}>
        <span>Loading. . .</span>
      </div>
    </>
  );
};

export default Loading;
