import classes from "./referral.module.css";
import FilledInputBox from "../ui/filledInputBox";
import { TwitterShareButton } from "react-share";
import Button from "../ui/Buttons";
import RefShare from "./refShare";
import RefCopy from "./refCopy";
import { toast } from "react-toastify";

const RefLink = (Props: { code: string }) => {
  const shareUri = `${process.env.REACT_APP_URI}/referred/${Props.code}`;
  const handleCopy = () => {
    navigator.clipboard.writeText(shareUri);
  };
  return (
    <>
      <div className={classes.refLink}>
        <h1 className={classes.refLinkHeader}>Your referral link</h1>
        <div className={classes.refLinkBox}>
          <FilledInputBox value={shareUri} />
          <div className={classes.refButton}>
            <Button
              label={<RefShare shareUri={shareUri} />}
              className="refShare"
              handleClick={() => {
                // toast("Coming soon", { theme: "dark" });
              }}
            />
            <Button
              label={<RefCopy />}
              className="refCopy"
              handleClick={() => {
                handleCopy();
                toast("copied ", { theme: "dark" });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RefLink;
