import { useEffect, useRef, useState } from "react";

import classes from "./Profile.module.css";
import ProfileHeader from "./Header/header";
import UserCard from "./UserCard";
import SwitchTabs from "../../components/SwitchTabs";
import TableInfinite from "./TableInfinite";
import Connections from "./settings/Connections";
import ChangePassword from "./settings/ChangePassword";
import LoginHistory from "./settings/LoginHistory";
import {
  openRival,
  prevRoute,
  RivalData,
} from "../../redux/application/actions";
import { useDispatch } from "react-redux";
import {
  useGetRivalOpen,
  useGetRivalUsername,
} from "../../redux/application/hooks";
import { useGetProfileTab } from "../../redux/application/hooks";
import { setProfileTab } from "../../redux/application/actions";
import BootstrapOffcanvas from "../../components/BootstrapOffcanvas";
import { useGetLoggedIn } from "../../redux/user/hooks";

const Profile = (props: {
  openProfile: boolean;
  rivalProfile: boolean;
  handleClose: () => void;
}) => {
  const rivalOpen = useGetRivalOpen();
  const isLoggedIn = useGetLoggedIn();
  const { openProfile } = props;
  const [activeUnit, setActiveUnit] = useState("profile");
  const [activeTab, setActiveTab] = useState("all");
  const dispatch = useDispatch();
  const metaData = useGetRivalUsername();
  const profileRef = useRef<HTMLDivElement>(null);

  const profileTabRedux = useGetProfileTab();
  useEffect(() => {
    if (profileTabRedux.length > 0) {
      setActiveUnit(profileTabRedux);
      dispatch(setProfileTab({ tab: "" }));
      dispatch(prevRoute({ route: "" }));
    }
  }, [profileTabRedux]);

  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleClickSettings = () => {
    setActiveUnit("settings");
  };

  const handleClickProfile = () => {
    setActiveUnit("profile");
  };

  const handleCloseUnit = () => {
    if (activeUnit === "profile" && openProfile) {
      props.handleClose();
    }

    if (isLoggedIn) {
      props.handleClose();
      dispatch(openRival({ key: false }));
      dispatch(RivalData({ username: "" }));
      return;
    }
    setActiveUnit("profile");

    if (props.rivalProfile) {
      dispatch(openRival({ key: false }));
      dispatch(RivalData({ username: "" }));
    }
  };

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (
  //     profileRef.current &&
  //     !profileRef.current.contains(event.target as Node)
  //   ) {
  //     handleCloseUnit();
  //   }
  // };

  // useEffect(() => {
  //   if (rivalOpen) return;
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [rivalOpen]);

  const ui = (
    <>
      <div ref={profileRef} className={`${classes.profile} pb-2 flex-column`}>
        <ProfileHeader
          handleClick={handleClickSettings}
          handleClickProfile={handleClickProfile}
          handleClose={handleCloseUnit}
          title={openProfile ? activeUnit : metaData}
        />
        <div
          className={`${classes.profileContainer} py-3 d-flex flex-column ${activeUnit === "profile" ? "gap-3" : "gap-4"}`}
        >
          {activeUnit === "profile" ? (
            <>
              <UserCard
                handleClick={handleClickSettings}
                handleClose={props.handleClose}
                type={openProfile ? "profile" : "rival"}
              />
              {openProfile && (
                <>
                  <div className="px-3">
                    <SwitchTabs
                      lables={["all", "high rollers"]}
                      className={"walletTabs"}
                      handleTab={handleTab}
                      activeTab={activeTab}
                    />
                  </div>

                  <TableInfinite
                    labels={["DATE", "BET", "PAYOUT", "GAMES"]}
                    activeTab={activeTab}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {openProfile && (
                <>
                  <Connections />
                  <ChangePassword />
                  <LoginHistory />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );

  return (
    <>
      <BootstrapOffcanvas
        show={openProfile || props?.rivalProfile}
        placement={"end"}
        body={ui}
      />
    </>
  );
};

export default Profile;
