import classes from "../Profile.module.css";
import InfoCard from "./InfoCard";
import EditSetting from "../EditSetting";
import Card from "../Card";
import ViewAllBets from "../ViewAllBets";
import { useEffect, useState } from "react";
import { getCountBetAll } from "../../../helpers/ServerApi";
import { useGetUserWagered } from "../../../redux/user/hooks";
import {
  useGetRivalWagered,
  useGetRivalWon,
  useGetRivalLoss,
} from "../../../redux/application/hooks";
export interface ICountRes {
  betCount: number;
}

interface IProps {
  handleClick: () => void;
  handleClose: () => void;
  type: string;
}

const UserCard = (Props: IProps) => {
  const [count, setCount] = useState(0);
  const wagered = useGetUserWagered();
  const rivalWagered = useGetRivalWagered();
  const rivalWon = useGetRivalWon();
  const rivalLoss = useGetRivalLoss();

  useEffect(() => {
    getBetsCount();
  }, [Props.type]);

  const getBetsCount = async () => {
    try {
      let data = await getCountBetAll();
      setCount((data as ICountRes).betCount);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className={`px-3 ${classes.profileCard}`}>
        <InfoCard
          type={Props.type}
          handleClick={Props.handleClick}
          handleClose={Props.handleClose}
        />
        {Props.type === "profile" && (
          <>
            <EditSetting
              handleClick={Props.handleClick}
              handleClose={Props.handleClose}
            />
          </>
        )}
        {/* <EditSetting
          handleClick={Props.handleClick}
          handleClose={Props.handleClose}
        /> */}
      </div>
      <div
        className={`${classes.profileCardBox} col-12 d-flex justify-content-around px-3 ${Props.type === "rival" && `flex-wrap`} `}
      >
        <Card
          title={Props.type === "profile" ? "my bets" : "Total Bets"}
          value={
            Props.type === "profile"
              ? String(count)
              : String(rivalLoss + rivalWon)
          }
        />
        {Props.type === "rival" && (
          <Card title="Number of wins" value={String(rivalWon)} />
        )}
        {Props.type === "rival" && (
          <Card title="number of losses" value={String(rivalLoss)} />
        )}

        <Card
          title="wagered"
          value={
            Props.type === "profile" ? String(wagered) : String(rivalWagered)
          }
        />
      </div>
      {Props.type === "profile" && (
        <>
          <ViewAllBets />
        </>
      )}
    </>
  );
};

export default UserCard;
