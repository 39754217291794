import dots3 from "../../../static/images/dots3.svg";
import classes from "./notifi.module.css";
const notifiBody = (
  <>
    <img src={dots3} alt="" />
    <p className={`${classes.divbody} text-center`}>
      Receive notification about bonuses,
      <br /> payments, announcements.
    </p>
  </>
);

export default notifiBody;
