import React, { useState, useEffect } from "react";
import IconLoader from "../../../components/IconLoader";
import {
  useGetUserInput,
  useUpdateUserInputs,
} from "../../../redux/application/hooks";
import chevDownIcon from "../../../static/images/chevron-down.svg";
import classes from "../wallet.module.css";
import Options from "../withdraw/options";
import OptionsDeposit from "../deposit/options";
import { useGetCurrencies } from "../../../redux/application/hooks";
import { useTokenBalance } from "../../../redux/token/hooks";
interface DataItem {
  symbol?: string;
  value?: string;
}

interface IProps {
  activeTab: string;
  data?: DataItem[];
  type?: string;
  onChange?: (value: string) => void;
}

const Drop: React.FC<IProps> = ({ activeTab, data, type, onChange }) => {
  const currencies = useGetCurrencies();

  const [value, setValue] = useState<string>(() => {
    // Set the first value from data as the default for deposit
    if (activeTab === "deposit" && data && data.length > 0) {
      return data[0].symbol || data[0].value || "";
    }
    return "";
  });

  const updateUserInput = useUpdateUserInputs();
  const userInput = useGetUserInput();
  const tokenBalance = useTokenBalance(userInput.token);
  console.log("activeTab", activeTab);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  const handleSelectToken = (newToken: string) => {
    setValue(newToken);
    if (onChange) {
      onChange(newToken);
    }
  };

  return (
    <>
      <div className="dropdown">
        <button
          className={`btn btn-secondary ${classes.inputSelect} ${activeTab === "withdraw" && classes.inputSelectWallet}`}
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className={classes.dropDownDiv1}>
              <IconLoader
                iconName={activeTab === "withdraw" ? userInput.token : value}
                iconType="tokenSymbol"
                width={18}
                height={18}
              />
              {activeTab === "withdraw" ? String(tokenBalance) : value}
              {/* <span> {String(tokenBalance)}</span> */}
            </div>
            <img src={chevDownIcon} width={24} height={24} alt="dropdown" />
          </div>
        </button>
        <ul className={`dropdown-menu ${classes.dropdownMenu}`}>
          {activeTab === "withdraw" &&
            // @ts-ignore
            currencies.map((el: IData, index) => (
              <Options
                key={index}
                index={index}
                selectedToken={userInput.token}
                passingToken={el.symbol}
                setSelectedToken={(token) =>
                  updateUserInput(
                    undefined,
                    undefined,
                    token,
                    undefined,
                    undefined,
                    el.image,
                  )
                }
              />
            ))}
          {activeTab === "deposit" &&
            type === "currency" &&
            data?.map((item: any, index: number) => (
              <OptionsDeposit
                key={index}
                value={item.symbol}
                setSelectedToken={() => handleSelectToken(item.symbol)}
              />
            ))}
          {activeTab === "deposit" &&
            type === "network" &&
            data?.map((item: any, index: number) => (
              <OptionsDeposit
                key={index}
                value={item.value}
                setSelectedToken={() => handleSelectToken(item.value)}
              />
            ))}
        </ul>
      </div>
    </>
  );
};

export default Drop;
