import { Offcanvas, OffcanvasProps } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

const BootstrapOffcanvas = (Props: {
  body: JSX.Element;
  show: boolean;
  placement: OffcanvasProps["placement"];
}) => {
  return (
    <>
      <Offcanvas
        show={Props.show}
        placement={Props.placement}
        name={Props.placement}
        backdrop={false}
      >
        <Offcanvas.Body>{Props.body}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default BootstrapOffcanvas;
