import classes from "./EditProfile.module.css";
import authClasses from "../../../components/Auth/Auth.module.css";
import userClass from "../../../modules/bet-details/betDetails.module.css";
import {
  pencil,
  editProfileUsernameIcon,
  editProfileEmailIcon,
  editProfileCheck,
} from "../../../static/images";
import {
  useGetUserName,
  useGetUserAvatar,
  useGetUserEmail,
} from "../../../redux/user/hooks";
import { updateUserName, updateUserAvatar } from "../../../redux/user/actions";
import { useState } from "react";
import { updateUser, updateAvatar } from "../../../helpers/ServerApi";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { checkAvatar } from "../../../helpers/functions";
import BootstrapModal from "../../../components/BootstrapModal";
import ChangeAvatar from "../changeAvatar/changeAvatar";
import Button from "../../../components/ui/Buttons";

const EditProfileContent = () => {
  const dispatch = useDispatch();
  const userName = useGetUserName();
  const userAvatar = useGetUserAvatar();
  const userEmail = useGetUserEmail();
  const [newUserName, setNewUserName] = useState(userName);
  const [editName, setEditName] = useState(false);
  const [editAvatar, setEditAvatar] = useState(false);
  const [error, setError] = useState("");

  const handleEditName = async () => {
    if (editName === false) {
      setEditName(!editName);
    } else {
      if (newUserName.length === 0) {
        setError("Username required");
        return;
      }
      if (newUserName.length <= 3) {
        setError("Username should be atleast 3 characters");
        return;
      } else {
        let body = {
          username: newUserName,
        };
        try {
          let res = await updateUser(body);
          dispatch(updateUserName({ username: res.user.username }));
          toast("Username Updated", { theme: "dark" });
          setEditName(!editName);
        } catch (error: any) {
          if (error.response.data.success === false) {
            setError("Username already exists");
          }
        }
      }
    }
  };

  const handleNewName = (e: string) => {
    if (e.length > 10) {
      return;
    } else {
      setError("");
      let name = e.substring(0, 15);
      const trimmedUsername = name.replace(/\s/g, ""); // Remove spaces
      const special = trimmedUsername.replace(/[^a-z0-9]/gi, "");
      setNewUserName(special);
    }
  };

  const handleChangeAvatar = async (avatar: string) => {
    const filename = avatar.substring(
      avatar.lastIndexOf("/") + 1,
      avatar.lastIndexOf("."),
    );
    const img = filename.split(".")[0];

    let body = {
      avatar: String(img),
      username: newUserName,
    };
    try {
      let res = await updateAvatar(body);
      if (res.user.avatar)
        dispatch(updateUserAvatar({ avatar: res.user.avatar }));
      toast("Avatar Updated", { theme: "dark" });
      setEditAvatar(!editAvatar);
    } catch (error: any) {
      if (error.response.data.success === false) {
        setError("Unable to update avatar, try later");
      }
    }
  };

  // const checkAvatar = (name: string) => {
  //   // Assuming avatars is an array of avatar URLs
  //   for (const avatar of avatars) {
  //     if (avatar.includes(name)) {
  //       return avatar;
  //     }
  //   }
  //   // Return a default avatar if the user's avatar is not found
  //   return noAvatar;
  // };

  // Assuming userAvatar is the name of the user's avatar
  const avatarUrl = checkAvatar(userAvatar); // Get the avatar URL
  const imgSrc = typeof avatarUrl === "string" ? avatarUrl : ""; // Ensure it's a string

  return (
    <>
      {editAvatar === true && (
        <BootstrapModal
          open={editAvatar}
          title={"Change Avatar"}
          message={
            <ChangeAvatar
              handleClose={() => setEditAvatar(!editAvatar)}
              handleChangeAvatar={handleChangeAvatar}
            />
          }
          handleClose={() => setEditAvatar(!editAvatar)}
          buttonTitle="none"
          className={"changePassword"}
        />
      )}
      <div className={`${classes.profileImg} d-flex flex-column`}>
        <div className={classes.profileImgDiv}>
          <img className={classes.profileImgDiv} src={imgSrc} alt="avatar" />
        </div>
        {editAvatar === false && (
          <Button
            className={"none"}
            label={
              <div
                className={`${classes.pencilDivImg} ${classes.pencilCircle}`}
              >
                {pencil(16)}
              </div>
            }
            handleClick={() => setEditAvatar(!editAvatar)}
          />
        )}
        <p className={classes.username}>{userName}</p>
        <div className={`${userClass.tag} ${classes.advDiv}`}>
          <p className={userClass.tagtxt}>Adventurer</p>
        </div>
      </div>

      <div className={classes.usernameContainer}>
        <p className={classes.usernameLabel}>username</p>
        <div className={`${authClasses.inputContainer} ${classes.usernameDiv}`}>
          <span className={authClasses.inputIcon}>
            {editProfileUsernameIcon(20)}
          </span>
          <input
            className={`${authClasses.input} ${authClasses.inputEmail} ${authClasses.inputPadding} ${classes.usernameInput} 
          ${editName ? classes.editName : ""}`}
            type="text"
            placeholder={"Enter new username"}
            value={newUserName}
            disabled={editName ? false : true}
            onChange={(e) => handleNewName(e.target.value)}
          />
          <span
            className={`${classes.usernameDivImg}`}
            onClick={() => handleEditName()}
            // as per sonarqube report changes below
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            role="button"
            tabIndex={0}
          >
            {editName === false ? pencil(20) : editProfileCheck}
          </span>
        </div>
        {error && (
          <span className={`${authClasses.authSwitch} ${classes.errorSpan}`}>
            {error}
          </span>
        )}
      </div>

      <div className={classes.usernameContainer}>
        <p className={classes.usernameLabel}>email</p>
        <div className={`${authClasses.inputContainer} ${classes.usernameDiv}`}>
          <span className={authClasses.inputIcon}>{editProfileEmailIcon}</span>
          <input
            className={`${authClasses.input} ${authClasses.inputEmail} ${authClasses.inputPadding} ${classes.usernameInput}`}
            type="email"
            placeholder={userEmail}
            value={userEmail}
            disabled
          />
        </div>
      </div>
    </>
  );
};

export default EditProfileContent;
