import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getGameBets, getUserBets } from "../../../helpers/ServerApi";
import { addBets } from "../../../redux/bets/actions";

const useUpdateGameBets = (props: { gameId: string }) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    getGameBets(props.gameId, 1, 10)
      .then((res: any) => {
        // console.log("getGameBets", res.data);
        // dispatch(addBets(res.data));
      })
      .catch((e) => console.log("error getUserBets", e));
  }, [dispatch]);
};

export default useUpdateGameBets;
