import { useEffect, useState } from "react";
import { chatted } from "../../helpers/ServerApi";
import { setChatted } from "../../redux/application/actions";
import { useDispatch } from "react-redux";

interface IRes {
    data: boolean;
}

const useGetUserChat = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState<boolean>(false);

    useEffect(() => {
        async function getData() {
            try {
                // Explicitly assert the response type as IRes
                const response = await chatted() as IRes;

                if (response.data) {
                    setData(response.data);
                    dispatch(setChatted({ key: response.data }));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }

        getData();
    }, []);

    return data; // Make sure to return the data if you want to use it outside the hook
};

export default useGetUserChat;
