import controlImage1 from "../../static/icons/gameControl/1.svg";
import controlImage2 from "../../static/icons/gameControl/2.svg";
import controlImage3 from "../../static/icons/gameControl/3.svg";
import classes from "./gameControl.module.css";

const GameControl = () => {
  return (
    <>
      <div className={classes.container}>
        <img src={controlImage1} alt="" />
        <img src={controlImage2} alt="" />
        <img src={controlImage3} alt="" />
      </div>
    </>
  );
};

export default GameControl;
