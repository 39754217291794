import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useGetJWT, useGetUserId, useSetJWT } from "../redux/user/hooks";
import { useLocation } from "react-router-dom";
import { useSetReferredBy } from "../redux/application/hooks";
import useUpdateAssetState from "../hooks/update/useUpdateAssetState";
import useUpdateBalances from "../hooks/update/useUpdateBalances";
import useUpdateGameBets from "../hooks/games/callbacks/useUpdateGameBets";
import useGetCurrencies from "../hooks/useGetCurrencies";
import useUpdateUser from "../hooks/update/useUpdateUser";
// import useGetGames from "../hooks/games/useGetGames";

const ServerProvider = ({ children }: React.HTMLProps<HTMLBaseElement>) => {
  const jwt = useGetJWT();

  const dispatch = useDispatch();
  const userId = useGetUserId();
  const updateBalances = useUpdateBalances();
  const currencies = useGetCurrencies();
  // const updateBets = useUpdateGameBets();
  const updateAssetState = useUpdateAssetState();
  const updateUser = useUpdateUser();
  const setJWT = useSetJWT();
  // const getGames = useGetGames();

  // track referral code
  const urlString = useLocation();
  const setReferredBy = useSetReferredBy();
  useEffect(() => {
    const paramString = urlString.search;
    const queryString = new URLSearchParams(paramString);
    const query = queryString.get("ref");
    if (query && query.length > 0) setReferredBy(query);
  }, [setReferredBy, urlString]);

  // update JWT
  useEffect(() => {
    const paramString = urlString.search;
    const queryString = new URLSearchParams(paramString);
    const query = queryString.get("jwt");
    if (query && query.length > 0) setJWT(query);
  }, [setJWT, urlString]);

  // user update user balances and bets
  useEffect(() => {
    if (userId && userId.length > 0) {
      updateBalances();
      // updateBets();
    }
  }, [
    userId,
    dispatch,
    // updateBets,
    updateBalances,
    currencies,
  ]);

  // get games
  // useEffect(() => {
  //   getGames();
  // }, [getGames]);

  // update user
  useEffect(() => {
    if (jwt) updateUser();
  }, [jwt, updateUser]);

  // download asset prices
  useEffect(() => {
    updateAssetState();
  }, [updateAssetState, updateBalances, userId]);

  return <>{children}</>;
};

export default ServerProvider;
