import { useCallback } from "react";
import useCore from "../../useCore";
import { useAddPopup } from "../../../redux/application/hooks";
import useUpdateBalances from "../../update/useUpdateBalances";

export default function usePlaceBetsPemitted(
  input: number,
  guess: number,
  inputCurrency: string,
  under: boolean,
  over: boolean,
) {
  const core = useCore();
  const updateBalances = useUpdateBalances();

  const addPopup = useAddPopup();

  return useCallback(
    async (
      onInitiating: () => void,
      onSuccess: () => void,
      onFailure: (e: string) => void,
    ): Promise<void> => {
      try {
        onInitiating();

        await core.queue.processTransaction({
          seedHash: "",
          clientHash: "",
          input,
          guess,
          inputCurrency,
          under,
          over,
        });

        updateBalances();
        onSuccess();
      } catch (e: any) {
        // console.log("e usePlaceBetsPemitted", e);

        addPopup(
          {
            error: {
              message: e?.data?.message || e?.message,
              stack: "",
            },
          },
          Array.from(crypto.getRandomValues(new Uint8Array(32)))
            .map((b) => b.toString(16).padStart(2, "0"))
            .join(""),
        );

        onFailure(e?.data?.message || e?.message);
        // console.log(`error in placing the bet`, e);
      }
    },
    [core.queue, input, guess, inputCurrency, updateBalances, addPopup, under, over],
  );
}
