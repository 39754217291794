import classes from "./aboutTheGame.module.css";
import gameone from "../../assets/gameone.png";
import gametwo from "../../assets/gametwo.png";
// import sampleImage from "../../static/images/aboutGameSample.png";
interface IProps {
  heading: string;
  // game: string;
  about: string;
  image: string;
}

const AboutTheGame = (Props: IProps) => {

  return (
    <>
      <div className="w-100">
        <div className={`d-flex ${classes.aboutTheGame}`}>
          <div className={classes.imageDiv}>
            <img
              className={classes.image1}
              src={
                process.env.REACT_APP_URL +
                "/assests/images/games/" +
                Props.image
              }
              width={262}
              height={262}
              alt=""
            />
          </div>
          <div className={classes.div1}>
            <h1 className={`${classes.aboutHeading} p-0 m-0`}>
              {Props.heading}
            </h1>
            <div className={classes.content}>
              <p className="p-0 m-0">{Props.about}</p>
              {/* <p className="p-0 m-0">
                Adipiscing at augue massa gravida. Enim scelerisque habitant
                nibh commodo. Pellentesque nunc accumsan aliquam nam massa vel.
                Nec adipiscing fringilla eros tristique lorem tristique quis
                gravida. Faucibus at tincidunt tristique aliquam ipsum.
              </p> */}
              {/* <p className="p-0 m-0">
                Adipiscing at augue massa gravida. Enim scelerisque habitant
                nibh commodo. Pellentesque nunc accumsan aliquam nam massa vel.
                Nec adipiscing fringilla eros tristique lorem tristique quis
                gravida. Faucibus at tincidunt tristique aliquam ipsum.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutTheGame;
