import { configureStore } from "@reduxjs/toolkit";
import { load, save } from "redux-localstorage-simple";
import { createLogger } from "redux-logger";

import application from "./application/reducer";
import token, { TokenState } from "./token/reducer";
import user, { UserState } from "./user/reducer";
import bets, { IBetsState } from "./bets/reducer";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { ApplicationState } from "../helpers/interface";

const PERSISTED_KEYS: string[] = ["token", "application", "user"];

export type AppStore = ToolkitStore<{
  application: ApplicationState;
  token: TokenState;
  user: UserState;
  bets: IBetsState;
}>;

const STORAGE_VERSION_KEY = 'storageVersion';
const CURRENT_STORAGE_VERSION = 'v11'; // Update this whenever you redeploy the build

const storedVersion = localStorage.getItem(STORAGE_VERSION_KEY);
if (storedVersion !== CURRENT_STORAGE_VERSION) {
  localStorage.clear(); // Clear the storage if version has changed
  localStorage.setItem(STORAGE_VERSION_KEY, CURRENT_STORAGE_VERSION); // Update the stored version
}

const store: AppStore = configureStore({
  reducer: {
    application,
    token,
    user,
    bets,
  },
  middleware: (getDefaultMiddleware: any) => {
    const res = getDefaultMiddleware({
      serializableCheck: false,
    }).concat(save({ states: PERSISTED_KEYS }));

    return false ? res : res.concat(createLogger({ collapsed: true }));
  },

  preloadedState: load({ states: PERSISTED_KEYS }),
});


export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
