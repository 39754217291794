import ReferralModule from "../modules/referral";

const ReferralPage = () => {
  return (
    <>
      <ReferralModule />
    </>
  );
};

export default ReferralPage;
