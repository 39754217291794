import classes from "./Auth.module.css";
import { NavLink } from "react-router-dom";
import { Authlogo, AuthInfo } from "../../static/images";
import Download from "../Download";

const AuthHeader = () => {
  return (
    <>
      <div className={classes.div1}>
        <NavLink to="/" className={classes.authHeaderLink} title="Back to Home">
          <img
            className={classes.authHeaderMooncake}
            src={Authlogo}
            alt="logo"
          />
        </NavLink>
        <div className={classes.contactDiv}>
          <img className={classes.infoIcon} src={AuthInfo} alt="" />
          <p className={classes.support}>
            {/* Contact Support */}
            <Download string={"Contact Support"} type={"contactSupport"} />
          </p>
        </div>
      </div>
    </>
  );
};

export default AuthHeader;
