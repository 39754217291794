import { useEffect, useState } from "react";
import { getCurrency } from "../helpers/ServerApi";
import { ICurrency } from "../helpers/interface";
import { useDispatch } from "react-redux";
import { setCurrencies } from "../redux/application/actions";
import { useGetLoggedIn } from "../redux/user/hooks";

interface Currency {
    symbol: string;
    image: string;
}

const useGetCurrencies = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useGetLoggedIn();
    const [data, setData] = useState<{ image: string, symbol: string }[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (isLoggedIn === false) return;
                const res: ICurrency = await getCurrency();

                // Access the data property which contains the array of currencies
                const currencySymbols = res.data.map((el: Currency) => el);
                setData(currencySymbols);
            } catch (err) {
                console.log("err", err);
            }
        };

        fetchData();
    }, [isLoggedIn]);

    // return data; // Return the state if you want to use this hook's data
    if (isLoggedIn)
        dispatch(setCurrencies({ currencies: data }));
    return data;
};


export default useGetCurrencies;
