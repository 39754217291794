import classes from "./CardBorder.module.css";

const CardBorderAnimation = (Props: { body: JSX.Element }) => {
  return (
    <>
      <div className={`${classes["card"]}`}>
        <div className={`${classes["mini-card"]}`}>{Props.body}</div>
        <div className={`${classes["shimmer"]}`}></div>
        <div className={`${classes["shimmer"]} ${classes["left"]}`}></div>
      </div>
    </>
  );
};

export default CardBorderAnimation;
