import { ICollateralType } from "../../config/games";
import { useTokenBalance } from "../../redux/token/hooks";
import IconLoader from "../IconLoader";
// import { useGetAssetApiData } from "../../redux/application/hooks";
import classes from "./TokenSelectorModal.module.css";

interface IProps {
  index: number;
  selectedToken: string;
  passingToken: string;
  setSelectedToken: (data: string) => void;
  image: string;
}

const TokenRow = (props: IProps) => {
  const tokenBalance = useTokenBalance(props.passingToken);

  return (
    <div
      key={props.index}
      className={`${classes.optionItem} ${
        props.selectedToken === props.passingToken ? `${classes.active}` : ""
      }`}
      onClick={() => props.setSelectedToken(props.passingToken)}
      // as per sonarqube report changes below
      onKeyDown={(e) => e.preventDefault()}
      role="button"
      tabIndex={0}
    >
      <div className={`${classes.tokenInfo} d-flex`}>
        <IconLoader
          iconName={props.passingToken}
          iconType={"tokenSymbol"}
          image={props.image}
          width={20}
          height={20}
        />
        <p>{String(tokenBalance)}</p>&nbsp;
      </div>
      <div className={`d-flex`}>
        <p className={classes.tokenBox}>{props.passingToken}</p>
      </div>
    </div>
  );
};

export default TokenRow;
