import userIcon from "../../static/images/user.png";
const BetTitle = () => {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ gap: "6px" }}
      >
        <img src={userIcon} width={24} height={24} alt="" />
        <span>BET DETAILS</span>
      </div>
    </>
  );
};

export default BetTitle;
