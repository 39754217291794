import "./transferTypeModal.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IModalProp } from "../../../helpers/interface";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/Modal";

const TransferTypeModal = (props: IModalProp) => {
  const navigate = useNavigate();

  const goto = useCallback(
    (to: string) => {
      props.handleClose();
      navigate(`/account/${to}`);
    },
    [navigate, props],
  );

  return (
    <Modal open={props.open} handleClose={props.handleClose} noHeader>
      <div className="transfer-modal">
        <div
          className="transfer-deposit cursor"
          onClick={() => goto("deposit")}
          // as per sonarqube report changes below
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon icon={"money-bill-transfer"} size="5x" />
          <span className="title">Deposit</span>
          <span>
            You can deposit assets into the protocol using ETH, Stablecoins or
            Credit-Cards
          </span>
          <span className="est">{"(Est: 0-5 mins)"}</span>
        </div>
        <div
          className="transfer-withdraw cursor"
          onClick={() => goto("withdraw")}
          // as per sonarqube report changes below
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon icon={"sack-dollar"} size="5x" />
          <span className="title">Withdraw</span>
          <span>You can withdraw your balance directly into your wallet</span>
          <span className="est">{"(Est: 5-10 mins)"}</span>
        </div>
      </div>
    </Modal>
  );
};

export default TransferTypeModal;
