import Modal from "../Modal";
import { IModalProp } from "../../helpers/interface";
import { useGetCurrencies } from "../../redux/application/hooks";
import classes from "./TokenSelectorModal.module.css";
import TokenRow from "./TokenRow";

interface IProps {
  selectedToken: string;
  setSelectedToken: (data: string) => void;
}

interface IData {
  symbol: string;
  image: string;
}

const TokenSelectorModal = (props: IModalProp & IProps) => {
  const currencies = useGetCurrencies();
  return (
    <Modal
      open={props.open}
      handleClose={props.handleClose}
      className={classes.tokenSelectorModal}
      title={"Select Token"}
    >
      <div className={classes.optionItems}>
        {
          // @ts-ignore
          currencies.map((data: IData, index) => {
            return (
              <TokenRow
                index={index}
                selectedToken={props.selectedToken}
                passingToken={data.symbol}
                setSelectedToken={props.setSelectedToken}
                image={data.image}
              />
            );
          })
        }
      </div>
    </Modal>
  );
};

export default TokenSelectorModal;
