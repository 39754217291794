import Button from "../ui/Buttons";
import { useNavigate } from "react-router-dom";

const SignInButton = () => {
  const navigateTo = useNavigate();
  return (
    <>
      <Button
        className="signIn"
        handleClick={() => {
          navigateTo("login");
        }}
        label="Sign In"
      ></Button>
    </>
  );
};

export default SignInButton;
