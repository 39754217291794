import { useEffect, useMemo, useRef, useState } from "react";
import { useTokenBalance } from "../../redux/token/hooks";
import usePlaceBetsPemitted from "../../hooks/games/callbacks/usePlaceBetsPemitted";
import { escapeRegExp, formatToTwoDecimals } from "../../helpers/functions";
import classes from "./dice.module.css";
import {
  useGetCurrentGameId,
  useGetUserInput,
  useUpdateUserInputs,
} from "../../redux/application/hooks";
import TransferTypeModal from "./components/TransferTypeModal";
import PlayButton from "./components/PlayButton";
import TokenSelectorModal from "../../components/TokenSelectorModal";
import { core } from "../../config";
import { useCurrentBet } from "../../redux/bets/hooks";
import { useDispatch } from "react-redux";
import coin from "../../static/icons/tokenSymbol/FUN.svg";

import diceSound from "../../static/sound/dice-sound.mp3";
import "./style.sass";

import Button from "../../components/ui/Buttons";
import ProgressBar from "react-bootstrap/ProgressBar";
import dicelogo from "../../assets/dicelogo.png";

import ConfettiComponent from "../../components/Confetti";
import BetsHistory from "./components/BetsHistory";

import left from "../../static/images/Game/left.svg";
import right from "../../static/images/Game/right.svg";
import BootstrapModal from "../../components/BootstrapModal";
import BetBody from "../bet-details/Content";
import BetTitle from "../bet-details/title";

import GameControl from "../../components/gameControlGroup";

import { useGetGamePlayError } from "../../redux/application/hooks";
import { setGamePlayError } from "../../redux/application/actions";
import useUpdateBalances from "../../hooks/update/useUpdateBalances";
import OpenWallet from "../wallet/openWallet";
import IconLoader from "../../components/IconLoader";
import Details from "../bet-details/Details";

const PlayingArea = () => {
  const updateBalances = useUpdateBalances();
  const dispatch = useDispatch();
  const userInput = useGetUserInput();
  const gamePlayError = useGetGamePlayError();
  const bet = useCurrentBet();
  const [updatedBet, setUpdatedBet] = useState<number>(0)
  const [input, setInput] = useState<string>("1");
  const [guess, setGuess] = useState<number>(50);
  const [underGuess, setUnderGuess] = useState<number>(50);
  const [tokenSelectorModal, setTokenSelectorModal] = useState<boolean>(false);
  const [selectedToken, setSelectedToken] = useState<string>(userInput.token);
  const [depositModal, setDepositModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [under, setUnder] = useState<boolean>(true);
  const [over, setOver] = useState<boolean>(false);
  const updateUserInput = useUpdateUserInputs();
  const [openModal, setOpenModal] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);

  const tokenBalance = useTokenBalance(selectedToken);
  const winChance = useMemo(() => 100 - guess, [guess]);
  const multiplier = useMemo(
    () => String(100 / (100 - parseFloat(String(guess)))).slice(0, 4),
    [guess],
  );

  // useEffect(() => {

  // }, [])

  const payout = useMemo(
    () => parseFloat(input) * parseFloat(multiplier),
    [input, multiplier],
  );

  const diceRef = useRef<HTMLDivElement>(null);

  const playWithPermit = usePlaceBetsPemitted(
    Number(input),
    under ? underGuess : guess,
    selectedToken,
    under,
    over,
  );

  const enforcer = (nextUserInput: string) => {
    const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      setInput(nextUserInput);
    }
  };

  useEffect(() => {
    if (bet !== undefined) {
      const newCurrentBet = Math.trunc(bet.random);
      setUpdatedBet(newCurrentBet);

      const timeoutId = setTimeout(() => {
        setUpdatedBet(0);
      }, 2000);

      // Clean up the timeout when the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [bet]);

  const handlePlay = () => {
    playWithPermit(
      () => setLoading(true),
      () => setLoading(true),
      () => setLoading(true),
    );
  };

  useEffect(() => {
    core.queue.initHash();
  }, []);

  useEffect(() => {
    if (gamePlayError === true) setLoading(false);
    dispatch(setGamePlayError({ key: false }));
  }, [gamePlayError]);

  useEffect(() => {
    updateBalances();
    setLoading(false);
  }, [bet, updateBalances]);

  useEffect(() => {
    const userGuess = under ? underGuess : guess;
    updateUserInput(userGuess, input, selectedToken, under, over);
  }, [input, guess, updateUserInput, selectedToken, under, over, underGuess]);

  useEffect(() => {
    if (userInput.token !== selectedToken) setSelectedToken(userInput.token);
  }, [userInput.token]);

  const playSound = (url: string) => {
    setLoading(true);
    const audio = new Audio(url);
    audio
      .play()
      .then(() => {
        console.log("Playback started successfully.");
      })
      .catch((error) => {
        console.error("Error playing audio:", error);
      });
    audio.addEventListener("ended", () => {
      handlePlay();
    });
  };

  const guessButton = (type: string) => {
    if (type === "dec" && guess > 5) {
      setGuess((prev) => prev - 1);
      // let under = 100 - guess;
      setUnderGuess(underGuess + 1);
    } else if (type === "inc") {
      if (guess < 98) {
        setGuess((prev) => prev + 1);
      }

      let under = 100 - (guess + 1); // Updated guess value here
      guess !== 98 && setUnderGuess(under);
    }
  };

  const handleBetAmmount = (type: String) => {
    const value = Number(input);
    if (type === "dec" && value > 1) {
      setInput(String(value - 1));
    } else if (type === "inc") {
      setInput(String(value + 1));
    }
  };

  const handleUnderOver = (type: String) => {
    if (type === "under") {
      setUnder(true);
      setOver(false);
    } else if (type === "over") {
      setOver(true);
      setUnder(false);
    }
  };

  const closeModal = () => {
    setOpenModal(false);
    setOpenWallet(false);
  };

  const [modalData, setModalData] = useState<any>(null);
  const setModalDataHandler = (data: any) => {
    setModalData(data);
  };

  const GameUI = (
    <>
      <div ref={diceRef} className={classes.dice}>
        <BetsHistory
          gameId={useGetCurrentGameId()}
          handleClick={setOpenModal}
          setModalDataHandler={setModalData}
        />

        {/* Group 2 */}
        <div className={classes.group2}>
          <div className={`${classes.group2} mb-3`}>
            <div
              className={`${classes.headingRoll} ${bet?.won === false && " diceLoss "}${
                bet?.won === true && " diceWon "
              }`}
            >
              <p>{updatedBet === 0 ? "000" : updatedBet}</p>
            </div>

            {/* <div className={classes.progressContainer}>
              <div className={classes["ProgressBarmeter"]} style={{position:"relative"}}>
              <span className={classes.progressBarNum}>0</span>
              <span className={classes.progressBarNum} style={{position: "absolute", left: `${under ? 100-guess : guess}%` }}>{under ? winChance: 100-winChance}</span>
              <span className={classes.progressBarNum}>99</span>
              </div>
                <ProgressBar
                  now={under ? underGuess : guess}
                  className={`${under ? classes.progressUnder : classes.progressOver}`}
                />
            </div> */}
            <div className={classes.progressContainer}>
            <ProgressBar
                  now={under ? underGuess : guess}
                  style={{marginTop: "10px"}}
                  className={`${under ? classes.progressUnder : classes.progressOver}`}
                />
              <div className={classes["ProgressBarmeter"]} style={{ position: "relative" }}>
                <span className={classes.progressBarNum}>0</span>
                <span
                  className={classes.progressBarNum}
                  style={{ position: "absolute", left: `${under ? 100 - guess : guess}%`, top: "-30px" }}
                >
                  {under ? winChance : 100 - winChance}
                </span>
                <span className={classes.progressBarNum}>99</span>
              </div>
            </div>

          </div>

          <div
            className={`col-12 d-flex justify-content-between ${classes["card-text"]}`}
          >
            <p className={`${classes.chanceTxt} ${classes.chancep}`}>Chance</p>
            <p className={`${classes.chanceWin} ${classes.chancep}`}>
              {String(winChance).slice(0, 4)}%
            </p>
          </div>

          <div className={classes.group4}>
            <div className={classes.inputSliderDiv}>
              <Button
                className="inputSliderBox"
                handleClick={() => guessButton("dec")}
                label={
                  <>
                    <img src={left} alt="" />
                  </>
                }
              />
              <input
                className="input-slider"
                type="range"
                step="1"
                value={guess}
                min="5"
                max="99"
                onInput={(event) => {
                  const newGuess = Math.max(
                    2,
                    Math.min(
                      Number((event.target as HTMLInputElement).value),
                      98,
                    ),
                  );
                  setGuess(newGuess);
                  setUnderGuess(Math.max(1, Math.min(100 - newGuess, 99)));
                }}
              />

              <Button
                className="inputSliderBox"
                handleClick={() => {
                  guessButton("inc");
                }}
                label={
                  <>
                    <img src={right} alt="" />
                  </>
                }
              />
            </div>
            <div className={classes.border1}></div>
            <div className={classes.div1}>
              <div className={classes.div2}>
                <p className={`${classes.targetPayout} ${classes.chancep}`}>
                  Target
                </p>
                <p
                  className={`${classes.targetPayoutValue} ${classes.chancep}`}
                >
                  X{String(multiplier).slice(0, 4)}
                </p>
              </div>
              <div className={`col-1 ${classes.div3}`}></div>
              <div className={classes.div2}>
                <p className={`${classes.targetPayout} ${classes.chancep}`}>
                  Payout
                </p>
                <p className={classes.payoutP}>
                  <IconLoader
                    iconName={userInput.token}
                    iconType={"tokenSymbol"}
                    image={userInput.image}
                    width={16}
                    height={16}
                  />
                  {/* <img src={coin} alt="" width={16} height={16} /> */}
                  <span className={classes.targetPayoutValue}>
                    {formatToTwoDecimals(payout)}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="col-6">
              <Button
                className={`${
                  under === true
                    ? "headingGradient-btnblue"
                    : "headingGradient-btnnone"
                }`}
                handleClick={() => {
                  handleUnderOver("under");
                }}
                label={
                  <>
                    <p
                      className={`text-center ${classes.guessTxt} ${classes.label1}`}
                    >
                      UNDER {underGuess}
                    </p>
                    <p className={`text-center ${classes.guessMux}`}>
                      x{String(multiplier).slice(0, 4)}
                    </p>
                  </>
                }
              />
            </div>
            <div className="col-6">
              <Button
                className={`${
                  over === true
                    ? "headingGradient-btnblue"
                    : "headingGradient-btnnone"
                }`}
                handleClick={() => {
                  handleUnderOver("over");
                }}
                label={
                  <>
                    <p
                      className={`text-center ${classes.guessTxt} ${classes.label1}`}
                    >
                      OVER {guess}
                    </p>
                    <p className={`text-center ${classes.guessMux}`}>
                      x{String(multiplier).slice(0, 4)}
                    </p>
                  </>
                }
              />
            </div>
          </div>
        </div>

        {/* Bottom Group */}
        <div
          className={`d-flex justify-content-between ${classes.bottomGroup}`}
        >
          <Button
            className="diceGameDec gamePlay"
            handleClick={() => {
              handleBetAmmount("dec");
            }}
            label={
              <>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.25 12.25H5.75"
                    stroke="#FFFFFF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </>
            }
          />

          <label className={classes.div4}>
            <div
              className={`${classes.betValueDiv} d-flex align-items-center justify-content-center`}
            >
              {/* <img
                src={coin}
                width={24}
                height={24}
                alt="Coin"
                className={classes.coinImage}
              /> */}
              <IconLoader
                iconName={userInput.token}
                iconType={"tokenSymbol"}
                image={userInput.image}
                width={24}
                height={24}
              />
              <input
                value={input}
                onChange={(event) => {
                  enforcer(event.target.value);
                }}
                className={classes.bottomValue}
                style={{
                  width: input.length === 0 ? "5px" : input.length + "ch",
                }}
              />
            </div>
          </label>

          <Button
            label={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 5.75C12.75 5.33579 12.4142 5 12 5C11.5858 5 11.25 5.33579 11.25 5.75V11.25H5.75C5.33579 11.25 5 11.5858 5 12C5 12.4142 5.33579 12.75 5.75 12.75H11.25V18.25C11.25 18.6642 11.5858 19 12 19C12.4142 19 12.75 18.6642 12.75 18.25V12.75H18.25C18.6642 12.75 19 12.4142 19 12C19 11.5858 18.6642 11.25 18.25 11.25H12.75V5.75Z"
                  fill="#FFFFFF"
                />
              </svg>
            }
            className="diceGameInc gamePlay"
            handleClick={() => {
              handleBetAmmount("inc");
            }}
          />
        </div>

        <div className="row">
          <div
            className="col-12"
            // onClick={() => {
            //   playSound(diceSound);
            // }}
          >
            <PlayButton
              tokenBalance={tokenBalance}
              diceValue={under ? underGuess : guess}
              betAmount={input}
              under={under}
              over={over}
              // handlePlay={handlePlay}
              loading={loading}
              showWallet={(value: boolean) => {
                setOpenWallet(value);
              }}
              play={() => {
                playSound(diceSound);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <OpenWallet openWallet={openWallet} closeModal={closeModal} />

      {openModal && (
        <BootstrapModal
          open={true}
          title={<BetTitle />}
          message={
            <BetBody
              data={modalData}
              handleClose={() => {
                closeModal();
              }}
              handlePlay={() => {
                // closeModal();
              }}
              type={2}
            />
          }
          footerMessage={<Details data={modalData} />}
          buttonTitle={"none"}
          height={"436px"}
          handleClose={() => {
            closeModal();
          }}
          width="436px"
          className="modalBetDetail"
        />
      )}
      {depositModal && (
        <TransferTypeModal
          open={depositModal}
          handleClose={() => setDepositModal(false)}
        />
      )}
      {tokenSelectorModal && (
        <TokenSelectorModal
          open={tokenSelectorModal}
          handleClose={() => setTokenSelectorModal(!tokenSelectorModal)}
          selectedToken={selectedToken}
          setSelectedToken={(token) => {
            setTokenSelectorModal(false);
            setSelectedToken(token);
          }}
        />
      )}

      <div className={`card ${classes.mainCard} ${classes.diceContainer}`}>
        <img
          className={`${classes.numberLogo} img-fluid`}
          src={dicelogo}
          alt="dicelogo"
        />
        <ConfettiComponent
          height={"220"}
          width={diceRef.current?.offsetWidth}
        />

        {/* Dice Game UI */}
        {GameUI}
        <GameControl />
      </div>
    </>
  );
};

export default PlayingArea;
