import classes from "./Auth.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../ui/Buttons";
import userIcon from "../../static/images/auth/user.svg";
import chevronRight from "../../static/images/auth/chevron-right.svg";
import { toast } from "react-toastify";
import { userUpdate } from "../../helpers/ServerApi";
import { useSetJWT } from "../../redux/user/hooks";
import { useDispatch } from "react-redux";
import { updateUserName } from "../../redux/user/actions";
import Foot from "./foot";

const Username = () => {
  const dispatch = useDispatch();
  const setJWT = useSetJWT();
  const Navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  const IsValidEmail = (word: string) => {
    const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const result = pattern.test(word) || word.length === 0 || word.length >= 3;

    return result;
  };

  const handleName = (e: any) => {
    let name = e.target.value;
    if (name.length < 11) {
      name = e.target.value.substring(0, 10);
      const trimmedUsername = name.replace(/\s/g, ""); // Remove spaces
      const special = trimmedUsername.replace(/[^a-z0-9]/gi, "");
      setUsername(special);
    }
  };

  const handleSubmit = async (username: string) => {
    // e.preventDefault();
    if (username.length === 0) {
      toast("Please provide the Username", { theme: "dark" });
      return;
    }
    if (username.length < 3) {
      IsValidEmail(username);
      toast("Username should be atleast 3 characters", { theme: "dark" });
      return;
    } else {
      const payload = {
        username,
      };
      setLoading(true);
      await userUpdate(payload)
        .then((res: any) => {
          setLoading(false);
          if (res?.user) {
            // setJWT(res.user.token);
            dispatch(updateUserName({ username: res.user.username }));
            Navigate("/", { state: { newUser: true } });
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.status === 401) {
          } else {
            toast(err.response?.data.error, { theme: "dark" });
          }
        });
    }
  };

  const conti = (
    <>
      <div className={`d-flex align-items-center justify-content-center gap-2`}>
        <p className={classes.discordText}>CONTINUE</p>
        {loading && (
          <i className="fa fa-spinner fa-spin" style={{ fontSize: "20px" }}></i>
        )}
        {!loading && <img src={chevronRight} alt="" />}
      </div>
    </>
  );

  return (
    <>
      <div className={classes.box + " " + classes.center}>
        <div style={{ gap: "2px" }}>
          <p className={classes.title}>Enter your Username</p>
          <p className={classes.subtitle}>
            Enter your details to continue with the game
          </p>
        </div>
        <div className={classes.signDiv3}>
          <div
            className={classes.inputContainer}
            data-tip="minimum 3 characters"
          >
            <input
              className={`
                ${classes.input} ${classes.inputEmail} ${classes.inputPadding} ${
                  IsValidEmail(username) === true
                    ? username.length >= 3 && classes.notError
                    : classes.error
                }
                `}
              type="text"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => {
                handleName(e);
              }}
              pattern="^.{1,10}$"
            />
            <img src={userIcon} alt="User Icon" className={classes.inputIcon} />
          </div>
          <div className={` d-flex flex-column align-items-start`}>
            {username && username.length < 3 ? (
              <i className="fa fa-times-circle" style={{ color: "#ff9494 " }}>
                {" "}
                <span
                  className={classes.authSwitch}
                  style={{ color: "#ff9494 " }}
                >
                  &nbsp;Username should be atleast 3 characters
                </span>
              </i>
            ) : null}
          </div>
        </div>
        <Button
          label={conti}
          handleClick={() => {
            handleSubmit(username);
          }}
          className={"authContinue"}
        />
        <Foot />
      </div>
    </>
  );
};

export default Username;
