import classes from "./StaticBanner.module.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetLoggedIn } from "../../../redux/user/hooks";

export type IBannerType = {
  type?: string;
  img: string;
  heading: string;
  body: string;
};

const StaticBanner = ({ type, img, heading, body }: IBannerType) => {
  const isLoggedIn = useGetLoggedIn();
  const Navigate = useNavigate();
  // Split the heading string by spaces and map each part to a JSX element
  const headingParts = heading.split(" ").map((part, index) => (
    <React.Fragment key={index}>
      {part}
      {index !== heading.split(" ").length - 1 && <br />}
    </React.Fragment>
  ));

  const style = type === "refer" ? { cursor: "pointer" } : {};
  function navigate() {
    if (type === "refer") {
      if (isLoggedIn) {
        Navigate("/referral");
      } else Navigate("/login");
    }
  }

  return (
    <>
      <div
        className={`col ${classes.container}`}
        style={style}
        onClick={() => {
          navigate();
        }}
        // as per sonarqube report changes below
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        role="button"
        tabIndex={0}
      >
        <div
          className={`${classes.div1} d-flex flex-column align-items-center justify-content-center gap-2`}
        >
          <div className={classes.image}>
            <img src={img} alt="" />
          </div>
          <div className={classes.text}>
            <h1 className={classes.heading}>{headingParts}</h1>
            <p className={classes.sentence}>{body}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaticBanner;
