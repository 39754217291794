import { createAction } from "@reduxjs/toolkit";

import { IAssetPrices, PopupContent } from "../../helpers/interface";
import { ICollateralType } from "../../config/games";

export const updateBlockNumber = createAction<{
  chainId: number;
  blockNumber: number;
}>("app/updateBlockNumber");

export const updateUserInput = createAction<{
  guess?: number;
  input?: string;
  token?: string;
  under?: boolean;
  over?: boolean;
  image?: string;
}>("app/updateUserInput");

export const toggleWalletModal = createAction<void>("app/toggleWalletModal");

export const toggleSettingsMenu = createAction<void>("app/toggleSettingsMenu");

export const setReferredBy = createAction<{ refBy: string }>(
  "app/setReferredBy",
);

export const setDepositAddress = createAction<{ address: string }>(
  "app/setDepositAddress",
);

export const setGameIDs = createAction<{ id: string }>("app/setGameId");

export const setAssetPrices = createAction<IAssetPrices>("app/setAssetPrices");

export const addPopup = createAction<{
  key?: string;
  removeAfterMs?: number | null;
  content: PopupContent;
}>("app/addPopup");

export const updatePopup = createAction<{
  key: string;
  content: PopupContent;
}>("app/updatePopup");

export const removePopup = createAction<{ key: string }>("app/removePopup");

export const toggleConfetti = createAction<void>("app/toggleConfetti");

export const toggleChatBox = createAction<{ key: boolean }>("app/toggleChatBox");
export const RivalData = createAction<{ username: string }>("app/RivalData");
export const RivalMetaData = createAction<{
  username: string;
  avatar: string;
  usdWagered: number;
  createdAt: string;
  _id: string;
  won: number;
  loss: number;
}>("app/RivalMetaData");
export const openRival = createAction<{ key: boolean }>("app/openRival");
export const openProfileAction = createAction<{ key: boolean }>("app/openProfile");

export const toggleSideMenu = createAction<{ key: boolean }>("app/toggleSideMenu");
export const openBetModal = createAction<{ key: boolean }>("app/openBetModal");

export const games = createAction<{ games: [] }>("app/games")

export const currentGame = createAction<{ gameId: string }>("app/currentGame")

export const prevRoute = createAction<{ route: string }>("app/prevRoute")
export const setProfileTab = createAction<{ tab: string }>("app/setProfileTab")
export const setCurrencies = createAction<{ currencies: { image: string, symbol: string }[] }>("app/setCurrencies")
export const setGamePlayError = createAction<{ key: boolean }>("app/gamePlayError");
export const setChatted = createAction<{ key: boolean }>("app/chatted");

export const clearApp = createAction<void>("app/clearApp");