import { useEffect, useState } from "react";
import { getReferralData } from "../helpers/ServerApi";
interface IRes {
    referralCode: string,
    numberOfUsers: number
    earnings: number,
    usdEarned: number,
    usdWagered: number
}
const useGetReferral = () => {
    const [data, setData] = useState<IRes>({} as IRes);
    useEffect(() => {
        getReferralData().then((res) => {
            setData(res.data);
        }).catch(err => {
            console.log("err", err);
        })
    }, []);
    return data;
}

export default useGetReferral;