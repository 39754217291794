import { Link } from "react-router-dom";
import classes from "./Banner2.module.css";
import Button from "../../../components/ui/Buttons";
import coinImg from "../../../static/images/banner2Coin.png";
import arrow from "../../../assets/arrow1.png";
import Download from "../../../components/Download";

const Banner2 = () => {
  return (
    <>
      <div className={`${classes.container}`}>
        <div className={classes.image}>
          <img className={`p-2 ${classes.img1}`} src={coinImg} alt="" />
        </div>
        <div className={classes.text}>
          <div className={classes.div1}>
            <h1 className={classes.heading}>
              Welcome to Mooncake <br /> Bets!
            </h1>
            <p className={classes.sentence}>
              A fast, provably-fair, KYC-free casino that is open to all.
              Everyone is treated fairly and has a fair chance of winning the
              various on-chain prizes.
            </p>
          </div>

          {/* <Link to="/referral"> */}
          <Button
            label={
              <div className={classes.div2}>
                <p className={classes.b2p1}>
                  <Download string={"See Documentation"} type={"welcome"} />
                </p>
                <img className={classes.img2} src={arrow} alt="arrow" />
              </div>
            }
            handleClick={() => {}}
            className="seeDoc"
          />
        </div>
      </div>
    </>
  );
};

export default Banner2;
