import IconLoader from "../../../components/IconLoader";
import Button from "../../../components/ui/Buttons";
import { useTokenBalance } from "../../../redux/token/hooks";
import classes from "../wallet.module.css";

interface IProps {
  index: number;
  selectedToken: string;
  passingToken: string;
  setSelectedToken: (data: string) => void;
}

const Options = (props: IProps) => {
  const tokenBalance = useTokenBalance(props.passingToken);
  return (
    <>
      {
        <li className={classes.listItem}>
          <Button
            className={"none"}
            handleClick={() => props.setSelectedToken(props.passingToken)}
            label={
              <div className="d-flex align-items-center justify-content-between gap-2">
                <div className="d-flex align-items-center justify-content-between gap-1">
                  <IconLoader
                    iconName={props.passingToken}
                    iconType={"tokenSymbol"}
                    width={20}
                    height={20}
                  />{" "}
                  {String(tokenBalance)}
                </div>
                {props.passingToken}
              </div>
            }
          />
        </li>
      }
    </>
  );
};

export default Options;
