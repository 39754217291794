import { infoIcon } from "../../static/images";
import classes from "./referral.module.css";

const RefPill = (Props: { text: string }) => {
  const pendingTxt = <>User only used your referral to sign up</>;
  const completedTxt = "User made a deposit";
  return (
    <>
      <div
        className={`${classes.refPill} d-flex gap-2 justify-content-end align-items-center`}
      >
        <div
          className={`${Props.text === "pending" ? classes.refPending : classes.refCompleted} tootlip`}
        >
          <p className={classes.refPillText}>{Props.text}</p>
        </div>
        <div className="tooltip">
          {infoIcon}
          <span className="tooltiptext tooltipTextRef">
            {Props.text === "pending" ? pendingTxt : completedTxt}
          </span>
        </div>
      </div>
    </>
  );
};

export default RefPill;
