import { useEffect, useMemo, useState } from "react";
import { useGetLoggedIn } from "../../../redux/user/hooks";
import { useNavigate } from "react-router-dom";
import AccountButton from "../../../components/AccountButton/AccountButton";
import Button from "../../../components/Button";
// import OpenWallet from "../../wallet/openWallet";

interface IProps {
  tokenBalance: number;
  diceValue: number;
  betAmount: string;
  // handlePlay: () => void;
  loading: boolean;
  under: boolean;
  over: boolean;
  play: () => void;
  showWallet: (open: boolean) => void;
}

const PlayButton = ({
  tokenBalance,
  // handlePlay,
  loading,
  betAmount,
  diceValue,
  under,
  over,
  play,
  showWallet,
}: IProps) => {
  const navigate = useNavigate();
  const [firstPlay, setFirstPlay] = useState(true);
  // To check if all pending orders + placing bets amount is greater than mETH balance > to avoid failures of tx;
  const isBalanceEnough = useMemo(
    () => tokenBalance >= Number(betAmount),
    [betAmount, tokenBalance],
  );

  const isLoggedIn = useGetLoggedIn();
  const showLoginButton = !isLoggedIn;

  const showDepositButton = useMemo(
    () => tokenBalance < Number(betAmount) && !showLoginButton,
    [betAmount, tokenBalance, showLoginButton],
  );

  const showPlayButton = useMemo(
    () => !showDepositButton && !showLoginButton,
    [showLoginButton, showDepositButton],
  );

  const handleClick = () => {
    if (showDepositButton) {
      showWallet(true);
    } else if (showLoginButton) {
      navigate("/login"); // assuming you want to navigate to login page
    } else if (showPlayButton) {
      // handlePlay(); // Uncomment this if you want to use handlePlay function
      setFirstPlay(false);
      play();
    }
  };

  const getButtonText = () => {
    if (showDepositButton) {
      return "Fund Your Account";
    } else if (showLoginButton) {
      return <AccountButton />;
    } else if (showPlayButton) {
      if (loading) {
        return "Rolling";
      } else {
        return Number(betAmount) !== 0
          ? firstPlay
            ? "PLAY"
            : "PLAY AGAIN"
          : "Please enter amount";
      }
    }
    return "";
  };

  const isButtonDisabled = () => {
    if (showPlayButton) {
      return (
        !isBalanceEnough || loading || !betAmount || Number(betAmount) === 0
      );
    }
    return false;
  };

  return (
    <div>
      <Button
        className={"primary-btn funding-needed playButton"}
        onClick={handleClick}
        disabled={isButtonDisabled()}
      >
        {getButtonText()}
      </Button>
      {/* Render OpenWallet if openWallet is true */}
    </div>
  );
};

export default PlayButton;
