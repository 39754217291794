import classes from "./Chat.module.css";
import _ from "underscore";
import { getAllChat } from "../../helpers/ServerApi";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  useGetJWT,
  useGetLoggedIn,
  useGetUserName,
} from "../../redux/user/hooks";
import useSocketIO from "../../hooks/useSocketIo";
import Picker from "emoji-picker-react";

import crossIcon from "../../static/images/ph_x.svg";
import Vector from "../../static/images/Vector.png";
import sendIcon from "../../static/images/send.svg";
import emojiIcon from "../../static/images/emoji.svg";

import { useDispatch } from "react-redux";
import { setChatted, toggleChatBox } from "../../redux/application/actions";
import { useGetChatOpen } from "../../redux/application/hooks";

import { getChatTag } from "../../helpers/ServerApi";

import ChatTag from "../../components/chatTag/";
import BootstrapModal from "../../components/BootstrapModal";
import BootstrapOffcanvas from "../../components/BootstrapOffcanvas";
import Comment from "./Comment";
import { IChat, IChatRes, IMetaData } from "../../helpers/interface";
import { useGetChatted } from "../../redux/application/hooks";
import Button from "../../components/ui/Buttons";
import { infoChat } from "../../static/images";

const Chat = (props: { className?: string }) => {
  const [online, setOnline] = useState(navigator.onLine);
  const chatted = useGetChatted();
  const inputRef = useRef<HTMLInputElement>(null);
  const sendRef = useRef<HTMLButtonElement>(null);
  const [messages, setArray] = useState<IChat[]>([]);
  const [chatText, setChatText] = useState<string>("");
  const messagesEndRef = useRef<HTMLInputElement | null>(null);
  const isUsername = useGetUserName();
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const [options, setOptions] = useState<IChatRes[]>([]);
  const [tagNumber, setTagNumber] = useState(1);
  const [tagSearch, setTagSearch] = useState<string>("");
  const [metaData, setMetaData] = useState<IMetaData[]>([]);
  const dispatch = useDispatch();
  const [isSendIconFocused, setIsSendIconFocused] = useState(false);
  const [focusState, setFocusState] = useState<
    "emojiPicker" | "sendIcon" | "inputField"
  >("inputField");
  const openChat = useGetChatOpen();

  useEffect(() => {}, [openChat]);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, [online]);

  const jwt = useGetJWT();
  const isLoggedIn = useGetLoggedIn();
  const socket = useSocketIO();

  const toggleChat = (value: boolean) => {
    dispatch(toggleChatBox({ key: value }));
  };

  useEffect(() => {
    getAllChat()
      .then((r: IChat[]) => {
        setArray(r.reverse());
        scrollToBottom(); // Scroll to bottom when component mounts
      })
      .catch(_.noop);
  }, []);

  useEffect(() => {
    socket?.on("new_message", (r: IChat) => {
      setArray((oldArray) => [...oldArray, r]);
    });
  }, [socket]);

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom whenever messages state changes
  }, [messages, openChat]);

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      inputRef.current?.focus();
    }, 500);
  };

  const sendMessageSocket = useCallback(() => {
    if (jwt && chatText.trim().length > 0) {
      const body = {
        message: chatText,
        token: jwt,
        metaData: metaData,
      };

      socket?.emit("new_chat", body);
      setChatText("");
      setTimeout(scrollToBottom, 500);
    }
  }, [chatText, jwt, metaData, socket]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") sendMessageSocket();
      if (event.key === "Tab") {
        event.preventDefault();
        if (focusState === "inputField") {
          setOpenEmojiPicker(true);
          setFocusState("emojiPicker");
        } else if (focusState === "emojiPicker") {
          setOpenEmojiPicker(false);
          setFocusState("sendIcon");
          sendRef.current?.focus();
        } else if (focusState === "sendIcon") {
          setFocusState("inputField");
          inputRef.current?.focus();
        }
      }
    },
    [sendMessageSocket, focusState],
  );

  useEffect(() => {
    let tag = chatText?.split("@")[tagNumber];
    if (tag?.trimEnd().length !== tag?.length) {
      setTagNumber(tagNumber + 1);
    }
    setTagSearch(tag);
    if (tag?.length >= 3) {
      let data = tag?.split(" ");
      getTag(data[0]);
    } else {
      setOptions([]);
    }
  }, [chatText]);

  const getTag = (text: string) => {
    getChatTag(text).then((r: any) => {
      if (r?.tag) {
        const data = r?.tag;
        const arr = data.map((obj: IChatRes) => {
          return obj;
        });
        setOptions(arr);
      }
    });
  };

  const selectedTag = (tag: IChatRes) => {
    // Create a regular expression to match the last occurrence of the substring
    const regex = new RegExp(`@${tagSearch}(?!.*@${tagSearch})`, "g");

    // Replace the last occurrence of the substring in chatText with the new tag
    const updatedChatText = chatText.replace(regex, `@${tag.username} `);

    // Update the state with the new chat text
    setChatText(updatedChatText);

    // Update the meta data array
    setMetaData((oldArray) => [
      ...oldArray,
      { _id: tag._id, username: tag.username },
    ]);

    // Reset options array and increment tagNumber
    setOptions([]);
    setTagNumber(tagNumber + 1);

    // Focus on the input reference if it exists
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  function countAtStrings(text: string) {
    const regex = /@\w+/g;
    const matches = text.match(regex);
    if (!matches) {
      return 0;
    }
    return matches.length;
  }

  useEffect(() => {
    const prevCount = tagNumber;

    const count = countAtStrings(chatText);
    if (prevCount - count > 1) {
      setTagNumber((prev) => prev - 1);
      // setMetaData((oldArray) => oldArray.slice(0, -1));
      setMetaData((oldArray) =>
        oldArray.filter((item) => chatText.includes(`@${item.username}`)),
      );
    }
  }, [chatText, tagNumber]);

  const RulesList = (
    <>
      <ul className={`px-3 mb-0 ${classes.ruleUl}`}>
        <li style={{ color: "#FF7F7F" }}>
          Making accusations regarding the fairness of the game without
          providing any proof will cause your account to be blocked. Remember
          that the game is provably fair and you can verify the transparency of
          every bet.
        </li>
        <li>
          <span style={{ color: "#FFF" }}>
            Don't engage in commercial activities like advertising, selling,
            buying, or any suspicious behavior that could be interpreted as scam
            attempts.
          </span>
        </li>
        <li>
          <span style={{ color: "#FFF" }}>
            Don't ask other players for loans, rain, or post your crypto address
            begging for money.
          </span>
        </li>
        <li>
          {" "}
          <span style={{ color: "#FFF" }}>
            Be polite, don't be "that guy." Avoid offensive comments.
          </span>
        </li>
      </ul>
    </>
  );

  const ui = (
    <>
      <div className={`${classes.chat}`}>
        {/* Header */}
        <div className={`row ${classes["chat-header"]}`}>
          <div
            className={`col-2 ${classes["icon-container"]}`}
            onClick={() => toggleChat(false)}
            // as per sonarqube report changes below
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            role="button"
            tabIndex={0}
          >
            <img
              width={24}
              height={24}
              src={crossIcon}
              alt=""
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className={`col ${classes["title-container"]}`}>
            <img src={Vector} width={18} height={18} alt="" />
            <span className={classes.span1}>CHAT</span>
          </div>
        </div>

        {/* List */}
        <div className={`${classes["chat-window"]}`}>
          {messages
            .filter((m) => m && m.from)
            .map((m) => (
              <Comment chatData={m} key={m._id} />
            ))}
          <div ref={messagesEndRef} />
        </div>

        {/* Input */}
        <div className={`${classes["chat-input"]}`}>
          {navigator.onLine ? (
            <>
              {infoChat}
              {/* <img src={infoIcon} width={24} height={24} alt="" /> */}

              <ChatTag options={options} handleSelect={selectedTag} />

              <input
                className={classes["chat-input-field"]}
                ref={inputRef}
                value={chatText}
                onChange={(event) => {
                  setOpenEmojiPicker(false);
                  setChatText(event.target.value);
                }}
                onKeyDown={handleKeyDown}
                placeholder={`${isLoggedIn ? "Say something..." : "Login to chat"}`}
                disabled={!isLoggedIn}
              />
              <Picker
                open={openEmojiPicker}
                searchDisabled={true}
                skinTonesDisabled={true}
                onEmojiClick={(e) => setChatText(chatText + e.emoji)}
                className={classes["emoji-picker"]}
              />
              {isLoggedIn && (
                <span className={classes.emojIcon}>
                  <Button
                    className={"none"}
                    label={<img src={emojiIcon} alt="" />}
                    handleClick={() => setOpenEmojiPicker(!openEmojiPicker)}
                  />
                </span>
              )}

              {isLoggedIn && isUsername && (
                <Button
                  ref={sendRef}
                  className={"none"}
                  label={
                    <img
                      className={`cursor ${classes.sendIcon} ${isSendIconFocused ? classes.highlighted : ""}`}
                      src={sendIcon}
                      alt=""
                    />
                  }
                  handleClick={() => {
                    setOpenEmojiPicker(false);
                    sendMessageSocket();
                  }}
                  handleBlur={() => setIsSendIconFocused(false)}
                  handleFocus={() => setIsSendIconFocused(true)}
                />
              )}
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center p-2">
                Connection lost, connect to the internet to chat and reload once
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );

  return (
    <>
      {chatted === true && openChat && (
        <BootstrapModal
          open={true}
          title={"CHAT RULES"}
          message={RulesList}
          buttonTitle={"UNDERSTOOD"}
          height={"412px"}
          width={"520px"}
          buttonClass={"understood"}
          handleClose={() => dispatch(setChatted({ key: false }))}
        />
      )}
      <BootstrapOffcanvas show={openChat} placement={"end"} body={ui} />
    </>
  );
};

export default Chat;
