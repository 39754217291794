import {
  avatars,
  noAvatar,
} from "../static/images";
import ellipse from "../static/images/Ellipse.svg";

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function getPopUpKey(): string {
  const randomNo = Math.floor(new Date().getTime());
  return randomNo.toString();
}

export const truncateMiddle = function (
  fullStr: string = "",
  strLen: number,
  separator?: string,
) {
  if (fullStr.length <= strLen) {
    return fullStr;
  }

  separator = separator || "...";

  let sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 3),
    backChars = Math.floor(charsToShow / 3);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
};

export function checkPassword(passwordFormat: RegExp, password: string) {
  const isValidPassword = passwordFormat.test(password);
  if (password.length === 0) {
    return false;
  } else if (!isValidPassword) {
    return false;
  } else {
    return true;
  }
}

export function comparePassword(passwordFormat: RegExp, password: string, confirmPassword: string) {
  const isValidPassword = passwordFormat.test(password);
  if (password.length === 0) {
    return false;
  } else if (!isValidPassword) {
    return false;
  } else if (password !== confirmPassword) {
    return false;
  } else {
    return true;
  }
}

export const checkAvatar = (name: string | undefined | null) => {

  if (name === undefined || name === null || name === '') return typeof noAvatar === "string" ? noAvatar : ""; // Ensure it's a string

  // Assuming avatars is an array of avatar URLs
  for (const avatar of avatars) {
    if (avatar.includes(name)) {
      return typeof avatar === "string" ? avatar : ""; // Ensure it's a string
    }
  }
  // Return a default avatar if the user's avatar is not found
  return typeof noAvatar === "string" ? noAvatar : ""; // Ensure it's a string
};


export const checkRoutes = (path: string) => {
  const paths = [
    "/login",
    "/register",
    "/set-username",
    "/forget-password",
    "/change-password",
    "/otp",
  ];
  if (paths.includes(path)) {
    return false;
  }
  return true;
}

export const formatToTwoDecimals = (num: number) => {
  const numStr = num.toString();
  const decimalIndex = numStr.indexOf('.');
  if (decimalIndex === -1) return 0; // No decimal point found
  return numStr.slice(0, decimalIndex + 3); // Include up to two decimal places
};

export const avatar = (id: string, userId: string, avatar: string, userAvatar: string) => {
  if (userId === id) {
    if (userAvatar) return checkAvatar(userAvatar);
    else return ellipse;
  } else {
    if (avatar) return checkAvatar(avatar);
    else return ellipse;
  }
};