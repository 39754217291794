import Button from "../ui/Buttons";
import classes from "./firstTimePlay.module.css";
interface IProps {
  handleClose: () => void;
}

const FirstPlayContent = ({ handleClose }: IProps) => {
  return (
    <>
      <div className={classes.content}>
        <p>
          To play this game, all you have to do is choose a number from the
          slider above and place a bet. A random number is rolled and if it is
          higher than the bet you have placed, then you will win the reward.
        </p>
        <p>
          If not, then you will lose the amount you have wagered.The higher then
          number you choose, the greater the reward but also the greater the
          risk.
        </p>

        <Button
          className="startPlaying"
          label="Start Game"
          handleClick={handleClose}
        />
      </div>
    </>
  );
};

export default FirstPlayContent;
