import { NavLink, useLocation } from "react-router-dom";
import classes from "./Footer.module.css";

import { footerlogo } from "../../../static/images";
import flower from "../../../static/images/footer/flower.svg";
import begamble from "../../../static/images/footer/BeGamble.svg";
import coins from "../../../static/images/footer/coins.svg";
import plus18 from "../../../static/images/footer/18plus.svg";
import twitter from "../../../static/images/footer/twitter.svg";
import telegram from "../../../static/images/footer/telegram.svg";

import { checkRoutes } from "../../../helpers/functions";
import Download from "../../../components/Download";
import { useGetSideMenuOpen } from "../../../redux/application/hooks";

const AppFooter = () => {
  const location = useLocation();
  const path = location.pathname;
  const sideMenuOpen = useGetSideMenuOpen();

  return (
    <>
      {checkRoutes(path) ? (
        <div className={`${classes.footer} ${sideMenuOpen ? classes['sidebar-open'] : ''}`}>
          <div className={classes.div2}>
            <div className={classes.div3}>
              {footerlogo()}
              <div className={`${classes.navs}`}>
                <NavLink to="/" className={classes.navlink}>
                  <span style={{ cursor: "pointer" }}>Home</span>
                </NavLink>
                <NavLink to="/games" className={classes.navlink}>
                  <span style={{ cursor: "pointer" }}>Games</span>
                </NavLink>
                <span>
                  <Download string={"About"} type={"about"} />
                </span>
                <span>
                  <Download string={"Help Center"} type={"helpCenter"} />
                </span>
                <span>
                  <Download string={"Blog"} type={"blog"} />
                </span>
              </div>
            </div>
            <div className={classes.div4}>
              <span className={classes.span1}>Supported</span>
              <div>
                <img className={classes.img1} src={coins} alt="" />
              </div>
            </div>
          </div>
          <div
            className={`d-flex justify-content-between py-3 ${classes.div5}`}
          >
            <div className="py-3">
              <img className="pe-2" src={flower} alt="" />
              <img className="pe-2" src={plus18} alt="" />
              <img className="pe-2" src={begamble} alt="" />
            </div>
            <div className={classes.div6}>
              <select
                className={classes.currency}
                id="currency"
                name="currency"
                disabled
              >
                <option className={classes.option} value="inr">
                  &#x20b9; &nbsp;INR
                </option>
              </select>
              <select
                id="language"
                name="english"
                className={classes.language}
                disabled
              >
                <option className={classes.option} value="ENGLISH">
                  ENGLISH
                </option>
              </select>
            </div>
          </div>
          <div className={classes.div7}>
            <span className="py-3">Copyright (C) 2023 Mooncake</span>
            <div className={classes.div8}>
              <span>
                <Download string={"Terms of Service"} type={"termsOfService"} />
              </span>
              <span className={classes.span2}></span>
              <span>
                <Download string={"Privacy Policy"} type={"privacy"} />
              </span>
              <span className={classes.span2}></span>
              <a
                className="align-items-center d-flex"
                href="https://twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="" style={{ cursor: "pointer" }} />
              </a>
              <a
                className="align-items-center d-flex"
                href="https://telegram.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={telegram}
                  alt="Telegram"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`${classes.authFooter}`}
            style={{
              gap: "48px",
              position:
                location.pathname === "/login" ||
                location.pathname === "/register"
                  ? "relative"
                  : "absolute",
            }}
          >
            <div className={classes.div9}>
              {footerlogo()}
              <p className={classes.authFooterC}>Copyright (C) 2023 Mooncake</p>
            </div>
            <div className={classes.div10}>
              <span className={`pe-2 ${classes.authFooterC}`}>
                <Download string={"Terms of Service"} type={"termsOfService"} />
              </span>
              <span className={classes.span2}></span>
              <span className={`px-2 ${classes.authFooterC}`}>
                <Download string={"Privacy Policy"} type={"privacy"} />
              </span>
              <span className={classes.span2}></span>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={`px-2 ${classes.authFooterMedia}`}
                  src={twitter}
                  alt=""
                />{" "}
              </a>
              <a
                href="https://www.telegram.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={`pe-2 ${classes.authFooterMedia}`}
                  src={telegram}
                  alt=""
                />
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AppFooter;
